import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LANGUAGES } from '@app/models/constants';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { DestroyService } from '@app/services/common/destroy.service';
import { AuthService } from '@app/services/common/auth.service';
import { takeUntil } from 'rxjs';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
	providers: [DestroyService]
})
export class ResetPasswordComponent implements OnInit {
	passwordForm?: FormGroup;
	languageNext?: string;
	isUnion: boolean;

	private key: string;

	private email: string;

	public checked = false;

	state = {
		sending: false,
		sent: false,
		successMsg: '',
		error: ''
	};

	public rules = {
		TOO_SHORT: false,
		INSUFFICIENT_CHARACTERISTICS: false,
		INSUFFICIENT_DIGIT: false,
		INSUFFICIENT_SPECIAL: false,
		INSUFFICIENT_UPPERCASE: false,
		INSUFFICIENT_LOWERCASE: false,
		USED_BEFORE: false,
		ILLEGAL_WORD: false
	};
	constructor(
		private fb: FormBuilder,
		private translateService: TranslateService,
		private translatePipe: TranslatePipe,
		private destroy$: DestroyService,
		private authService: AuthService,
		private route: ActivatedRoute,
		private router: Router
	) {}

	ngOnInit(): void {
		console.log('LOAD COMPONENT');
		this.route.queryParams.subscribe((params) => {
			this.email = params['email'];
			this.key = params['key'];
		});

		if (!this.key || this.key.length !== 64 || !this.email) {
			this.router.navigateByUrl('login');
		}

		this.authService.checkResetKey(this.key, this.email).subscribe({
			next: () => {
				this.checked = true;
			},
			error: (err) => {
				this.router.navigateByUrl('login');
				console.log('NOT AUTHORIZED');
			}
		});

		this.passwordForm = this.fb.group({
			password: ['', [Validators.required, Validators.minLength(8)]]
		});

		this.languageNext = LANGUAGES[this.getNextLanguage()];

		this.isUnion = this.authService.isUnion;
	}

	getNextLanguage() {
		const langKeys = Object.keys(LANGUAGES);
		const currentIndex = langKeys.indexOf(this.translateService.currentLang);
		return currentIndex + 1 >= langKeys.length ? langKeys[0] : langKeys[currentIndex + 1];
	}

	onResetPassword() {
		this.state.sending = true;
		this.authService.resetPassword(this.key, this.email, this.passwordForm?.get('password')?.value).subscribe({
			next: () => {
				this.state.sending = false;
				this.state.sent = true;
				this.state.successMsg = this.translatePipe.transform('RESET_PW.SUCCESS');
				this.passwordForm?.get('password')?.setValue('');
			},
			error: (response) => {
				this.state.sending = false;
				if (response.status === 403) {
					this.router.navigateByUrl('login');
				} else if (response.status === 400) {
					if (response.data && response.data.violations) {
						for (const [key, value] of Object.entries(this.rules)) {
							// @ts-ignore
							this.rules[key] = !response.data.violations[key];
						}

						const violations = Object.values(response.data.violations).join('\n');
						this.state.error = this.translatePipe.transform('RESET_PW.ERROR.OPERATION_FAILED_DETAILS', {
							violations
						});
					} else {
						this.state.error = this.translatePipe.transform('RESET_PW.ERROR.OPERATION_FAILED');
					}
				}
			}
		});
	}

	changeLang() {
		const nextLanguage = this.getNextLanguage();
		this.translateService
			.use(nextLanguage)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => (this.languageNext = LANGUAGES[this.getNextLanguage()]));
	}

	public updateRequirements(password: string) {
		let rulesMet = {
			TOO_SHORT: password.length > 7,
			INSUFFICIENT_CHARACTERISTICS: false,
			INSUFFICIENT_DIGIT: /\d/.test(password),
			INSUFFICIENT_SPECIAL: /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password),
			INSUFFICIENT_UPPERCASE: /[A-Z]/.test(password),
			INSUFFICIENT_LOWERCASE: /[a-z]/.test(password)
		};

		// At least 3 of the following rules must be met in order for the requirements to be satisfied
		// Count the true values of the given requirements
		rulesMet.INSUFFICIENT_CHARACTERISTICS =
			[
				rulesMet.INSUFFICIENT_DIGIT,
				rulesMet.INSUFFICIENT_SPECIAL,
				rulesMet.INSUFFICIENT_UPPERCASE,
				rulesMet.INSUFFICIENT_LOWERCASE
			].reduce((previousValue, currentValue) => {
				return currentValue ? previousValue + 1 : previousValue;
			}, 0) >= 3;

		// Apply ruleset before sending it to the backend to give the user feedback while typing
		this.rules = { ...this.rules, ...rulesMet };
	}
}
