import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LocalStorageModule } from 'angular-2-local-storage';
import { AuthInterceptor } from 'src/core/interceptors/auth.interceptor';
import { PasswordExpiredComponent } from './login/password-expired/password-expired.component';
import { ExternalReleaseComponent } from './external-release/external-release.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
	return new TranslateHttpLoader(http, './assets/locale/', '.json');
}

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		ExternalReleaseComponent,
		ForgotPasswordComponent,
		PasswordExpiredComponent,
		ResetPasswordComponent
	],
	imports: [
		LocalStorageModule.forRoot({
			prefix: 'eucon-smart-invoice',
			storageType: 'localStorage'
		}),
		BrowserModule,
		AppRoutingModule,
		NgbModule,
		BrowserAnimationsModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			},
			useDefaultLang: false
		}),
		SharedModule
	],
	providers: [
		TranslatePipe,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		Title
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor() {}
}
