<div class="login" style="background-image:url('{{ environment.backgroundImagePath }}')">
	<div class="login-box">
		<div class="login-logo">
			<img
				*ngIf="authService.isVoelkel; else otherImg"
				src=""
				class="logo-img"
				alt=""
				title="Smart Invoice"
				width="220"
				height="21"
			/>
			<ng-template #otherImg>
				<img src="" class="logo-img" title="Smart Invoice" width="200" height="60px" />
			</ng-template>
		</div>
		<div class="error" [hidden]="state.sent">{{ 'PASSWORD_EXPIRED.TEXT' | translate }}</div>
		<div class="page-title no-hor-margin no-padding no-box" [hidden]="state.sent">
			<h2>{{ 'PASSWORD_EXPIRED.TITLE' | translate }}</h2>
		</div>
		<form
			class="login-form"
			*ngIf="resetPWForm && !state.sent"
			[formGroup]="resetPWForm"
			(ngSubmit)="doSetPW()"
			novalidate
			autocomplete="off"
		>
			<div class="input-group-field" [ngClass]="{ invalid: resetPWForm.dirty && email && email.invalid }">
				<input
					type="email"
					class="login-input"
					[placeholder]="'PASSWORD_EXPIRED.PLACEHOLDER_EMAIL' | translate"
					formControlName="email"
					required
					(change)="resetError()"
					autocomplete="on"
				/>
				<i class="login-icon fa fa-user"></i>
			</div>

			<div class="input-group-field">
				<input
					[placeholder]="'PASSWORD_EXPIRED.PLACEHOLDER_CURRENT' | translate"
					formControlName="current"
					required
					class="login-input"
					autocomplete="off"
					type="password"
					spellcheck="false"
					(change)="resetError()"
				/>
				<i class="login-icon fa fa-key"></i>
			</div>

			<div class="input-group-field">
				<input
					[placeholder]="'PASSWORD_EXPIRED.PLACEHOLDER' | translate"
					formControlName="pw1"
					required
					class="login-input"
					autocomplete="off"
					type="password"
					spellcheck="false"
					(change)="resetError()"
					minlength="8"
				/>
				<i class="login-icon fa fa-key"></i>
			</div>

			<div class="page-title no-hor-margin no-padding">
				<h2>{{ 'PASSWORD_EXPIRED.REQUIREMENTS' | translate }}</h2>
			</div>

			<ul class="password-rules list-unstyled">
				<li [ngClass]="{ valid: rules['TOO_SHORT'], invalid: !rules['TOO_SHORT'] }">
					<i
						class="fa fa-fw icon"
						[ngClass]="{ 'fa-check': rules['TOO_SHORT'], 'fa-close': !rules['TOO_SHORT'] }"
					></i>
					<span class="text">{{ 'PASSWORD_EXPIRED.REQUIREMENT.MIN8CHARS' | translate }}</span>
				</li>
				<li
					[ngClass]="{
						valid: rules['INSUFFICIENT_CHARACTERISTICS'],
						invalid: !rules['INSUFFICIENT_CHARACTERISTICS']
					}"
				>
					<i
						class="fa fa-fw icon"
						[ngClass]="{
							'fa-check': rules['INSUFFICIENT_CHARACTERISTICS'],
							'fa-close': !rules['INSUFFICIENT_CHARACTERISTICS']
						}"
					></i>
					<span class="text">{{ 'PASSWORD_EXPIRED.REQUIREMENT.THREE_OF4CHAR_RULES' | translate }}</span>
				</li>
				<li
					class="sub-rule"
					[ngClass]="{ valid: rules['INSUFFICIENT_DIGIT'], invalid: !rules['INSUFFICIENT_DIGIT'] }"
				>
					<i
						class="fa fa-fw icon"
						[ngClass]="{
							'fa-check': rules['INSUFFICIENT_DIGIT'],
							'fa-close': !rules['INSUFFICIENT_DIGIT']
						}"
					></i>
					<span class="text">{{ 'PASSWORD_EXPIRED.REQUIREMENT.DIGITS' | translate }}</span>
				</li>
				<li
					class="sub-rule"
					[ngClass]="{ valid: rules['INSUFFICIENT_SPECIAL'], invalid: !rules['INSUFFICIENT_SPECIAL'] }"
				>
					<i
						class="fa fa-fw icon"
						[ngClass]="{
							'fa-check': rules['INSUFFICIENT_SPECIAL'],
							'fa-close': !rules['INSUFFICIENT_SPECIAL']
						}"
					></i>
					<span class="text">{{ 'PASSWORD_EXPIRED.REQUIREMENT.NON_ALPHA_NUM' | translate }}</span>
				</li>
				<li
					class="sub-rule"
					[ngClass]="{ valid: rules['INSUFFICIENT_UPPERCASE'], invalid: !rules['INSUFFICIENT_UPPERCASE'] }"
				>
					<i
						class="fa fa-fw icon"
						[ngClass]="{
							'fa-check': rules['INSUFFICIENT_UPPERCASE'],
							'fa-close': !rules['INSUFFICIENT_UPPERCASE']
						}"
					></i>
					<span class="text">{{ 'PASSWORD_EXPIRED.REQUIREMENT.CAPS' | translate }}</span>
				</li>
				<li
					class="sub-rule"
					[ngClass]="{ valid: rules['INSUFFICIENT_LOWERCASE'], invalid: !rules['INSUFFICIENT_LOWERCASE'] }"
				>
					<i
						class="fa fa-fw icon"
						[ngClass]="{
							'fa-check': rules['INSUFFICIENT_LOWERCASE'],
							'fa-close': !rules['INSUFFICIENT_LOWERCASE']
						}"
					></i>
					<span class="text">{{ 'PASSWORD_EXPIRED.REQUIREMENT.LOWER_CASE_LETTERS' | translate }}</span>
				</li>
				<li [ngClass]="{ valid: rules['ILLEGAL_WORD'], maybe: !rules['ILLEGAL_WORD'] }">
					<i
						class="fa fa-fw icon"
						[ngClass]="{ 'fa-check': rules['ILLEGAL_WORD'], 'fa-question': !rules['ILLEGAL_WORD'] }"
					></i>
					<span class="text">{{ 'PASSWORD_EXPIRED.REQUIREMENT.NO_EMAIL' | translate }}</span>
				</li>
				<li [ngClass]="{ valid: rules['USED_BEFORE'], maybe: !rules['USED_BEFORE '] }">
					<i
						class="fa fa-fw icon"
						[ngClass]="{ 'fa-check': rules['USED_BEFORE '], 'fa-question': !rules['USED_BEFORE '] }"
					></i>
					<span class="text">{{ 'PASSWORD_EXPIRED.REQUIREMENT.NOT_USED_BEFORE' | translate }}</span>
				</li>
			</ul>

			<button class="btn btn-primary" [disabled]="state.sending || !resetPWForm.valid">
				<i class="fa fa-spin fa-spinner ng-animate-disabled" *ngIf="state.sending"></i>
				<span>{{ 'PASSWORD_EXPIRED.SET' | translate }}</span>
			</button>
			<div class="text-align-right">
				<a routerLink="/login">{{ 'CANCEL' | translate }}</a>
			</div>

			<div class="error pre-ws">
				<span *ngIf="state.error">{{ state.error }}</span>
			</div>
		</form>
		<div *ngIf="languageNext === 'English'" class="lang">
			<button class="btn-lang" (click)="changeLang()"><span class="lang-icon">EN</span>English?</button>
		</div>
		<div *ngIf="languageNext === 'Deutsch'" class="lang">
			<button class="btn-lang" (click)="changeLang()"><span class="lang-icon">DE</span>Deutsch?</button>
		</div>
		<div *ngIf="state.sent" class="success-wrap">
			<div class="icon"><i class="fa fa-check"></i></div>
			<div class="msg">{{ state.successMsg }}</div>
			<a routerLink="/login">{{ 'PASSWORD_EXPIRED.TO_LOGIN' | translate }}</a>
		</div>
	</div>
</div>
