export interface SaveFiltersDTO {
	filters: CaseSearchFilters;
	title: string;
}

export interface EditFiltersDTO {
	filters: CaseSearchFilters;
}

export interface SaveFiltersResponseDTO {
	addedFilterID: string;
	filters: Array<CaseSearchFilters>;
}

export interface CaseSearchFilters {
	accTargetPropertyObjectIdentKey: string;
	accTargetPropertyObjectKeyID: number;
	accountingTarget: string;
	apleonaRoutingStep1: number;
	apleonaRoutingStep2: number;
	apleonaRoutingStep3: number;
	archive: 'all' | 'not' | 'only' | 'deleted' | 'only_not_deleted';
	archived: boolean;
	archivedAtFrom: string;
	archivedAtTo: string;
	assignedGroup: number;
	assignedUser: string;
	barcodeFrom: number;
	barcodeTo: number;
	billDateFrom: string;
	billDateTo: string;
	billNr: string;
	billingPeriodFrom: string;
	billingPeriodTo: string;
	billingUnit: string;
	bookingDateFrom: string;
	bookingDateTo: string;
	bookingType: 'bill' | 'credit';
	businessEntityKey: string;
	businessEntityName: string;
	caseDocumentCategories: Array<string>;
	caseDocumentTypes: Array<string>;
	caseEditorFilter: string;
	caseEditors: Array<string>;
	caseID: number;
	caseState: string;
	category: string;
	clientOrganizationID: number;
	companyCode: string;
	createdAtFrom: string;
	createdAtTo: string;
	creditorKey: string;
	creditorName: string;
	deleted: boolean;
	docType: string;
	editor: string;
	erpReceiptNumber: string;
	erpReceiptNumberFrom: string;
	erpReceiptNumberTo: string;
	erpTransactionNumber: string;
	erpTransactionNumberFrom: string;
	erpTransactionNumberTo: string;
	exclude: Array<number>;
	excludeCaseID: number;
	glAccounts: Array<string>;
	hasBillingUnit: 'all' | 'not' | 'only';
	hasCapturedDiscountText: string;
	hasContractAccount: 'both' | 'set' | 'not_set';
	hasCustomerNr: 'both' | 'set' | 'not_set';
	hasDiscountDueDate: string;
	hasDiscountRate: string;
	hasPdfText: boolean;
	hasPosTexts: string;
	hasServiceDescr: string;
	hasThumbs: boolean;
	hasUniversalBE: string;
	hasUsedDiscount: string;
	hasUsedFullDiscount: string;
	isUtilityBill: boolean;
	langIso1: string;
	needle: string;
	netPrice: string;
	netPriceAssignments: string;
	netPriceAssignmentsPos: string;
	orderNr: string;
	pdfText: string;
	pdfTextFirstPage: string;
	processStep: string;
	processSteps: Array<string>;
	pspElementKey: string;
	pspProject: string;
	qcEndFrom: string;
	qcEndTo: string;
	release: 'all' | 'me' | 'meorgroup' | 'notmebutgroup' | 'rejectme' | 'rejectmeorgroup' | 'rejectnotmebutgroup';
	reverseCharge: string;
	sapFiscalYear: number;
	sapReceiptNumbers: Array<string>;
	serviceChargeKey: string;
	serviceDescription: string;
	servicePeriodFrom: string;
	servicePeriodTo: string;
	serviceType: number;
	source: string;
	targetSoftware: string;
	taxCode: string;
	taxCodeID: number;
	tenantNumber: string;
	totalAccountAssignments: number;
	totalAccountAssignmentsPos: number;
	totalBusinessUnits: number;
	totalPrice: string;
	totalPriceAssignments: string;
	totalPriceAssignmentsPos: string;
	uploadedByFastLane: boolean;
	uploadedBySCUser: boolean;
	uploaderFullName: string;
	uploaderGroupName: string;
	vh: number;
	vw: number;
}
