<ngx-file-drop
  #fileDrop
  dropZoneClassName="drop-zone"
  contentClassName="drop-zone-content"
  (onFileDrop)="dropped($event)"
  [accept]="accept"
  [disabled]="disabled"
  [multiple]="multiple"
  (click)="openFileSelector()"
>
  <ng-template ngx-file-drop-content-tmp>
    <ng-content></ng-content>
  </ng-template>
</ngx-file-drop>
