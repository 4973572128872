<label class="switch">
  <input type="checkbox" class="switch-input" [checked]="checked" (click)="onActionToggle()">
  <div class="switch-group">
    <span class="switch-off text-center" [innerHTML]="offText">
    </span>
    <span class="switch-on text-center" [innerHTML]="onText">
    </span>
  </div>
  <span class="grip"></span>
</label>
