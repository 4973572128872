import { Component, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgxFileDropComponent, NgxFileDropEntry } from 'ngx-file-drop';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-file-drop',
	templateUrl: './file-drop.component.html',
	styleUrls: ['./file-drop.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class FileDropComponent implements OnInit {
	@ViewChild('fileDrop') fileDrop: NgxFileDropComponent;
	@Output() dropEvent = new Subject<NgxFileDropEntry[]>();
	@Output() clickEvent = new Subject();

	@Input() multiple = false;
	@Input() disabled = false;
	@Input() accept = '';
	@Input() subTitle: string;
	@Input() canDrop: boolean | undefined = true;
	files: NgxFileDropEntry[] = [];
	get anyFile() {
		return !this.accept;
	}
	get excelFile() {
		return !!this.accept.split(',').find((x) => x.toLocaleLowerCase() === '.xlsx');
	}
	get pdfFile() {
		return !!this.accept.split(',').find((x) => x.toLocaleLowerCase() === '.pdf');
	}
	constructor() {}

	ngOnInit(): void {}

	dropped(files: NgxFileDropEntry[]) {
		if (this.canDrop) {
			this.files = files;
			this.dropEvent.next(files);
		}
	}
	openFileSelector() {
		if (this.canDrop) {
			this.fileDrop.openFileSelector();
		}
		this.clickEvent.next(null);
	}
}
