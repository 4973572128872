import { IDict, IFormattedNumber, IPageThumb } from '..';

export interface ISlaDate {
	formattedDay: string;
	total: number;
	ocr: number;
	qs: number;
	done: number;
}

export interface IEntryParamDto {
	cat?: number;
	sentBack?: string;
	type: 'latest' | 'own' | 'rejected' | 'group';
	step?: number;
	group?: any;
}

export interface IEntryDto {
	title: string;
	total: number;
	params: IEntryParamDto;
	children?: Array<IEntryDto>;
}

export interface IInboxDto {
	entries: Array<IEntryDto>;
	filters: {
		serviceTypes: Array<IDict>;
		states: Array<IDict>;
		documentTypes: Array<IDict>;
		documentCategories: Array<IDict>;
		hintTypes: Array<IDict<string>>;
	};
	nextSlaDates: Array<ISlaDate>;
}

export interface ICaseListItem {
	id: number;
	userID: number;
	assignedGroupTitle: string;
	assignedUserFirstName: string;
	assignedUserLastName: string;
	beCCKey: string;
	beCity: string;
	beID: string;
	beKey: string;
	beName: string;
	billDate: string;
	billNumber: string;
	businessPartnerBusinessKey: string;
	businessPartnerErrMsg: string;
	businessPartnerName: string;
	caseCreatedAt: string;
	caseDocCategories: Array<string>;
	caseDocCategoryIDs: Array<number>;
	caseEditorID: string;
	creditorDataID: number;
	creditorKey: string;
	creditorName: string;
	currency: string;
	daysBetween: number;
	debitorDataID: number;
	debitorKey: string;
	debitorName: string;
	docTitle: string;
	docTypeID: string;
	editedByFullName: string;
	editedByID: number;
	firstPageThumb: IPageThumb;
	fullName: string;
	groupID: number;
	inboxCategoryID: string;
	inboxEntryType: string;
	inboxID: number;
	inboxNote: string;
	isResubmission: boolean;
	lastProcessStartedAt: string;
	lastUserSentBack: boolean;
	netPriceObj: IFormattedNumber;
	openLogDisplayName: string;
	processStep: string;
	processStepID: string;
	serviceTypeText: string;
	slaClass: string;
	slaEnd: string;
	slaEndsIn: string;
	state: string;
	stateClass: string;
	stateID: string;
	totalErrors: number;
	totalMessages: number;
	totalPriceObj: IFormattedNumber;
	totalWarnings: number;
	uploadedBy: number;
}

export interface ICompactCase {
	needle: string;
	totalResults: number;
	hasMore: boolean;
	lastItemInfo?: any;
	lastItemClasses?: any;
	cols: string[];
	results: ICompactCaseResult[];
}

interface ICompactCaseResult {
	html: boolean;
	cssClasses?: any;
	table: boolean;
	headers?: any;
	id: number;
	rows: string[];
	extendedTitle?: any;
	displayTitle: string;
}
