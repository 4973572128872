export class FileDto {
	fileName: string;
	shortFileName: string;
	id: number;
	entries: any;
	errorMsg: string;
	errorParam: string;
	normalSuccessMsg: string;
	progress: number;
	isProgress: boolean;
	successMsg: string;
	result?: any;
	fileSize?: any;
	error: any;
	fastlaneSuccessMsg: string;
}
