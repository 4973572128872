import { Component, inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalService } from '@app/services/common/modal.service';

@Component({
	selector: 'app-modal-outlet',
	template: ` <ng-container #view></ng-container> `
})
export class ModalOutletComponent implements OnInit {
	@ViewChild('view', { static: true, read: ViewContainerRef })
	vcr!: ViewContainerRef;

	constructor(private modalService: ModalService) {}
	ngOnInit(): void {
		this.modalService.registerOutlet(this.vcr);
	}
}
