<div class="wrapper">
  <ng-container *ngIf="pdfSrc else loadingTemplate">
    <div class="controls">
      <div class="mb">
        <button (click)="incrementPage(1)" [disabled]="!pdf || (pdf && page === pdf.numPages)" type="button">
          <i class="fa fa-solid fa-arrow-up"></i>
        </button>
        <button (click)="incrementPage(-1)" [disabled]="!pdf || page === 1" type="button">
          <i class="fa fa-solid fa-arrow-down"></i>
        </button>

        <input class="input pages" [disabled]="!pdf" type="number" placeholder="Page" [(ngModel)]="page"
               pattern="[[1-9][0-9]+"/>

        <span *ngIf="pdf">of {{ pdf.numPages }}</span>
      </div>

      <div class="mb middle">
        <button [disabled]="!pdf" (click)="incrementZoom(-0.1)" type="button">
          <i class="fa fa-solid fa-minus"></i>
        </button>

        <button [disabled]="!pdf" (click)="incrementZoom(0.1)" type="button">
          <i class="fa fa-solid fa-plus"></i>
        </button>

        <button [disabled]="!pdf" (click)="rotateRight(90)" type="button">
          <i class="fa fa-solid fa-undo"></i>
        </button>

        <button [disabled]="!pdf" (click)="rotateLeft(90)" type="button">
          <i class="fa fa-solid fa-repeat"></i>
        </button>
      </div>
      <div class="file_controls_wrapper">
        <button [disabled]="!pdf" (click)="print()">
          <i class="fa fa-solid fa-print"></i>
        </button>
        <button [disabled]="!pdf" (click)="download()">
          <i class="fa fa-solid fa-download"></i>
        </button>
      </div>
    </div>
    <button *ngIf="canUseImgViewer" class="reload_button" (click)="reload()"><i class="fa fa-fw fa-image"></i></button>
    <div class="pdfWrapper">
      <div class="error" *ngIf="error">
        <img [src]="errorSrc"/>
      </div>
      <div *ngIf="!isLoaded && !error && progressData" class="progress">
        <div class="bg">
          <div class="bar" [style.width]="(progressData.loaded / progressData.total) * 100 + '%'"></div>
        </div>
        <span>{{ getInt((progressData.loaded / progressData.total) * 100) }}%</span>
      </div>
      <pdf-viewer
        [show-all]="showAll"
        [src]="pdfSrc"
        [(page)]="page"
        [rotation]="rotate"
        [render-text]="true"
        [original-size]="false"
        [zoom]="zoom"
        [autoresize]="true"
        [zoom-scale]="zoomScale"
        (after-load-complete)="afterLoadComplete($event)"
        (on-progress)="onProgress($event)"
      ></pdf-viewer>
    </div>
  </ng-container>
  <ng-template #loadingTemplate>
    <ng-container *ngIf="!error">Loading...</ng-container>
    <div class="error" *ngIf="error">
      <img [src]="errorSrc"/>
    </div>
  </ng-template>
</div>
