import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DestroyService } from 'src/core/services/common/destroy.service';
import { lastValueFrom, takeUntil } from 'rxjs';
import { LANGUAGES } from '@app/models/index';
import { AuthService } from '@app/services/common/auth.service';
import { EuconStorageService } from '@app/services/common/eucon-storage.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { EuconErrorResponse } from '@app/models/responses/error/EuconErrorResponse';

@Component({
	selector: 'app-login-page',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	providers: [DestroyService]
})
export class LoginComponent implements OnInit {
	loginForm?: FormGroup;
	languageNext?: string;
	environment = environment;

	state: { authenticating: boolean; errorMessage: string | null; loggedIn: boolean } = {
		authenticating: false,
		errorMessage: null,
		loggedIn: false
	};

	constructor(
		private fb: FormBuilder,
		private translateService: TranslateService,
		public authService: AuthService,
		private router: Router,
		private destroy$: DestroyService,
		private euconStorageService: EuconStorageService
	) {}

	ngOnInit() {
		const email = this.euconStorageService.getData('email');
		const rememberEmail = !!email;
		const username = rememberEmail ? email : '';

		this.loginForm = this.fb.group({
			username: [username, [Validators.email, Validators.required]],
			password: ['', Validators.required],
			rememberEmail: [rememberEmail]
		});
		this.languageNext = LANGUAGES[this.getNextLanguage()];

		if (this.authService.getUnauthorizedMsg) {
			this.state.errorMessage = this.authService.getUnauthorizedMsg;
			this.authService.setUnauthorizedMsg = null;
		}
	}

	getNextLanguage() {
		const langKeys = Object.keys(LANGUAGES);
		const currentIndex = langKeys.indexOf(this.translateService.currentLang);
		return currentIndex + 1 >= langKeys.length ? langKeys[0] : langKeys[currentIndex + 1];
	}

	onLogin() {
		if (this.loginForm?.valid) {
			const username = this.loginForm.get('username')?.value;
			const password = this.loginForm.get('password')?.value;
			const rememberEmail = this.loginForm.get('rememberEmail')?.value;

			this.authService.authenticate(username, password).subscribe(
				(authInfo) => {
					this.state.authenticating = false;
					this.state.loggedIn = true;
					this.authService.authenticated(authInfo, rememberEmail ? username : undefined);
					setTimeout(() => {
						this.state.loggedIn = true;
						if (window.location.search) {
							const params = new URLSearchParams(window.location.search);
							const redirect = params.get('redirect');

							if (redirect) {
								window.location.href = redirect;
								return;
							}
						}

						if (this.authService.returnToState) {
							const curState = this.authService.returnToState;
							this.authService.returnToState = null;
							if (curState) {
								this.router.navigateByUrl(curState);
							}
							return;
						}
						this.router.navigateByUrl('/');
					}, 1000);
				},
				async ({ error: { errorCode, msg } }: EuconErrorResponse) => {
					this.state.authenticating = false;
					if (!errorCode) {
						// when there is no errorCode we want to check if the backend simply did not respond
						// or if the backend is running the legacy error-message-handling -> msg attribute is present
						this.state.errorMessage =
							msg ?? (await lastValueFrom(this.translateService.get('ERROR.SERVICE_NOT_AVAILABLE')));
						return;
					}
					let errorMessage = await lastValueFrom(this.translateService.get(`ERROR.${errorCode}`));
					this.state.errorMessage =
						errorMessage ?? (await lastValueFrom(this.translateService.get('ERROR.GENERAL_ERROR')));
				}
			);
		}
	}

	changeLang() {
		const nextLanguage = this.getNextLanguage();
		this.euconStorageService.setData('language', nextLanguage);
		this.translateService
			.use(nextLanguage)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => (this.languageNext = LANGUAGES[this.getNextLanguage()]));
	}
}
