import { HttpParams } from '@angular/common/http';

export class FilterObject {
	constructor(
		public name: string = '',
		public operation: string = '',
		public value: any,
		public groupName?: string
	) {}
}

export class GridFilter {
	filters: FilterObject[] = [];

	get hasFilters() {
		return this.filters.length;
	}

	constructor(filterOption?: any) {
		if (filterOption) {
			this.parseFilter(filterOption);
		}
	}

	addFilter(name: string, operation: string, value: any, groupName?: string) {
		//dirtyFix

		if (name == 'repliedAtFormatted') {
			name = 'repliedAt';
		}

		if (name == 'repliedToFormatted') {
			name = 'repliedTo';
		}

		if (name == 'createdAtFormatted') {
			name = 'createdAt';
		}

		if (name == 'createdToFormatted') {
			name = 'createdTo';
		}

		value = this.checkValues(value);
		switch (operation) {
			case 'between':
				this.filters.push(new FilterObject(name + 'From', '=', value[0], groupName));
				this.filters.push(new FilterObject(name + 'To', '=', value[1], groupName));
				break;
			case '<=':
				this.filters.push(new FilterObject(name + 'To', '=', value, groupName));
				break;
			case '>=':
				this.filters.push(new FilterObject(name + 'From', '=', value, groupName));
				break;
			default:
				this.filters.push(new FilterObject(name, operation, value, groupName));
		}
	}

	private checkValues(value: any) {
		if (Array.isArray(value)) {
			for (let index = 0; index < value.length; index++) {
				const element = value[index];
				if (element == null || element == undefined || undefined == 'undefined') {
					value[index] = '';
				}
			}
		} else {
			if (value == undefined || value == null || value == 'undefined') {
				value = '';
			}
		}

		return value;
	}

	setFilter(filterObject: FilterObject) {
		const { name, operation, value, groupName } = { ...filterObject };

		const id = this.filters.findIndex((f) => f.name === name);
		if (id < 0) {
			this.addFilter(name, operation, value, groupName);
		} else {
			Object.assign(this.filters[id], { name, operation, value, groupName });
		}
	}

	removeFilter(name: string) {
		const id = this.filters.findIndex((f) => f.name === name);
		if (id > -1) {
			this.filters.splice(id, 1);
		}
	}

	parseFilter(filterOption: any) {
		const filterArray = filterOption as Array<any>;

		if (filterArray[1] === 'and') {
			filterArray.forEach((paramArray: Array<any>) => {
				if (Array.isArray(paramArray)) {
					if (Array.isArray(paramArray[0])) {
						const subArray = paramArray[0] as Array<any>;
						this.addFilter(subArray[0], subArray[1], subArray[2]);
					}

					if (Array.isArray(paramArray[2])) {
						const subArray = paramArray[2] as Array<any>;
						this.addFilter(subArray[0], subArray[1], subArray[2]);
					} else {
						this.addFilter(paramArray[0], paramArray[1], paramArray[2]);
					}
				}
			});
		}

		if (!this.filters.length) {
			this.addFilter(filterArray[0], filterArray[1], filterArray[2]);
		}
	}

	parseFilterFromJson(jsonString: string): FilterObject[] | undefined {
		if (!jsonString) {
			return;
		}

		const filterObject = JSON.parse(jsonString);
		if (!(filterObject instanceof Array)) {
			return;
		}

		(filterObject as FilterObject[]).forEach((filter) => {
			this.addFilter(filter.name, filter.operation, filter.value, filter.groupName);
		});

		return;
	}

	toString() {
		return JSON.stringify(this.filters);
	}
}

export function addParamToFilter(
	params: HttpParams,
	paramName: string,
	paramValue: string,
	paramOperation?: string,
	groupName?: string
) {
	const filter = new GridFilter();
	filter.parseFilterFromJson(params.get('filter') || '');
	filter.addFilter(paramName, paramOperation || '=', paramValue, groupName);
	return params.set('filter', filter.toString());
}
