import { IDict } from '..';

export interface SearchWrapResponse {
	data: SearchWrap | null;
	totalCount: number;
	currentPage: number;
	totalPages: number;
}

export interface SearchWrap {
	aggs: Array<AggWrap>;
	caseDocumentCategories: Array<IDict>;
	caseDocumentTypes: Array<IDict>;
	caseEditors: Array<IDict>;
	cases: Array<IndexedCaseDTO>;
	processSteps: Array<IDict>;
}

export interface AggWrap {
	entries: Array<Agg>;
	title: string;
}

export interface IndexedCaseDTO {
	apleonaRoutingStep1: string;
	apleonaRoutingStep2: string;
	apleonaRoutingStep3: string;
	archived: boolean;
	archivedAt: string;
	assignedGroup: string;
	assignedTo: string;
	assignedUserName: string;
	avgCheckWordsInDicRatio: string;
	avgGoodCharsRatio: string;
	billDate: string;
	billNumber: string;
	businessEntityKey: string;
	businessEntityName: string;
	canRequestCapturing: boolean;
	capturedContractAccount: string;
	capturedCustomerNr: string;
	caseEditorName: string;
	companyCode: string;
	createdAt: string;
	creditorID: string;
	creditorName: string;
	deleted: boolean;
	documentCategories: string;
	documentType: string;
	euconBarcode: number;
	firstPageMiniThumb: CaseThumb;
	firstPageThumb: CaseThumb;
	fondName: string;
	hasPdfText: boolean;
	hasThumbs: boolean;
	highlightedText: string;
	id: number;
	idStr: string;
	isOptimizedPDF: boolean;
	lang: string;
	netPrice: string;
	orderNumber: string;
	pdfID: number;
	processStep: string;
	sapFiscalYears: string;
	sapOrderBusinessKeys: string;
	sapReceiptNumbers: string;
	sapTransactionNumbers: string;
	slaEndsAt: string;
	state: string;
	summaryLine: string;
	totalChanges: number;
	totalErrors: number;
	totalMessages: number;
	totalPages: number;
	totalPrice: string;
	totalWarnings: number;
	uploaderFullName: string;
	uploaderGroupName: string;
	utilityBill: boolean;
	vatPrice: string;
	vatRate: string;
}

export interface Agg {
	applicable: boolean;
	id: number;
	title: string;
	total: number;
	totalValues: number;
	vals: Array<Vals>;
}
export interface CaseThumb {
	caseID: number;
	height: number;
	url: string;
	width: number;
}
export interface Vals {
	current: boolean;
	id: number;
	key: string;
	percent: string;
	score: number;
	total: number;
	totalAll: number;
}
