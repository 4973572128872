import { Injectable } from '@angular/core';
import { EuconStorageService } from './eucon-storage.service';
import { Router } from '@angular/router';
import { tokenDataKey, userDataKey } from '@app/models/index';
import { AuthApiService } from 'src/core/api/auth.api.service';
import { User, Permission, AuthorityTitle, Authorities, AuthInfo } from '@app/models/dto/common/auth.info..dto';
import { environment } from 'src/environments/environment';
import { Observable, switchMap, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FeatureFlagService } from '@app/services/common/feature-flag.service';
import { Tenant } from '@app/models/constants/tenants';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	lockedCaseID: any;
	caseLogId: any;
	private _token: string | null;
	private _user: User;
	private _unauthorizedMsg: string | null;
	private tenants = Tenant;

	constructor(
		private euconStorageService: EuconStorageService,
		private router: Router,
		private authApiService: AuthApiService,
		private translateService: TranslateService,
		private featureFlagService: FeatureFlagService
	) {}

	private _returnToState: string | null;

	get returnToState() {
		return this._returnToState || this.euconStorageService.getData('returnToState');
	}

	set returnToState(val: string | null) {
		this._returnToState = val;
		this.euconStorageService.setData('returnToState', val);
	}

	get getUnauthorizedMsg(): string | null {
		return this._unauthorizedMsg;
	}

	set setUnauthorizedMsg(msg: string | null) {
		this._unauthorizedMsg = msg;
	}

	get canOpenAnyReport(): boolean {
		return this.getUser().canOpenAnyReport;
	}

	get hasAccessToILVTool(): boolean {
		return this.getUser().hasAccessToILVTool;
	}

	get tenant(): Tenant {
		return environment.tenant;
	}

	get isVoelkel(): boolean {
		return environment.tenant === this.tenants.VOELKEL;
	}

	get isDeka(): boolean {
		return environment.tenant === this.tenants.DEKA;
	}

	get isNotVoelkel(): boolean {
		return !this.isVoelkel;
	}

	get isApleona(): boolean {
		return environment.tenant === this.tenants.APLEONA;
	}

	get groupLongTitle(): string {
		return this.getUser().groupLongTitle;
	}

	get permissions(): Permission[] {
		return this.getUser().permissions;
	}

	get authorityTitles(): AuthorityTitle[] {
		return this.getUser().authorityTitles;
	}

	get genericRoles(): any[] {
		return this.getUser().genericRoles;
	}

	get canUpload(): boolean {
		return this.getUser().canUpload;
	}

	get canUploadFastLane(): boolean {
		if (!this.canUpload) {
			return false;
		}

		return this.getUser() && this.getUser().canUploadFastLane;
	}

	get canUploadFastLaneOCR(): boolean {
		if (!this.canUpload) {
			return false;
		}

		return this.getUser() && this.getUser().canUploadFastLaneOCR;
	}

	get authorities(): Authorities {
		return this.getUser().authorities;
	}

	get hasAccessToAdminPanel(): boolean {
		return this.getUser().hasAccessToAdminPanel;
	}

	get hasAccessToAdminPanelRecurringPaymentPlan(): boolean {
		return this.getUser().hasAccessToAdminPanelRecurringPaymentPlan;
	}

	get showSpecialAndCorporateAssetFastLaneCategories() {
		return this.getUser().showSpecialAndCorporateAssetFastLaneCategories;
	}

	get showURFFastLaneCategory() {
		return this.getUser().showURFFastLaneCategory;
	}

	get isPmAdmin(): boolean {
		return this.getUser().authorities.ROLE_PM_ADMIN;
	}

	get isUserAdmin(): boolean {
		return this.getUser().authorities.ROLE_USER_ADMIN;
	}

	get isSuperAdmin(): boolean {
		return this.getUser().authorities.ROLE_SUPER_ADMIN;
	}

	get isAdmin(): boolean {
		return this.getUser().authorities.ROLE_ADMIN;
	}

	get isPM(): boolean {
		return this.getUser().authorities.ROLE_ADMIN || (this.getUser().hasAccessToAdminPanel && this.getUser().isPM);
	}

	get isEuconUser(): boolean {
		return this.getUser().isEuconUser;
	}

	get isUnion(): boolean {
		return environment.tenant === this.tenants.UNION;
	}

	get isShowRecurringPayments(): boolean {
		return this.isApleona;
	}

	get showAutoprocessCasesTable(): boolean {
		return this.isApleona;
	}

	get isAmpega(): boolean {
		return environment.tenant === this.tenants.AMPEGA;
	}

	get isStrabag(): boolean {
		return environment.tenant === this.tenants.STRABAG;
	}

	getToken() {
		return this._token || this.euconStorageService.getToken();
	}

	getUser() {
		return this._user || this.euconStorageService.getUser();
	}

	setLockedCaseID(id: any) {
		this.lockedCaseID = id;
	}

	getLockedCaseID() {
		return this.lockedCaseID;
	}

	setCaseLogId(id: any) {
		this.caseLogId = id;
	}

	getCaseLogId() {
		return this.caseLogId;
	}

	authenticate(username: string, password: string) {
		return this.authApiService.logIn(username, password);
	}

	authenticated(authInfo: AuthInfo, usernameToSave: string | undefined) {
		this._token = authInfo.token;
		this._user = authInfo.user;
		this.euconStorageService.saveToken(this._token);
		this.euconStorageService.saveUser(this._user);
		if (this.featureFlagService.isFeatureEnabled('ilvTool')) {
			if (!this._token) {
				this.euconStorageService.removeData('ILV_AUTH_TOKEN');
			} else {
				this.euconStorageService.setData('ILV_AUTH_TOKEN', this._token);
			}
			if (!this._user) {
				this.euconStorageService.removeData('ILV_USER');
			} else {
				this.euconStorageService.setData('ILV_USER', this._user);
			}
		}

		if (!!usernameToSave) this.euconStorageService.setData('email', usernameToSave);
		else this.euconStorageService.removeData('email');
	}

	isLoggedIn() {
		return !!this.euconStorageService.getUser();
	}

	logOut() {
		if (this.getToken())
			this.authApiService.logOut().subscribe(() => {
				this.removeAuthInfo();
				this.router.navigateByUrl('/login');
			});
	}
	changeLang(lang: string) {
		return this.authApiService.changeLang(lang).pipe(
			tap(() => {
				this.translateService.use(lang);
				this.euconStorageService.setData('language', lang);
			}),
			switchMap(() => this.authApiService.getMe())
		);
	}
	removeAuthInfo() {
		this.euconStorageService.removeData(userDataKey);
		this.euconStorageService.removeData(tokenDataKey);
		this.euconStorageService.removeData('ILV_AUTH_TOKEN');
		this.euconStorageService.removeData('ILV_USER');
		this._token = null;
	}
	getMe() {
		return this.authApiService.getMe().pipe(
			tap((u) => {
				this._user = u;
				this.euconStorageService.saveUser(this._user);
				if (this.translateService.currentLang !== this._user.langKey) {
					this.translateService.use(this._user.langKey);
				}
			})
		);
	}

	forgotPassword(email: string) {
		return this.authApiService.forgotPassword(email);
	}

	checkResetKey(key: string, email: string): Observable<any> {
		return this.authApiService.checkResetKey(key, email);
	}

	resetPassword(key: string, email: string, password: string): Observable<any> {
		return this.authApiService.resetPassword(key, email, password);
	}
}
