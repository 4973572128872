import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/services/common/auth.service';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { HttpResponseApiService } from '../api/http-response.api.service';
import { ToastService } from '@app/services/common/toast.service';

@Injectable({
	providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
	constructor(
		private authService: AuthService,
		private router: Router,
		private translate: TranslateService,
		private httpResponseApiService: HttpResponseApiService,
		private toastService: ToastService,
		private translatePipe: TranslatePipe
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = this.authService.getToken();
		let authReq = req;
		if (token)
			authReq = req.clone({
				headers: req.headers
					.set('BAuthorization', `Bearer ${this.authService.getToken()}`)
					.set('Accept-Language', this.translate?.currentLang || 'de')
			});
		return next.handle(authReq).pipe(
			tap((httpEvent) => {
				if (httpEvent.type != 0) {
					this.httpResponseApiService.setResponseHeaders(httpEvent);
				}
			}),
			catchError((err) => {
				switch (<HttpStatusCode | 0>err.status) {
					// We want to catch the 'no-server-response-error' here to show a message
					case 0: {
						if (window.location.pathname !== '/login')
							this.toastService.error(
								this.translatePipe.transform('ERROR.SERVICE_NOT_AVAILABLE_TITLE'),
								this.translatePipe.transform('ERROR.SERVICE_NOT_AVAILABLE'),
								5000
							);
						this.authService.removeAuthInfo();
						if (window.location.pathname !== '/login')
							this.authService.returnToState = `${window.location.pathname}${window.location.search}`;
						this.router.navigateByUrl('/login');
						break;
					}
					case HttpStatusCode.Unauthorized: {
						this.authService.removeAuthInfo();
						if (window.location.pathname !== '/login') this.router.navigateByUrl('/login');
						break;
					}
					case HttpStatusCode.PreconditionRequired: {
						if (err.data && err.data.message) {
							this.authService.setUnauthorizedMsg = err.data.message;
						}
						this.router.navigateByUrl('/forgot-password');
						break;
					}
				}
				return throwError(err);
			})
		);
	}
}
