import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LANGUAGES } from '@app/models/constants';
import { AuthService } from '@app/services/common/auth.service';
import { DestroyService } from '@app/services/common/destroy.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';
import { AuthApiService } from 'src/core/api/auth.api.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-password-expired',
	templateUrl: './password-expired.component.html',
	styleUrls: ['./password-expired.component.scss'],
	providers: [DestroyService]
})
export class PasswordExpiredComponent implements OnInit {
	resetPWForm?: FormGroup;
	languageNext?: string;
	environment = environment;

	state = {
		sending: false,
		sent: false,
		error: null,
		successMsg: null
	};
	rules: { [key: string]: boolean } = {
		TOO_SHORT: false,
		INSUFFICIENT_CHARACTERISTICS: false,
		INSUFFICIENT_DIGIT: false,
		INSUFFICIENT_SPECIAL: false,
		INSUFFICIENT_UPPERCASE: false,
		INSUFFICIENT_LOWERCASE: false,
		USED_BEFORE: false,
		ILLEGAL_WORD: false
	};
	get email() {
		return this.resetPWForm?.get('email');
	}
	get current() {
		return this.resetPWForm?.get('current');
	}
	get pw1() {
		return this.resetPWForm?.get('pw1');
	}
	constructor(
		private fb: FormBuilder,
		private translateService: TranslateService,
		public authService: AuthService,
		private authApi: AuthApiService,
		private destroy$: DestroyService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.resetPWForm = this.fb.group({
			email: [{ value: '', disabled: this.state.sending }, Validators.email],
			current: [{ value: '', disabled: this.state.sending }, Validators.required],
			pw1: [{ value: '', disabled: this.state.sending }, Validators.required]
		});
		this.languageNext = LANGUAGES[this.getNextLanguage()];
		if (this.authService.isLoggedIn()) {
			this.router.navigateByUrl('/index');
		}
	}
	doSetPW() {
		this.state.sending = true;
		this.authApi.setNewPasswordForEmail(this.email?.value, this.current?.value, this.pw1?.value).subscribe({
			next: () => {
				this.state.sending = false;
				this.state.sent = true;
				this.translateService.get('PASSWORD_EXPIRED.SUCCESS').subscribe((msg) => {
					this.state.successMsg = msg;
				});
				this.pw1?.setValue(null);
			},
			error: (response) => {
				this.state.sending = false;
				if (response.status === 403) {
					this.router.navigateByUrl('/login');
				} else if (response.status === 400) {
					if (response.data && response.data.violations) {
						for (const key in this.rules) {
							this.rules[key] = !response.data.violations[key];
						}

						const violations = Object.values(response.data.violations).join('\n');
						this.translateService
							.get('PASSWORD_EXPIRED.ERROR.OPERATION_FAILED_DETAILS', { violations })
							.subscribe((msg) => {
								this.state.error = msg;
							});
					} else {
						this.translateService.get('PASSWORD_EXPIRED.ERROR.OPERATION_FAILED').subscribe((msg) => {
							this.state.error = msg;
						});
					}
				}
			}
		});
	}
	changeLang() {
		const nextLanguage = this.getNextLanguage();
		this.translateService
			.use(nextLanguage)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => (this.languageNext = LANGUAGES[this.getNextLanguage()]));
	}
	getNextLanguage() {
		const langKeys = Object.keys(LANGUAGES);
		const currentIndex = langKeys.indexOf(this.translateService.currentLang);
		return currentIndex + 1 >= langKeys.length ? langKeys[0] : langKeys[currentIndex + 1];
	}

	resetError() {
		this.state.error = null;
	}
}
