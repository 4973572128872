<div ngbDropdown
     class="d-inline-block"
     [autoClose]="autoClose"
     [display]="display"
     [dropdownClass]="dropdownClass"
     [open]="openFlag"
     (openChange)="openChange.emit($event)">
  <button ngbDropdownToggle class="btn btn-{{btnTypeClass}} {{btnCssClass}}">
    <i *ngIf="icon" [class]="getIconClass(icon)" aria-hidden="true"></i>
    {{label}}
  </button>
  <div *ngIf="items && items.length > 0" ngbDropdownMenu>
    <ng-container *ngFor="let item of items">
      <ng-container *ngTemplateOutlet="getTemplate(item);context:{ $implicit: item }">
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #defaultTmpl euconTemplate="{{getTypeName(optionTypeEnum.Standard)}}" let-item>
  <li
    *ngIf="getVisibility(item)"
    class="dropdown-item {{item.cssClass}}"
    [title]="item.tooltip || ''">
    <i *ngIf="item.icon" [class]="getIconClass(item.icon)"></i>{{item.text}}
  </li>
</ng-template>
<ng-template euconTemplate="{{getTypeName(optionTypeEnum.Header)}}" let-item>
  <li
    *ngIf="getVisibility(item)"
    class="dropdown-header {{item.cssClass}}"
    [title]="item.tooltip || ''">
    <i *ngIf="item.icon" [class]="getIconClass(item.icon)"></i><b>{{item.text}}</b>
  </li>
</ng-template>
<ng-template euconTemplate="{{getTypeName(optionTypeEnum.Divider)}}" let-item>
  <li
    *ngIf="getVisibility(item)"
    class="divider {{item.cssClass}}">
  </li>
</ng-template>
<ng-template euconTemplate="{{getTypeName(optionTypeEnum.Link)}}" let-item>
  <li
    *ngIf="getVisibility(item)"
    class="dropdown-item dropdown-item-clickable {{item.cssClass}}"
    [title]="item.tooltip || ''">
    <a *ngIf="item.isExternalUrl !== true" routerLink="{{item.url}}" [target]="item.isTargetBlank ? '_blank' : '_self'">
      <i *ngIf="item.icon" [class]="getIconClass(item.icon)"></i>{{item.text}}
    </a>
    <a *ngIf="item.isExternalUrl === true" href="{{item.url}}" [target]="item.isTargetBlank ? '_blank' : '_self'">
      <i *ngIf="item.icon" [class]="getIconClass(item.icon)"></i>{{item.text}}
    </a>
  </li>
</ng-template>
<ng-template euconTemplate="{{getTypeName(optionTypeEnum.ActionButton)}}" let-item>
  <li
    *ngIf="getVisibility(item)"
    class="dropdown-item pointer dropdown-item-clickable {{item.cssClass}}"
    [title]="item.tooltip || ''"
    (click)="item.onActionClicked && item.onActionClicked()">
    <i *ngIf="item.icon" [class]="getIconClass(item.icon)"></i>{{item.text}}
    <span *ngIf="item.textHTML" [innerHtml]="item.textHTML"></span>
  </li>
</ng-template>

<ng-content></ng-content>
