import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthInfo, User } from '@app/models/dto/common/auth.info..dto';
import { Observable } from 'rxjs';
import { BaseApiService } from './base.api.service';

@Injectable({
	providedIn: 'root'
})
export class AuthApiService extends BaseApiService {
	constructor(http: HttpClient) {
		super(http);
	}

	logIn(username: string, password: string) {
		let headers = new HttpHeaders();
		headers.set('Accept-Language', 'application/json');
		return this.http.post<AuthInfo>(`${this.baseUrl}/login`, { username, password });
	}
	logOut() {
		return this.http.post(`${this.baseUrl}/logout`, null);
	}
	changeLang(lang: string) {
		return this.http.post(`${this.baseUrl}/user/lang`, { lang });
	}

	getMe(): Observable<User> {
		return this.http.get<User>(`${this.baseUrl}/me`);
	}

	forgotPassword(email: string) {
		return this.http.post(`${this.baseUrl}/users/forgot-pw`, { email });
	}
	checkResetKey(key: string, email: string) {
		return this.http.get(`${this.baseUrl}/users/forgot-pw/reset`, { params: { key, email } });
	}

	resetPassword(key: string, email: string, password: string) {
		return this.http.post(`${this.baseUrl}/users/forgot-pw/reset`, { key, email, password });
	}
	setNewPasswordForEmail(email?: string, currentPassword?: string, newPassword?: string) {
		return this.http.post(`${this.baseUrl}/user/set-new-password-for-email`, {
			email,
			currentPassword,
			newPassword
		});
	}
}
