<!--<ngb-toast header="Erfolg!" [autohide]="false">-->
<!--  Aktion erfolgreich durchgefuhrt.-->
<!--</ngb-toast>-->

<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [title]="toast.title"
  [class]="toast.classname"
  [autohide]="true"
  [delay]="toast.delay"
  (hidden)="toastService.remove(toast)"
>
  <div class="toast-content">
    <p class="toast-title">{{ toast.title }}</p>
    <p class="toast-msg">{{ toast.msg }}</p>
  </div>
  <button class="toast-close-btn" (click)="close(toast)">x</button>
</ngb-toast>
