import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { LoadingScreenService } from '@app/services/common/loading-screen.service';

@Component({
	selector: 'app-loading-screen',
	templateUrl: './loading-screen.component.html',
	styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnChanges, AfterViewInit {
	@Input()
	private state: boolean = false;

	@ViewChild('loadingScreenContainer')
	loadingScreenContainer: ElementRef<HTMLDivElement>;

	constructor(private loadingScreenService: LoadingScreenService) {}

	/**
	 * Reaction to the @Input() changes of the state attribute
	 * @param changes
	 */
	ngOnChanges(changes: SimpleChanges): void {
		if (changes['state'].currentValue !== changes['state'].previousValue) {
			this.triggerAnimation(changes['state'].currentValue);
		}
	}

	ngAfterViewInit(): void {
		this.loadingScreenService.state.subscribe((nextState) => {
			if (this.state !== nextState) {
				this.state = nextState;
				this.triggerAnimation(nextState);
			}
		});
	}

	/**
	 * Switch classes that trigger in- and out-animations for the loading screen
	 * regarding the given state
	 * @param state True: Loading screen will be animated in; Vice versa.
	 * @private
	 */
	private triggerAnimation(state: boolean) {
		const { nativeElement: loadingScreen } = this.loadingScreenContainer;
		switch (state) {
			case true:
				loadingScreen.classList.remove('d-none');
				// we need to wait (a cycle) for the d-none removal to take effect before
				// triggering the transition animation, else the animation will be cancelled
				setTimeout(() => {
					loadingScreen.classList.add('active');
				}, 1);
				break;
			case false:
				loadingScreen.classList.remove('active');
				// we need to wait 500ms for the transition animation to finish
				// before hiding the loading screen
				setTimeout(() => {
					loadingScreen.classList.add('d-none');
				}, 500);
				break;
		}
	}
}
