import { Component } from '@angular/core';
import { Toast, ToastService } from '@app/services/common/toast.service';

@Component({
	selector: 'app-toast',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.scss']
})
export class ToastComponent {
	constructor(public toastService: ToastService) {}

	close(toast: Toast) {
		this.toastService.remove(toast);
	}
}
