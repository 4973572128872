import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from './base.api.service';
import { BehaviorSubject } from 'rxjs';

export class FooterResponseHeaders {
	backendVersion: string;
	lastMDImport: string;
	tokenEndsAt: string;
	sFTPImportActive: boolean;
	sFTPExportActive: boolean;
}
@Injectable({
	providedIn: 'root'
})
export class HttpResponseApiService extends BaseApiService {
	constructor(http: HttpClient) {
		super(http);
	}

	responseHeaders: FooterResponseHeaders = {
		backendVersion: '-',
		lastMDImport: '-',
		tokenEndsAt: '-',
		sFTPImportActive: false,
		sFTPExportActive: false
	};
	responseHeadersSubject = new BehaviorSubject(this.responseHeaders);

	setResponseHeaders(response: any) {
		if (response.headers) {
			this.responseHeaders = {
				backendVersion: response.headers.get('x-backend-version'),
				lastMDImport: response.headers.get('x-backend-last-md-import'),
				tokenEndsAt: response.headers.get('x-backend-token-ends-at'),
				sFTPImportActive: response.headers.get('x-backend-import-sftp'),
				sFTPExportActive: response.headers.get('x-backend-export-sftp')
			};
			this.responseHeadersSubject.next(this.responseHeaders);
		}
	}
}
