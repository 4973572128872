import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PDFDocumentProxy, PDFProgressData, PDFSource } from 'ng2-pdf-viewer';
import { HttpClient } from '@angular/common/http';
import { map, take, takeUntil } from 'rxjs/operators';
import { DestroyService } from '@app/services/common/destroy.service';
import { PDFService } from '../../../app/smart-invoice/fabric/services/p-d-f.service';
import { saveAs } from 'file-saver';
import {
	PDFDocument,
	PDFName,
	PDFDict,
	PDFArray,
	PDFHexString,
	PDFString,
	PDFStream,
	decodePDFRawStream,
	PDFRawStream
} from 'pdf-lib';

@Component({
	selector: 'app-pdf-viewer',
	templateUrl: './pdf-viewer.component.html',
	styleUrls: ['./pdf-viewer.component.scss'],
	providers: [DestroyService]
})
export class PdfViewerComponent implements OnChanges {
	@Input() pdfURL: string;
	@Input() canUseImgViewer: boolean;

	progressData: PDFProgressData | undefined;
	isLoaded: boolean = false;
	error: any;
	errorSrc = '/assets/img/no-image.png';

	pdfSrc: string | PDFSource | any = '';

	private pdfBlob: Blob;

	private pdfAttachments: { data: Uint8Array; name: any }[];

	@Output()
	embeddedPdfAttachments: EventEmitter<typeof this.pdfAttachments> = new EventEmitter<typeof this.pdfAttachments>();

	@Input()
	zoom: number = 0.95;
	zoomScale: 'page-height' | 'page-fit' | 'page-width' = 'page-width';
	pdf: any;
	showAll: boolean = true;

	private _page: number = 1;
	private _rotate: number = 0;

	get page(): number {
		return this._page;
	}

	set page(value: number) {
		if (value) {
			this._page = value;
		} else {
			this._page = 0;
		}
	}

	get rotate(): number {
		return this._rotate;
	}

	set rotate(value: number) {
		if (value) {
			this._rotate = value;
		} else {
			this._rotate = 0;
		}
	}

	rotateRight(value: number) {
		this._rotate -= value;
	}

	rotateLeft(value: number) {
		this._rotate += value;
	}

	incrementPage(amount: number) {
		this._page += amount;
	}

	incrementZoom(amount: number) {
		this.zoom += amount;
	}

	constructor(
		private http: HttpClient,
		private destroy$: DestroyService,
		private pdfService: PDFService,
		private changeDetectorRef: ChangeDetectorRef
	) {}

	loadPdfData(url: string) {
		this.http
			.get(url, { responseType: 'blob' })
			.pipe(
				map((res: any) => {
					return new Blob([res], { type: 'application/pdf' });
				}),
				takeUntil(this.destroy$),
				take(1)
			)
			.subscribe({
				next: (res) => {
					this.pdfBlob = res;
					this.pdfSrc = URL.createObjectURL(res);
					this.changeDetectorRef.detectChanges();
					this.getAllPdfAttachments();
				},
				error: (error) => {
					this.error = error;
				}
			});
	}

	afterLoadComplete(pdf: PDFDocumentProxy) {
		this.pdf = pdf;
	}

	onProgress(progressData: PDFProgressData) {
		this.progressData = progressData;

		this.isLoaded = progressData.loaded >= progressData.total;
		this.error = null;
	}

	getInt(value: number): number {
		return Math.round(value);
	}

	reload(): void {
		this.pdfService.toggleUseImgViewerSetting();
	}

	download(): void {
		saveAs(this.pdfSrc, 'output.pdf');
	}

	print(): void {
		const iframe = document.createElement('iframe');
		iframe.style.visibility = 'hidden';
		iframe.src = this.pdfSrc;
		document.body.appendChild(iframe);

		const endPrint = () => {
			iframe.remove();
			window.removeEventListener('focus', endPrint);
		};

		window.addEventListener('focus', endPrint);

		iframe.contentWindow?.focus();
		iframe.contentWindow?.print();
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.pdfSrc = null;
		this.error = false;
		this.loadPdfData(this.pdfURL);
	}

	private extractRawAttachments = (pdfDoc: PDFDocument) => {
		if (!pdfDoc.catalog.has(PDFName.of('Names'))) return [];
		const Names = pdfDoc.catalog.lookup(PDFName.of('Names'), PDFDict);

		if (!Names.has(PDFName.of('EmbeddedFiles'))) return [];
		const EmbeddedFiles = Names.lookup(PDFName.of('EmbeddedFiles'), PDFDict);

		if (!EmbeddedFiles.has(PDFName.of('Names'))) return [];
		const EFNames = EmbeddedFiles.lookup(PDFName.of('Names'), PDFArray);

		const rawAttachments = [];
		for (let idx = 0, len = EFNames.size(); idx < len; idx += 2) {
			const fileName = EFNames.lookup(idx) as PDFHexString | PDFString;
			const fileSpec = EFNames.lookup(idx + 1, PDFDict);
			rawAttachments.push({ fileName, fileSpec });
		}

		return rawAttachments;
	};

	private extractAttachments = (pdfDoc: PDFDocument) => {
		const rawAttachments = this.extractRawAttachments(pdfDoc);
		return rawAttachments.map(({ fileName, fileSpec }) => {
			const stream = fileSpec
				.lookup(PDFName.of('EF'), PDFDict)
				.lookup(PDFName.of('F'), PDFStream) as PDFRawStream;
			return {
				name: fileName.decodeText(),
				data: decodePDFRawStream(stream).decode()
			};
		});
	};

	private getAllPdfAttachments = async () => {
		const pdfWithAttachments = await this.pdfBlob.arrayBuffer();

		const pdfDoc = await PDFDocument.load(pdfWithAttachments);

		this.pdfAttachments = this.extractAttachments(pdfDoc);

		this.embeddedPdfAttachments.emit(this.pdfAttachments);
	};
}
