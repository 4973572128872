import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-navigation-menu-button',
	templateUrl: './navigation-menu-button.component.html',
	styleUrls: ['./navigation-menu-button.component.scss']
})
export class NavigationMenuButtonComponent implements OnInit {
	@Input() text: string = '';
	@Input() href: string | undefined;
	@Input() iconClasses: string = '';
	@Input() activeFunction: any;

	constructor(private router: Router) {}

	ngOnInit(): void {}

	getClassActiveFunction() {
		let isActive = false;
		try {
			if (this.href) {
				const splitUrl = this.router.url.split('/');
				const url = splitUrl[1] + splitUrl[2];

				const splitHref = this.href.split('/');
				const href = splitHref[1] + splitHref[2];

				isActive = url === href;

				if (!isActive && this.activeFunction) {
					isActive = this.activeFunction(this.router.url);
				}
			}
		} catch (e) {
			console.error(`can't split url to get active class`);
		}
		return { active: isActive };
	}
}
