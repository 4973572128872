<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ label }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-table
      [columns]="gridColumns"
      [dataSource]="dataSource"
      [(selectedRowKeys)]="selectedRowKeys"
      (selectedRowsChanged)="selectedRowsChanged($event)"
    >
    </app-table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="save()">Save</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Cancel click')">Cancel</button>
  </div>
</ng-template>

<div class="d-flex">
  <app-input
    [label]="label"
    [(value)]="displayValue"
    [placeholder]="placeholder"
    [autocomplete]="true"
    [hasErrors]="hasErrors"
  >
  </app-input>
  <div class="btn-search">
    <button ngbButton (click)="openDialog(content)"><i [class]="icon" aria-hidden="true"></i> {{ btnLabel }}</button>
  </div>
</div>
