import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BaseApiService } from '../../../../core/api/base.api.service';
import { ToastService } from '@app/services/common/toast.service';

@Injectable()
export class PDFService extends BaseApiService {
	public findTextListener = new EventEmitter<any>();
	public findTextClearListener = new EventEmitter<void>();
	//editCaseDataPropertyEvent = new EventEmitter();

	constructor(http: HttpClient, private router: Router, private toastService: ToastService) {
		super(http);
	}

	/*public editCaseDataProperty(caseDataID: number, property: any, newVal: any): void {
    this.http
      .patch(`${this.baseUrl}/case-data/${caseDataID}`, {
        property,
        value: newVal,
      })
      .subscribe((x) => {
        this.editCaseDataPropertyEvent.emit(x);
      });
  }*/

	public quick(
		address: 'quick-aggs' | 'quick-count',
		caseDataID: number,
		needle: string,
		field: any
	): Observable<any> {
		return this.http.post(`${this.baseUrl}/compact-case/${caseDataID}/${address}`, { needle, field });
	}

	toggleUseImgViewerSetting(): void {
		this.toastService
			.toastify(this.http.post(`${this.baseUrl}/user/toggle-use-img-viewer`, null), {
				success: 'CRUD.MSG.TOGGLE_USER_POS_TABLE_SETTING.SUCCESS',
				error: 'CRUD.MSG.TOGGLE_USER_POS_TABLE_SETTING.ERROR'
			})
			.subscribe(() => {
				this.toastService.caseDataUpdated.emit(null);
			});
	}
}
