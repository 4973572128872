import { Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import DataSource, { Options } from 'devextreme/data/data_source';
import { LoadOptions, SortDescriptor } from 'devextreme/data';
import { GridFilter } from './grid-filter';

export interface GridParams {
	page?: number;
	size?: number;
	sort?: string;
	[key: string]: string | number | boolean | { [key: string]: string } | undefined;
}

export interface IGridLoadResponse<T> {
	totalResults?: number;
	items?: T[];
	totalCount?: number;
}

export interface CustomGridParams {
	name: string;
	value: string;
}

export type GridLoadFn<TData> = (params: GridParams) => Observable<IGridLoadResponse<TData>>;

export type RowDeleteFn = (key: number | string) => Promise<void>;

export type RowUpdateFn = (key: number | string, values: any) => Promise<void>;

export type RowInsertFn = (values: any) => Promise<void>;

export type LoadByKeyFn<TData> = (key: any) => Observable<TData>;

export class GridDataSource<TData> {
	source?: DataSource;

	error$ = new Subject();
	customFilters: CustomGridParams[];
	customSort: SortDescriptor<any>;

	totalCount(): string {
		return this.source?.totalCount() == null || this.source?.totalCount() < 0
			? ''
			: this.source?.totalCount().toString();
	}

	constructor(
		public loadFn: GridLoadFn<TData>,
		public rowDeleteFn?: RowDeleteFn,
		public keyFieldName: string = 'id'
	) {
		this.initDataSource();
	}

	removeDataSource() {
		this.source = undefined;
	}

	initDataSource() {
		this.source = new DataSource(<Options>{
			key: this.keyFieldName,
			load: (loadOptions: LoadOptions<any>) => {
				const params = {
					page: (
						(loadOptions.skip === undefined ? 0 : loadOptions.skip) /
						(loadOptions.take === undefined ? 25 : loadOptions.take)
					).toString(),
					size: (loadOptions.take === undefined ? 25 : loadOptions.take).toString()
				} as unknown as GridParams;

				if (loadOptions.filter) {
					const filter = new GridFilter(loadOptions.filter);
					if (filter.hasFilters) {
						filter.filters.forEach((f) => (params[f.name] = f.value.toString()));
					}
				}

				if (this.customFilters) {
					this.customFilters.forEach((f) => (params[f.name] = f.value.toString()));
				}

				let sort: any = loadOptions.sort;
				if (loadOptions.sort) {
					sort = loadOptions.sort;
				} else if (this.customSort) {
					sort = this.customSort;
				}

				if (sort) {
					params.sort = sort[0].selector + ',' + (sort[0].desc ? 'desc' : 'asc');
				}

				this.error$.next('No data');

				return this.loadFn(params)
					.pipe(
						map((res) => {
							return {
								totalCount: !!res.totalCount ? res.totalCount : 0,
								data: res.items
							};
						}),
						catchError((err) => {
							console.log(err);
							this.error$.next('Something went wrong');

							return of({
								totalCount: 0,
								data: []
							});
						})
					)
					.toPromise();
			},
			remove: (key: number) => this.rowDeleteFn!(key)
		});
	}

	refresh() {
		this.source!.reload();
	}
}
