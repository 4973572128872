import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FileDto } from '@app/models/dto/common/file.dto';
import { AuthService } from '@app/services/common/auth.service';
import { FileDataService } from '@app/services/data/file.service';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { catchError, of, Subscription, take, takeUntil } from 'rxjs';
import { DestroyService } from '@app/services/common/destroy.service';
import { ExternalReleaseService } from './external-release.service';
import { environment } from 'src/environments/environment';
import { UnionInvoiceNoteVM } from '../smart-invoice/case/models/case.model';
import { FabricTargetFieldService } from '../smart-invoice/fabric/services/fabric-target-field.service';
import { PDFService } from '../smart-invoice/fabric/services/p-d-f.service';
import { DOCUMENT } from '@angular/common';
import { ToastService } from '@app/services/common/toast.service';

export interface ExternalReleaseVMField {
	title: string;
	value: string;
}

export interface ExternalReleaseVM {
	error: boolean;
	errorMsg: string;
	appTitle: string;
	caseTitle: string;
	accompanyingNote: boolean;
	noteVM: UnionInvoiceNoteVM;
	fields: ExternalReleaseVMField[];
}

@Component({
	selector: 'app-external-release',
	templateUrl: './external-release.component.html',
	styleUrls: ['./external-release.component.scss'],
	providers: [DestroyService, ExternalReleaseService, FabricTargetFieldService, PDFService]
})
export class ExternalReleaseComponent implements OnInit, OnDestroy {
	environment = environment;
	releaseData: ExternalReleaseVM;
	submitting = false;
	successMsg: any;
	token: string | null;
	comment: string;
	errorMessage: string | null;
	files: FileDto[] = [];
	errFiles: FileDto[] = [];
	uploading: boolean;
	caseDateUpdated$: Subscription;
	get tenant() {
		return this.authService.tenant.toLowerCase();
	}
	constructor(
		private fileService: FileDataService,
		private activatedRoute: ActivatedRoute,
		private destroy$: DestroyService,
		private dataService: ExternalReleaseService,
		private authService: AuthService,
		private toastService: ToastService,
		@Inject(DOCUMENT) private document: Document,
		private renderer: Renderer2
	) {}

	ngOnInit(): void {
		this.caseDateUpdated$ = this.toastService.caseDataUpdated.subscribe((response) => {
			if (response) {
				setTimeout(() => {
					this.releaseData.noteVM.note = response.note;
					this.releaseData.noteVM.fields = response.fields;
				});
			}
		});

		this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
			this.token = params.get('token');
			this.dataService
				.getExternalReleaseData(this.token)
				.pipe(take(1))
				.pipe(takeUntil(this.destroy$))
				.subscribe((data) => {
					this.releaseData = data;
				});
		});
		this.renderer.addClass(this.document.body, 'wrap-overflow');
	}

	ngOnDestroy(): void {
		this.renderer.removeClass(this.document.body, 'wrap-overflow');
		if (this.caseDateUpdated$) {
			this.caseDateUpdated$.unsubscribe();
		}
	}

	accept() {
		this.submitting = true;
		this.dataService.acceptExternalLink(this.token, this.comment, this.files).subscribe(
			(msg) => {
				this.submitting = false;
				this.successMsg = msg;
			},
			(error) => {
				this.submitting = false;
			}
		);
	}

	decline() {
		this.submitting = true;
		this.dataService.denyExternalLink(this.token, this.comment, this.files).subscribe(
			(msg) => {
				this.submitting = false;
				this.successMsg = msg;
			},
			(error) => {
				this.submitting = false;
			}
		);
	}

	upLoadFile(files: NgxFileDropEntry[]) {
		this.uploading = true;
		for (let file of files) {
			if (file.fileEntry.isFile) {
				const fileEntry = file.fileEntry as FileSystemFileEntry;
				fileEntry.file((binaryFile: File) => {
					this.fileService.uploadFromExternalApproval(binaryFile, this.token).subscribe(
						(x) => {
							if (x.result) {
								this.files.push(x);
								this.uploading = false;
							}
						},
						catchError((err) => {
							this.errFiles.push(err);
							return of();
						})
					);
				});
			}
		}
	}

	clear() {
		this.files = [];
		this.errFiles = [];
	}

	removeFile(index: number) {
		this.files.splice(index, 1);
	}

	print() {
		window.print();
	}
}
