import { Component, EventEmitter, HostListener, Input, OnDestroy, Output, TemplateRef } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BehaviorSubject, firstValueFrom, Observable, Subject } from 'rxjs';
import { SizesType } from '@app/models/constants/sizes.type';
import { SignalColor } from '@app/models/constants/color';

export type ModalEmission = { skipDialog?: boolean };

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
	animations: [
		trigger('modalInOut', [
			state(
				'void',
				style({
					opacity: 0,
					transform: 'translateY(-40%) translateX(-50%)'
				})
			),
			state(
				'*',
				style({
					opacity: 1,
					transform: 'translateY(-50%) translateX(-50%)'
				})
			),
			transition(':enter', [
				animate('400ms 200ms cubic-bezier(0.34, 1.56, 0.64, 1)') // Animation duration and easing
			]),
			transition(':leave', [
				animate('400ms cubic-bezier(0.36, 0, 0.66, -0.56)') // Animation duration and easing
			])
		]),
		trigger('modalBgInOut', [
			state(
				'void',
				style({
					opacity: 0,
					backdropFilter: 'blur(0)'
				})
			),
			state(
				'*',
				style({
					opacity: 1,
					backdropFilter: 'blur(3px)'
				})
			),
			transition('void => *', [
				animate('400ms ease-out') // Animation duration and easing
			]),
			transition('* => void', [
				animate('400ms 400ms ease-out') // Animation duration and easing
			])
		])
	]
})
export class ModalComponent implements OnDestroy {
	@Input() title: string = '';
	@Input() size: SizesType = 'regular';

	@Input() modalBodyTemplate?: TemplateRef<any>;
	@Input() textContent?: string;

	@Input() overflow: 'hidden' | 'visible' = 'visible';

	@Input() cancelable: boolean = true;
	@Input() submittable: boolean = true;
	@Input() skippable: boolean = false;

	@Output() cancelEvent = new EventEmitter<ModalEmission>();
	@Output() submitEvent = new EventEmitter<ModalEmission>();
	@Output() actionEvent = new EventEmitter();

	private modalClosed$ = new Subject<void>();

	public modalOpen: boolean = true;
	public skipDialog: boolean = false;

	public canSubmit$: Observable<boolean> = new BehaviorSubject(true);
	public canAction$: Observable<boolean> = new BehaviorSubject(true);

	@Input() public submitLabel?: string;
	@Input() public cancelLabel?: string;
	@Input() public actionLabel?: string;

	@Input() public submitColor?: SignalColor;
	@Input() public cancelColor?: SignalColor;
	@Input() public actionColor?: SignalColor;

	cancel(): void {
		this.cancelEvent.emit(this.skippable ? { skipDialog: this.skipDialog } : {});
	}

	submit(): void {
		this.submitEvent.emit(this.skippable ? { skipDialog: this.skipDialog } : {});
	}

	handleAction(): void {
		this.actionEvent.emit();
	}

	close(): Observable<void> {
		this.modalOpen = false;
		return this.modalClosed$.asObservable();
	}

	animationDone(event: any) {
		if (event.toState === 'void' && !this.modalOpen) {
			// Animation finished and the component is no longer visible, destroy it
			this.modalClosed$.next();
			this.modalClosed$.complete();
		}
	}

	@HostListener('document:keydown', ['$event'])
	async handleDocumentKeyPress(event: KeyboardEvent) {
		switch (event.key) {
			case 'Escape' || 'Esc':
				this.cancelable && this.cancel();
				break;
			case 'Enter':
				const canSubmit = await firstValueFrom(this.canSubmit$);
				this.submittable && canSubmit && this.submit();
				break;
		}
	}

	ngOnDestroy() {
		this.cancel();
		this.close();
	}
}
