<div class="wrap">
	<router-outlet></router-outlet>
	<app-toast></app-toast>
</div>

<app-modal-outlet></app-modal-outlet>

<ng-template #hotjarConsent>
	<div class="d-flex flex-column gap-3">
		<p class="text-paragraph">
			{{ 'general.cookieConsent.partOne' | translate }}
		</p>
		<p class="text-paragraph">
			{{ 'general.cookieConsent.partTwo' | translate }}
		</p>
		<p class="text-paragraph">
			{{ 'general.cookieConsent.partThree' | translate }}
		</p>
	</div>
</ng-template>
