import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Inject,
	Input,
	NgZone,
	OnInit,
	Optional,
	Self
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Router } from '@angular/router';
import { GridDataSource } from '@app/models/index';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CaseApiService } from 'src/core/api/case.api.service';
import { BaseFormControl } from '../base-form-control/base-form-control';
import { UnionInvoiceNoteApiService } from '@app/shared/components/union-invoice-note/union-invoice-note.api.service';

@Component({
	selector: 'app-lookup',
	templateUrl: './lookup.component.html',
	styleUrls: ['./lookup.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LookupComponent extends BaseFormControl<any, HTMLInputElement> implements OnInit {
	@Input() valueField: string = 'id';
	@Input() displayFormat: string;
	@Input() placeholder: string;
	@Input() gridColumns: any[] = [];
	@Input() dataSource?: GridDataSource<any>;
	@Input() selectedRowKeys: number[];
	@Input() btnLabel: string;
	@Input() icon: string = 'fa fa-fw fa-search';
	displayValue: string;
	selectedItem: any;
	private modalRef: NgbModalRef;
	constructor(
		@Inject(NgControl) @Self() @Optional() public override ngControl: NgControl,
		protected override cdr: ChangeDetectorRef,
		protected override zone: NgZone,
		protected override caseService: CaseApiService,
		protected override unionInvoiceNoteApiService: UnionInvoiceNoteApiService,
		protected override router: Router,
		private modalService: NgbModal
	) {
		super(ngControl, cdr, caseService, unionInvoiceNoteApiService, router, zone);
	}
	override ngOnInit(): void {
		super.ngOnInit();
		this.initValue();
	}
	openDialog(content: any) {
		this.modalRef = this.modalService.open(content, { size: 'xl' });
	}

	save() {
		this.displayValue = this.selectedItem[this.displayFormat || this.valueField];
		this.modalRef.close();
	}
	selectedRowsChanged(event: any) {
		this.selectedItem = event.selectedRowsData[0];
	}
	initValue() {
		if (this.value) {
			this.displayValue = this.value[this.displayFormat];
			this.selectedRowKeys = [this.value[this.valueField]];
		}
	}
}
