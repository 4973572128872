import {
	Component,
	ContentChildren,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	QueryList,
	SimpleChanges,
	TemplateRef,
	ViewChild
} from '@angular/core';

import * as moment from 'moment';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { GridDataSource } from '@app/models/index';
import { QueryParams, TableService } from './table.service';
import { EuconStorageService } from '@app/services/common/eucon-storage.service';
import { EuconTemplateDirective } from '@app/shared/directives/eucon-template.directive';
import { DestroyService } from '@app/services/common/destroy.service';
import { takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { ToastService } from '@app/services/common/toast.service';
import { DxDateBoxComponent } from 'devextreme-angular/ui/date-box';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss'],
	providers: [DestroyService, TableService]
})
export class TableComponent implements OnChanges, OnInit {
	@ViewChild(DxDataGridComponent, { static: false }) dataGrid?: DxDataGridComponent;
	@ContentChildren(EuconTemplateDirective) customTemplates: QueryList<EuconTemplateDirective>;
	private _selectedRowKeys: number[] = [];
	@Input() get selectedRowKeys(): number[] {
		return this._selectedRowKeys;
	}

	set selectedRowKeys(val) {
		this._selectedRowKeys = val;
		this.selectedRowKeysChange.emit(this._selectedRowKeys);
	}

	@Input() columns: any[] = [];
	@Input() dataSource?: GridDataSource<any>;
	@Input() useQueryParams = false;
	@Input() storageKey: string;
	@Input() title: string;
	@Input() detailFields: string[] = [];
	@Input() sortingMode: string = 'none';
	@Output() selectedRowsChanged: EventEmitter<any> = new EventEmitter();
	@Output() selectedRowKeysChange: EventEmitter<number[]> = new EventEmitter();
	@Output() rowClickEvent: EventEmitter<any> = new EventEmitter();
	@Output() rowPreparedEvent: EventEmitter<any> = new EventEmitter<any>();

	@Input() allowSelection: boolean = false;

	pageIndex: number = 0;
	pageSize: number = 15;
	filters: FormGroup;
	private selectedRowsData: [] = [];

	loadState = () => {};

	saveState = (state: any) => {
		if (this.storageKey && state) {
			state.columns.forEach((col: any) => {
				col.filterValue = null;
				col.sortOrder = null;
			});
			state.filterValue = null;
			delete state.pageIndex;
			this.euconStorageService.setData(this.storageKey, state);
		}
	};

	constructor(
		private tableService: TableService,
		private euconStorageService: EuconStorageService,
		private router: Router,
		private destroy$: DestroyService,
		private toastService: ToastService
	) {}

	ngOnInit(): void {
		this.initFilterFormGroup();
		this.loadColumnsState();
	}

	fireKeyUpEnter(element: any) {
		const ke = new KeyboardEvent('keyup', {
			bubbles: true,
			cancelable: true,
			keyCode: 13
		});
		element.element.nativeElement.dispatchEvent(ke);
	}

	ngOnChanges(changes: SimpleChanges) {
		for (const propName in changes) {
			if (propName === 'dataSource' && changes['dataSource'].currentValue) {
				this.loadParams();
			}
		}
	}

	loadParams() {
		if (this.useQueryParams) {
			this.tableService
				.getQueryParams(this.columns)
				.pipe(takeUntil(this.destroy$))
				.subscribe((qp: QueryParams) => {
					this.pageSize = qp.pageSize;
					this.pageIndex = qp.pageIndex;
					this.columns = qp.columns;
					this.updateFilters();
					this.dataSource!.customFilters = qp.customFilters;
					this.dataGrid?.instance.refresh();
				});
		}
	}

	onOptionChanged(e: any) {
		if (!this.useQueryParams) {
			return;
		}

		if (e.name !== 'paging' && e.name !== 'columns') return;
		if (e.name === 'columns' && !e.fullName.endsWith('sortOrder') && !e.fullName.endsWith('filterValue')) return;

		const state = this.dataGrid?.instance.state();
		this.applyChangesToState(e, state);
		this.tableService.setQueryParams(state, this.dataSource?.customFilters);
	}

	onSortClick(name: string) {
		const options = this.dataGrid?.instance.columnOption(name);
		if (options.allowSorting === false) {
			return;
		}

		const prevOrder = options.sortOrder;

		// if no sorting is present prefer asc
		const newOrder = !prevOrder || prevOrder === 'desc' ? 'asc' : 'desc';
		this.dataGrid?.instance.clearSorting();
		this.dataGrid?.instance.columnOption(name, 'sortOrder', newOrder);
	}

	onFilterEnter(colName: string, e: any, gridSucks: boolean = false) {
		if (gridSucks == true) {
			this.dataGrid?.instance.columnOption(colName, 'filterValue', e.code === '' ? '' : e.code);
		} else if (e.target.checkValidity()) {
			this.dataGrid?.instance.columnOption(colName, 'filterValue', e.target.value === '' ? '' : e.target.value);
		} else {
			this.toastService.error('Invalid value', `${colName} value is incorrect`);
		}
	}

	onFilterRangeEnter(colName: string, from: any, to: any, type: string = 'text') {
		let newValue: string[] | undefined = undefined;
		if (type === 'date') {
			let fromValue = '';
			let toValue = '';
			if (from.value) {
				if (from instanceof DxDateBoxComponent) {
					fromValue = from.text;
				} else {
					fromValue = from.value;
				}
			}
			if (to.value) {
				if (to instanceof DxDateBoxComponent) {
					toValue = to.text;
				} else {
					toValue = to.value;
				}
			}
			newValue = [fromValue, toValue];
		} else if (from.value || to.value) {
			newValue = [from.value, to.value];

			newValue = newValue.map((x) => (x === null || x === 'undefined' || x === undefined ? '' : x));
		}
		this.dataGrid?.instance.columnOption(colName, 'filterValue', newValue);
	}

	onFilterRangeCustomEnter(data: any, from: any, to: any) {
		let params: any = {};
		let dateFrom = data.column.entries[0];
		let dateTo = data.column.entries[1];
		if (from.value) {
			if (typeof from.value !== 'string') {
				params[dateFrom] = from.text;
			} else {
				params[dateFrom] = from.value;
			}
		}
		if (to.value) {
			if (typeof to.value !== 'string') {
				params[dateTo] = to.text;
			} else {
				params[dateTo] = to.value;
			}
		}
		this.router.navigate([], { queryParams: params });
	}

	onSelectionChanging(event: any) {
		this.selectedRowsData = event.selectedRowsData;
		this.selectedRowsChanged.emit(event);
	}

	onRowClick(event: any) {
		this.rowClickEvent.emit(event.data);
	}

	findOption(colName: string, row: any) {
		const options = this.dataGrid?.instance.columnOption(colName);
		const lookup = options.lookup;
		const item = lookup.dataSource.find((i: any) => i[lookup.valueExpr] === row.data[options.dataField]);
		return item && item[lookup.displayExpr];
	}

	getCustomTemplate(name: string): TemplateRef<any> | null {
		if (this.customTemplates && this.customTemplates.length) {
			const template = this.customTemplates.find((t) => t.name === name);
			if (!template) {
				throw new Error('Custom template is not provided');
			}
			return template.templateRef;
		}

		return null;
	}

	getRangeFilterValue(value: string | string[], part: 'From' | 'To'): string | undefined {
		if (!value) return '';

		if (Array.isArray(value)) {
			return part === 'From' ? value[0] : value[1];
		}

		return part === 'From' ? value : undefined;
	}

	getRangeFilterCustomValue(value: string): string | undefined {
		if (!value) return '';
		return value;
	}

	refresh() {
		this.dataGrid?.instance.refresh();
	}

	columnsClick() {
		this.dataGrid?.instance.showColumnChooser();
	}

	private applyChangesToState(changes: any, state: any) {
		const columns: { dataField: string; sortOrder: string; filterValue: any }[] = state.columns;
		if (changes.fullName === 'paging.pageIndex') {
			state.pageIndex = changes.value;
		} else if (changes.fullName === 'paging.pageSize') {
			state.pageSize = changes.value;
		} else if (changes.name === 'columns') {
			const colIndex = +changes.fullName.slice(8, changes.fullName.indexOf(']'));
			if (changes.fullName.endsWith('sortOrder')) {
				columns[colIndex].sortOrder = changes.value;
			} else if (changes.fullName.endsWith('filterValue')) {
				columns[colIndex].filterValue = changes.value;
			}
		}
	}

	openWindow(link: string, param: string | null = null): void {
		console.log(arguments);
		if (param) {
			window.open(`${link}/${param}`);
		} else {
			window.open(`${link}`);
		}
	}

	log(...data: any) {
		console.log(data);
	}

	getDate(rangeFilterValue: string | undefined) {
		if (rangeFilterValue) {
			return moment(rangeFilterValue, 'DD-MM-YYYY HH:mm').toDate();
		} else return null;
	}

	private updateFilters() {
		if (!this.filters) {
			return;
		}

		let group: any = {};
		this.columns
			.filter((c) => c.dataField && c.entries?.length === 1)
			.forEach((c) => {
				group[c.dataField] = this.getColumnFilterValue(c);
			});
		this.filters.patchValue(group);
	}

	private getColumnFilterValue(column: any): any {
		if (column.selectedFilterOperation === 'between') {
			if (column.type?.startsWith('date')) {
				return [this.getDate(column.filterValue?.at(0)), this.getDate(column.filterValue?.at(1))];
			} else {
				return [column.filterValue?.at(0), column.filterValue?.at(1)];
			}
		} else {
			if (column.type?.startsWith('date')) {
				return this.getDate(column.filterValue);
			}
			return column.filterValue;
		}
	}

	private initFilterFormGroup() {
		let group: any = {};
		this.columns
			.filter((c) => c.dataField)
			.forEach((c) => {
				const filterValue = this.getColumnFilterValue(c);
				group[c.dataField] = Array.isArray(filterValue)
					? new FormArray(filterValue.map((v) => new FormControl(v)))
					: new FormControl(filterValue);
			});
		this.filters = new FormGroup(group);
	}

	private loadColumnsState() {
		if (!this.storageKey) {
			return;
		}

		const data = this.euconStorageService.getData(this.storageKey);

		if (data) {
			data.columns.forEach((colStore: any) => {
				const column = this.columns.find((c: any) => c.name === colStore.name);
				column.visibleIndex = colStore.visibleIndex;
				column.visible = colStore.visible;
				column.width = colStore.width;
			});
		}
	}
}
