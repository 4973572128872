import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
	selector: 'textarea[appTextareaAutoresize]'
})
export class TextareaAutoresizeDirective implements AfterViewInit {
	@Input() appTextareaAutoresize: string;

	constructor(private elementRef: ElementRef<HTMLTextAreaElement>) {}

	@HostListener('input')
	onInput() {
		this.resize(false);
	}

	ngAfterViewInit() {
		// dirty solution due to too slow scrollHeight calculation
		// it is always 0 here despite using it inside afterViewInit
		setTimeout(() => {
			if (this.elementRef.nativeElement.scrollHeight) {
				setTimeout(() => this.resize());
			}
		}, 200);
	}

	resize(animateIn: boolean = false) {
		// instant
		this.elementRef.nativeElement.style.height = '0';
		this.elementRef.nativeElement.style.height = this.elementRef.nativeElement.scrollHeight + 2 + 'px';
	}
}
