import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthGuard } from '../core/guards/auth.guard';
import { PasswordExpiredComponent } from './login/password-expired/password-expired.component';
import { ExternalReleaseComponent } from './external-release/external-release.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: 'external-release/:token',
		component: ExternalReleaseComponent
	},
	{
		path: 'forgot-password',
		component: ForgotPasswordComponent
	},
	{
		path: 'forgot-pw/finish',
		component: ResetPasswordComponent
	},
	{ path: 'password-expired', component: PasswordExpiredComponent },
	{
		path: '',
		loadChildren: () => import('./smart-invoice/smart-invoice.module').then((m) => m.SmartInvoiceModule),
		canActivate: [AuthGuard]
	},
	{
		path: '**',
		redirectTo: ''
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
