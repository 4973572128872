/**
 * Error codes that get sent from the backend to distinguish the exact error that occurred. <br>
 * We can use the enum value to access the i18n translation keys. <br>
 * The error translations should be stored with the prefix `ERROR.`
 * following the error code from this enum.
 *
 * @example
 * // returns the translation for the given errorCode
 * await lastValueFrom(this.translateService.get(`ERROR.${errorCode}`));
 */
export enum ErrorCode {
	/** Internal server error */
	SERVER_ERROR = 'SERVER_ERROR',

	/**
	 * Exception thrown on concurrency failure.
	 * This exception should be subclassed to indicate the type of failure: optimistic locking, failure to acquire lock, etc.
	 */
	CONCURRENCY_ERROR = 'CONCURRENCY_ERROR',

	/**
	 * The request (data) is invalid. Details are specified with a list of ValidationErrorCode
	 * @see ValidationErrorCode
	 */
	VALIDATION_ERROR = 'VALIDATION_ERROR',

	/**
	 * Thrown if an Authentication object does not hold a required authority or user does not have permissions.
	 */
	ACCESS_DENIED_ERROR = 'ACCESS_DENIED_ERROR',

	/**
	 * Exception thrown when a request handler does not support a specific request method.
	 */
	HTTP_METHOD_NOT_SUPPORTED_ERROR = 'HTTP_METHOD_NOT_SUPPORTED_ERROR',

	/**
	 * User credential expired.
	 */
	CREDENTIAL_EXPIRED = 'CREDENTIAL_EXPIRED',

	/**
	 * Operation is not allowed with entity.
	 */
	NOT_ALLOWED_ERROR = 'NOT_ALLOWED_ERROR',

	/**
	 * New/Changed user password violate password rules.
	 */
	MALFORMED_PASSWORD_ERROR = 'MALFORMED_PASSWORD_ERROR',

	/**
	 * Failed to create case.
	 */
	CASE_CREATE_ERROR = 'CASE_CREATE_ERROR',

	/**
	 * Error in SAP process
	 */
	SAP_ERROR = 'SAP_ERROR',

	/**
	 * Error doing operation. Operation is only available for specific asset types.
	 */
	WRONG_ASSET_TYPE_ERROR = 'WRONG_ASSET_TYPE_ERROR',

	/**
	 * Indicates that there is a problem with eucon creditor ID provided. E.g. not existing id, same id.
	 */
	EUCON_CREDITOR_ERROR = 'EUCON_CREDITOR_ERROR',

	/**
	 * Failed to convert file.
	 */
	FILE_CONVERT_ERROR = 'FILE_CONVERT_ERROR',

	/**
	 * No description.
	 */
	DUPLICATE_REFERENCE_ERROR = 'DUPLICATE_REFERENCE_ERROR',

	/**
	 * Failed to find entity by id in DB.
	 */
	ENTITY_NOT_FOUND_ERROR = 'ENTITY_NOT_FOUND_ERROR',

	/**
	 * Error in export process.
	 */
	EXPORT_ERROR = 'EXPORT_ERROR',

	/**
	 * Error in import process.
	 */
	IMPORT_ERROR = 'IMPORT_ERROR',

	/**
	 * Error with user submitted task.
	 */
	USER_TASK_ERROR = 'USER_TASK_ERROR',

	/**
	 * Failed to parse XML.
	 */
	XML_PARSING_ERROR = 'XML_PARSING_ERROR',

	/**
	 * Error with reoccurring payment
	 */
	RECURRING_PAYMENT_ERROR = 'RECURRING_PAYMENT_ERROR',

	/**
	 * Credentials provided by the user are incorrect
	 */
	INCORRECT_CREDENTIALS_ERROR = 'INCORRECT_CREDENTIALS_ERROR',

	/**
	 * User cannot log in because he/she took too many login tries and is now locked.
	 */
	USER_LOCKED_ERROR = 'USER_LOCKED_ERROR',

	/**
	 * User cannot log in because the account was manually disabled by a user administrator.
	 */
	USER_DISABLED_ERROR = 'USER_DISABLED_ERROR',

	/**
	 * User account was locked automatically due to long inactivity.
	 */
	ACCOUNT_EXPIRED_ERROR = 'ACCOUNT_EXPIRED_ERROR'
}
