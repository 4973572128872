<div class="wrapper">
  <h2>
    <i class="{{ iconClasses }}"></i>
    {{ text }}
  </h2>
  <ol *ngIf="breadcrumbs" class="breadcrumb">
    <li *ngFor="let breadcrumb of breadcrumbs; let last = last">
      <a
        *ngIf="!last"
        routerLink="/inbox"
        routerLinkActive="active"
        [queryParams]="breadcrumb.params"
        (click)=refresh();
        >{{ breadcrumb.title }}</a
      >
      <span *ngIf="last" class="last">{{ breadcrumb.title }}</span>
    </li>
  </ol>
  <div class="content-wrapper">
    <ng-content></ng-content>
  </div>
</div>
