import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { of } from 'rxjs';

@Component({
	selector: 'app-link-navigation',
	templateUrl: './link-navigation.component.html',
	styleUrls: ['./link-navigation.component.scss']
})
export class LinkNavigationComponent implements AfterViewInit {
	@Input() text: string = '';
	@Input() href: string = '';
	@Input() params: any = {};
	@Input() checkParams?: any[];
	@Input() spanValue: number | undefined;

	constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

	ngAfterViewInit(): void {}

	isActive(): boolean {
		const queryParamsIndex = this.router.url.indexOf('?');
		const baseUrl = queryParamsIndex === -1 ? this.router.url : this.router.url.slice(0, queryParamsIndex);
		if (this.checkParams) {
			let activate = true;
			const params = this.activatedRoute.snapshot.queryParams;
			this.checkParams.forEach((x) => {
				const name = Object.keys(x)[0];
				const value = x[name];
				if (value) {
					if (!params[name]) {
						activate = false;
					}
				} else {
					if (params[name]) {
						activate = false;
					}
				}
			});
			return baseUrl === this.href && activate;
		}
		return baseUrl === this.href;
	}
}
