import { Directive, ElementRef, HostListener } from '@angular/core';
import { ToastService } from '@app/services/common/toast.service';

@Directive({
	selector: '[appCopyToClipboard]'
})
export class CopyToClipboardDirective {
	constructor(private elementRef: ElementRef, private toast: ToastService) {
		// Make the host turn the cursor to a pointer on hover to indicate clickableness ☝
		(<HTMLElement>elementRef.nativeElement).classList.add('pointer');
	}

	/**
	 * Click on the host element copies innerText contents of the host element to the clipboard
	 */
	@HostListener('click')
	public async copyToClipboard(): Promise<void> {
		const element: HTMLElement = this.elementRef.nativeElement;
		await navigator.clipboard.writeText(element.innerText);
		this.toast.success('Inhalt kopiert', 'Inhalt erfolgreich in die Zwischenablage kopiert!');
	}

	/**
	 * Old functionality found in the previous smartinvoice frontend
	 * @private
	 * @deprecated
	 */
	private oldCopyToClipboard(): void {
		const element: HTMLElement = this.elementRef.nativeElement;
		const range: Range = document.createRange();

		range.selectNode(element);

		window.getSelection()?.removeAllRanges();
		window.getSelection()?.addRange(range);

		// @deprecated use of document.execCommand
		let successful = document.execCommand('copy');

		window.getSelection()?.removeAllRanges();
	}
}
