import { Tenant } from '@app/models/constants/tenants';
import { DeploymentTier } from '@app/models/constants/deployment-tiers';
import { FeatureConfig } from '@app/models/constants/features';
import * as _ from 'lodash';
import { LANGUAGE_KEY } from '@app/models/constants';

type IEnvironment = {
	deploymentTier: DeploymentTier;
	tenant: Tenant;
	defaultLocale: LANGUAGE_KEY;
	systemVersion: string;
	systemVersionColor: string;
	systemVersionDisplay: string;
	title: string;
	backgroundImagePath: string;
	version: string;
	buildDate: string;
	baseUrl: string;
	featureConfig: Partial<FeatureConfig>;
};

/**
 * Common environment defaults. <br>
 * <strong> IMPORTANT: Import path MUST be relative from project root!</strong>
 * @example
 * import { commonEnvironment } from 'src/environments/environment.common';
 */
export const commonEnvironment: IEnvironment = {
	deploymentTier: DeploymentTier.UNDEFINED,
	tenant: Tenant.UNDEFINED,
	defaultLocale: LANGUAGE_KEY.EN,
	systemVersion: '',
	systemVersionColor: 'black',
	systemVersionDisplay: 'inline-block',
	title: 'SmartInvoice',
	backgroundImagePath: '/assets/img/bg.jpg',
	version: '1.0.2',
	buildDate: '14.11.2024 | 11:23:36',
	baseUrl: 'https://smartinvoice-dev-backend.westeurope.cloudapp.azure.com/backend/api',
	featureConfig: {}
};

/**
 * Set up an environment which replaces the common environment defaults. <br>
 * <strong> IMPORTANT: Import path MUST be relative from project root!</strong>
 * @example
 * import { tenantEnvironment } from 'src/environments/environment.common';
 */
export function tenantEnvironment(initializer: Partial<IEnvironment>): IEnvironment {
	return _.merge(commonEnvironment, initializer);
}
