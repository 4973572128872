/**
 * Describes the current state of the environment: <br>
 * <i>PRODUCTION</i>, <br>
 * <i>PRODUCTION_TEST</i>, <br>
 * <i>STAGING</i>, <br>
 * <i>DEVELOPMENT</i>, <br>
 * <i>LOCAL_DEVELOPMENT</i>, <br>
 * <i>UNDEFINED</i>.
 */
enum DeploymentTier {
	/**
	 * Connects to the production backends for each tenant. <br>
	 * Not possible for locally hosted frontends.
	 */
	PRODUCTION = 'PRODUCTION',

	/**
	 * Currently only for apleona: A copy of the production backend+database for testing double booking cases.
	 */
	PRODUCTION_TEST = 'PRODUCTION_TEST',

	/**
	 * Connects to the staging backends for each tenant. <br>
	 * Not possible for locally hosted frontends.
	 */
	STAGING = 'STAGING',

	/**
	 * Connects to the staging backends for each tenant. <br>
	 * For locally hosted frontends to connect to.
	 */
	DEVELOPMENT = 'DEVELOPMENT',

	/**
	 * Connects to the locally hosted backend.
	 */
	LOCAL_DEVELOPMENT = 'LOCAL_DEVELOPMENT',

	/**
	 * The initial commonEnvironment value
	 */
	UNDEFINED = 'UNDEFINED'
}

export { DeploymentTier };
