import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LoadingScreenService {
	/**
	 * The active state of the loading screen components. <br>
	 * Shown / Hidden
	 */
	state = new BehaviorSubject<boolean>(false);

	constructor() {}

	/**
	 * Sets a flag to animate-in the present loading screen elements <br>
	 * @note If multiple loading screen components are in the DOM, all of them will trigger.
	 */
	show() {
		if (this.state.value) return;
		this.state.next(true);
	}

	/**
	 * Sets a flag to animate-out the present loading screen elements <br>
	 * @note If multiple loading screen components are in the DOM, all of them will trigger.
	 */
	hide() {
		if (!this.state.value) return;
		this.state.next(false);
	}
}
