import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class NavigationService {
	private history: string[] = [];

	constructor(private router: Router, private location: Location) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.history.push(event.urlAfterRedirects);
			}
		});
	}

	/**
	 * Navigates to the last route. <br>
	 * <i><strong>Note:</strong> Route overwrites, e.g. the changing of query parameters count as separate routes in the navigation history.</i>
	 */
	public back(): void {
		this.history.pop();
		if (this.history.length > 0) {
			this.location.back();
		} else {
			this.router.navigateByUrl('/');
		}
	}

	/**
	 * Returns the string representation of the last route of the application <br>
	 * If there is no last route then '/' will be returned
	 * @returns {string} Route URL with query parameters in string format.
	 */
	public getLastRoute(): string {
		console.log(this.history);
		// the last element is the current route
		// if we want to get the route before the current route we need to target the second to last element
		if (this.history.length > 1) {
			return this.history[this.history.length - 2];
		} else {
			return '/';
		}
	}

	/**
	 * Returns the string representation of the last route not containing a specific string <br>
	 * If there is no last route then '/' will be returned
	 * @param exclude The string ( or array of strings) to be avoided in recent routing history
	 * @returns {string} Route URL with query parameters in string format.
	 */
	public getLastRouteNotContaining(exclude: string | string[]): string {
		const rev_history = [...this.history].reverse();
		const result = rev_history.find((route: string) => {
			// If a single match was provided
			if (typeof exclude === 'string') {
				return !route.includes(exclude);
			}
			// If an array of exclusions is provided
			return !exclude.find((exclusionItem) => route.includes(exclusionItem));
		});
		return result ?? '/';
	}

	/**
	 * Returns the string representation of the last route containing a specific string <br>
	 * If there is no last route then '/' will be returned
	 * @param match The desired string to be contained within the route of the recent routing history
	 * @returns {string} Route URL with query parameters in string format.
	 */
	public getLastRouteContaining(match: string): string {
		const rev_history = [...this.history].reverse();
		const route = rev_history.find((element: string) => element.includes(match));
		return route ?? '/';
	}
}
