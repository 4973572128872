import { Injectable } from '@angular/core';
import { FeatureConfig } from '@app/models/constants/features';
import { environment } from '../../../environments/environment';
import { get, has } from 'lodash';

@Injectable({
	providedIn: 'root'
})
export class FeatureFlagService {
	public readonly config: Partial<FeatureConfig>;
	constructor() {
		this.config = environment.featureConfig;
	}
	public isFeatureEnabled(key: keyof FeatureConfig): boolean {
		if (this.config && has(this.config, key)) {
			return !!get(this.config, key, false);
		}
		return false;
	}

	public getFeatureConfig(key: keyof FeatureConfig): FeatureConfig[typeof key] | undefined {
		if (this.config && has(this.config, key)) {
			return get(this.config, key);
		}
		return undefined;
	}
}
