import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, elementAt, map, Observable } from 'rxjs';
import { PDFService } from './p-d-f.service';
import { Aggregation } from '../models/Aggregation';
import { CasePageCompactVM } from '../../case/models/case.model';
import { filter } from 'lodash';
import { TranslatePipe } from '@ngx-translate/core';

@Injectable()
export class FabricTargetFieldService {
	fabricTargetFieldElem: any = null;

	copyEvent = new EventEmitter();
	private _applyTextFields: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
	private _filterNeedle: BehaviorSubject<string> = new BehaviorSubject<string>('');
	caseID: number | undefined;
	copyEndEvent = new EventEmitter<string>();

	fieldSelectedEvent = new EventEmitter<any>();

	expandSelectionEvent = new EventEmitter();
	shrinkSelectionEvent = new EventEmitter();
	applyAggEvent = new EventEmitter<Aggregation>();

	editCaseDataEvent = new EventEmitter<any>();
	texts: any = Object.create(null);
	pages: any = null;

	setTextFields(value: any[]) {
		this._applyTextFields.next(this.mapLabelFields(value));
	}

	get applyTextFields(): Observable<any[]> {
		return combineLatest(this._applyTextFields, this._filterNeedle).pipe(
			map(([textFields, filter]) =>
				textFields.filter((x) => x.label.toLowerCase().trim().startsWith(filter.toLocaleLowerCase(), 0) == true)
			)
		);
	}

	constructor(private crudService: PDFService, private translatePipe: TranslatePipe) {
		/*this.fieldSelectedEvent.subscribe((x) => {
	  this.copyEndEvent.pipe(first()).subscribe((y) => {
		this.crudService.editCaseDataProperty(x.parentID, x.key, y);
	  });
	  this.copy();
	});*/
	}

	copy() {
		this.copyEvent.emit();
	}

	removeTargetFieldElem(): void {
		if (this.fabricTargetFieldElem) {
			this.fabricTargetFieldElem = null;
		}
	}

	setFilter(value: string) {
		this._filterNeedle.next(value);
	}

	applyAgg(param: Aggregation): void {
		console.log('apply Agg: ', param, this.caseID);
		this.applyAggEvent.emit(param);
	}

	setPages(p: CasePageCompactVM[]) {
		this.pages = p;
		let pageNr = 1;

		if (this.pages && this.pages.length > 0) {
			for (const key in this.pages) {
				let i = 0;

				for (const pageFlow of this.pages[key].pageFlows) {
					for (const box of pageFlow.blocks) {
						for (const line of box.lines) {
							for (let j = 0; j < line.words.length; j++) {
								const wordObj = line.words[j];

								if (!wordObj || !wordObj.word) {
									continue;
								}

								const lc = wordObj.word.toLowerCase();

								if (!this.texts[lc]) {
									this.texts[lc] = [];
								}

								const entry = { pageNr, i, word: wordObj.word, expansions: [wordObj.word] };

								let k = j + 1;

								while (k < line.words.length) {
									const components = [];

									for (let m = j; m <= k; m++) {
										components.push(line.words[m].word);
									}

									k++;
									entry.expansions.push(components.join(' '));
								}

								if (this.texts[lc] && Array.isArray(this.texts[lc])) {
									this.texts[lc].push(entry);
								}
								i++;
							}
						}
					}
				}
			}
			pageNr++;
		}
	}

	unsetPages() {
		this.pages = null;
		this.texts = Object.create(null);
	}

	prefixSearch(needle: string, page: number = 1, maxResults: number = 5) {
		const lc = needle.toLowerCase();

		if (lc.length < 1) {
			return [];
		}

		let expansions: any[] = [];

		expansions = this.textOrExpansionStartsWith(lc.toLocaleLowerCase(), page);

		return expansions
			.filter((v) => v.toLowerCase() !== lc.toLocaleLowerCase())
			.slice(0, maxResults)
			.map((v) => {
				return {
					id: v,
					displayTitle: v,
					extendedTitle: v
				};
			});
	}

	textOrExpansionStartsWith(lc: string, pageNr: number) {
		let expansions: any = [];
		for (var text in this.texts) {
			for (let index = 0; index < this.texts[text].length; index++) {
				const textEntry = this.texts[text][index];

				if (
					textEntry.word.toLocaleLowerCase().startsWith(lc) &&
					textEntry.pageNr == pageNr &&
					!expansions.includes(textEntry.word)
				) {
					expansions.push(textEntry.word);
				}

				if (textEntry.expansions) {
					for (let expansionIndex = 0; expansionIndex < textEntry.expansions.length; expansionIndex++) {
						const expansionText = textEntry.expansions[expansionIndex];
						if (
							expansionText.toLocaleLowerCase().startsWith(lc) &&
							textEntry.pageNr == pageNr &&
							!expansions.includes(expansionText)
						) {
							expansions.push(expansionText);
						}
					}
				}
			}
		}
		return expansions;
	}

	mapLabelFields(value: any[]): any[] {
		for (let index = 0; index < value.length; index++) {
			const textField = value[index];

			switch (textField.key) {
				case 'clientOrgCity':
					textField.label = this.translatePipe.transform('FABRIC_TAGET_FIELDS.CLIENT_ORG.CITY');
					break;
				case 'clientOrgZip':
					textField.label = this.translatePipe.transform('FABRIC_TAGET_FIELDS.CLIENT_ORG.PLZ');
					break;
				case 'clientOrgStreet':
					textField.label = this.translatePipe.transform('FABRIC_TAGET_FIELDS.CLIENT_ORG.STREET');
					break;
				case 'clientOrgStreetNr':
					textField.label = this.translatePipe.transform('FABRIC_TAGET_FIELDS.CLIENT_ORG.STREET_NUMBER');
					break;
				case 'clientOrgName':
					textField.label = this.translatePipe.transform('FABRIC_TAGET_FIELDS.CLIENT_ORG.NAME');
					break;
				case 'recipientCity':
					textField.label = this.translatePipe.transform('FABRIC_TAGET_FIELDS.RECIPIENT.CITY');
					break;
				case 'recipientStreet':
					textField.label = this.translatePipe.transform('FABRIC_TAGET_FIELDS.RECIPIENT.STREET');
					break;
				case 'recipientStreetNr':
					textField.label = this.translatePipe.transform('FABRIC_TAGET_FIELDS.RECIPIENT.STREET_NUMBER');
					break;
				case 'recipientZip':
					textField.label = this.translatePipe.transform('FABRIC_TAGET_FIELDS.RECIPIENT.PLZ');
					break;
				case 'recipientName':
					textField.label = this.translatePipe.transform('FABRIC_TAGET_FIELDS.RECIPIENT.NAME');
					break;
				case 'locationCity':
					textField.label = this.translatePipe.transform('FABRIC_TAGET_FIELDS.LOCATION.CITY');
					break;
				case 'locationStreet':
					textField.label = this.translatePipe.transform('FABRIC_TAGET_FIELDS.LOCATION.STREET');
					break;
				case 'locationZip':
					textField.label = this.translatePipe.transform('FABRIC_TAGET_FIELDS.LOCATION.PLZ');
					break;
				case 'locationName':
					textField.label = this.translatePipe.transform('FABRIC_TAGET_FIELDS.LOCATION.NAME');
					break;
				case 'city':
					textField.label = this.translatePipe.transform('FABRIC_TAGET_FIELDS.CREDITOR.CITY');
					break;
				case 'street':
					textField.label = this.translatePipe.transform('FABRIC_TAGET_FIELDS.CREDITOR.STREET');
					break;
				case 'streetAndNumber':
					textField.label = this.translatePipe.transform('FABRIC_TAGET_FIELDS.CREDITOR.STREETANDNUMBER');
					break;
				case 'postalCode':
					textField.label = this.translatePipe.transform('FABRIC_TAGET_FIELDS.CREDITOR.PLZ');
					break;
				case 'name':
					textField.label = this.translatePipe.transform('FABRIC_TAGET_FIELDS.CREDITOR.NAME');
					break;
				case 'email':
					textField.label = this.translatePipe.transform('FABRIC_TAGET_FIELDS.CREDITOR.EMAIL');
					break;
				case 'tel':
					textField.label = this.translatePipe.transform('FABRIC_TAGET_FIELDS.CREDITOR.TEL');
					break;
				case 'fax':
					textField.label = this.translatePipe.transform('FABRIC_TAGET_FIELDS.CREDITOR.FAX');
					break;
				default:
					break;
			}
		}
		return value;
	}
}
