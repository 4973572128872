import { Directive, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlagService } from '@app/services/common/feature-flag.service';
import { FeatureConfig, FeatureValueType } from '@app/models/constants/features';

class FeatureFlagContext {
	public $implicit?: FeatureValueType;
}

@Directive({
	selector: '[featureFlag]'
})
export class FeatureFlagDirective implements OnInit, OnChanges {
	/**
	 * The feature name to check for displaying the contents of the structural element.
	 */
	@Input() featureFlag: keyof FeatureConfig;
	/**
	 * An additional statement to check, before displaying the feature element.
	 * When the if-input is not set, it defaults to true, so it does not affect the outcome.
	 */
	@Input() featureFlagIf: boolean = true;
	/**
	 * Relay the config object of the specific feature config as $implicit value to the directive output.
	 * @private
	 */
	private context = new FeatureFlagContext();

	constructor(
		private tpl: TemplateRef<FeatureFlagContext>,
		private vcr: ViewContainerRef,
		private featureFlagService: FeatureFlagService
	) {}

	// /**This is a more general approach when using a well-defined context class.
	//  * In this case the context is ambiguous with the union type FeatureValueType, which is holding every feature type.
	//  * Use this guard when using a strict context type to enable compiler checks for the templates. **/
	// static ngTemplateContextGuard(dir: FeatureFlagDirective, ctx: unknown): ctx is FeatureFlagContext {
	// 	return true;
	// }

	/**
	 * This guard ensures that the compiler becomes aware that the context of featureFlag ($implicit attribute)
	 * contains the possible types for every feature flag
	 * @param dir The directive
	 * @param ctx The context
	 */
	static ngTemplateGuard_featureFlag(dir: FeatureFlagDirective, ctx: FeatureValueType): ctx is FeatureValueType {
		return true;
	}

	/**
	 * When the additional if input changes, check if the feature can be activated / deactivated
	 * @param changes
	 */
	ngOnChanges(changes: SimpleChanges): void {
		if (changes['featureFlagIf']?.currentValue !== changes['featureFlagIf']?.previousValue) {
			this.checkFeature();
		}
	}

	/**
	 * One-time-check if the feature flag exists and the rights are set.
	 */
	ngOnInit() {
		this.checkFeature();
	}

	/**
	 * Check if the feature is listed and the requirements are met.
	 * Prevent double-adding the view in case the check gets called multiple times.
	 * Remove the view if the requirements are not met.
	 * @private
	 */
	private checkFeature(): void {
		const allowFeature = this.featureFlagService.isFeatureEnabled(this.featureFlag) && this.featureFlagIf;
		const isAlreadyDisplayed = !!this.vcr.length;
		if (allowFeature && !isAlreadyDisplayed) {
			this.context.$implicit = this.featureFlagService.getFeatureConfig(this.featureFlag);
			this.vcr.createEmbeddedView(this.tpl, this.context);
		}
		if (!allowFeature && isAlreadyDisplayed) {
			this.vcr.clear();
		}
	}
}
