<form [formGroup]="filters">
  <dx-data-grid
    [dataSource]="dataSource?.source!"
    [cacheEnabled]="false"
    [columns]="columns"
    [allowColumnResizing]="true"
    [allowColumnReordering]="true"
    [filterSyncEnabled]="true"
    [hoverStateEnabled]="true"
    [wordWrapEnabled]="true"
    [(selectedRowKeys)]="selectedRowKeys"
    (onRowClick)="onRowClick($event)"
    (onSelectionChanged)="onSelectionChanging($event)"
    (onOptionChanged)="onOptionChanged($event)"
    (onRowClick)="onRowClick($event)"
    (onRowPrepared)="rowPreparedEvent.emit($event)"
  >
    >
    <dxo-scrolling [useNative]="false" [scrollByContent]="true" [scrollByThumb]="true" showScrollbar="always">
    </dxo-scrolling>
    <dxo-selection *ngIf="allowSelection" mode="multiple" selectAllMode="page" [showCheckBoxesMode]="'always'">
    </dxo-selection>
    <dxo-state-storing
      [enabled]="true"
      [storageKey]="storageKey"
      type="custom"
      [customLoad]="loadState"
      [customSave]="saveState"
    ></dxo-state-storing>
    <dxo-sorting [mode]="sortingMode"></dxo-sorting>
    <dxo-remote-operations [groupPaging]="true"></dxo-remote-operations>
    <dxo-paging [pageSize]="pageSize" [pageIndex]="pageIndex"></dxo-paging>
    <dxo-master-detail [autoExpandAll]="true" [enabled]="false" template="detail"></dxo-master-detail>
    <dxo-pager
      [visible]="true"
      [allowedPageSizes]="[15, 30, 50, 100]"
      [displayMode]="'full'"
      [showPageSizeSelector]="true"
      [showInfo]="true"
      [showNavigationButtons]="true"
    >
    </dxo-pager>
    <dxo-filter-row [visible]="false"></dxo-filter-row>
    <div *dxTemplate="let data of 'betweenDate'">
      <!--Caption render-->
      <ng-container *ngIf="data.component.columnOption(data.column.name, 'hideCaption') !== true">
        <div
          *ngIf="data.component.columnOption(data.column.name, 'allowSorting'); else textCaption"
          class="filter-label"
          (click)="onSortClick(data.column.name)"
        >
          <i
            class="fa"
            [ngClass]="{
              'fa-chevron-down': data.component.columnOption(data.column.name, 'sortOrder') === 'desc',
              'fa-chevron-up': data.component.columnOption(data.column.name, 'sortOrder') === 'asc'
            }"
          ></i>
          <ng-container *ngTemplateOutlet="textCaption"></ng-container>
        </div>
        <ng-template #textCaption>
          <span
            title="{{
              data.component.columnOption(data.column.name, 'title')
                ? data.component.columnOption(data.column.name, 'title')
                : data.component.columnOption(data.column.name, 'caption')
            }}"
            >{{ data.component.columnOption(data.column.name, 'caption') }}</span
          >
        </ng-template>
      </ng-container>
      <!--Filters render-->
      <ng-container>
        <dx-date-box
          [height]="18"
          type="date"
          #from
          (keyup.enter)="onFilterRangeCustomEnter(data, from, to)"
          (onValueChanged)="onFilterRangeCustomEnter(data, from, to)"
          [showClearButton]="true"
          [displayFormat]="'dd.MM.yyyy'"
          [useMaskBehavior]="true"
          value="{{ getRangeFilterCustomValue(data.component.columnOption(data.column.name, 'filterValueFrom')) }}"
          placeholder="{{
            data.component.columnOption(data.column.name, 'placeholders')
              ? data.component.columnOption(data.column.name, 'placeholders')[0]
              : ''
          }}"
        ></dx-date-box>
        <dx-date-box
          [height]="18"
          type="date"
          #to
          (keyup.enter)="onFilterRangeCustomEnter(data, from, to)"
          (onValueChanged)="onFilterRangeCustomEnter(data, from, to)"
          [showClearButton]="true"
          [displayFormat]="'dd.MM.yyyy'"
          [useMaskBehavior]="true"
          value="{{ getRangeFilterCustomValue(data.component.columnOption(data.column.name, 'filterValueTo')) }}"
          placeholder="{{
            data.component.columnOption(data.column.name, 'placeholders')
              ? data.component.columnOption(data.column.name, 'placeholders')[1]
              : ''
          }}"
        ></dx-date-box>
      </ng-container>
    </div>
    <div *dxTemplate="let data of 'multipleFieldsHeaderTemplate'">
      <div *ngFor="let colName of data.column.entries">
        <!--Caption render-->
        <ng-container *ngIf="data.component.columnOption(colName, 'hideCaption') !== true">
          <div
            *ngIf="data.component.columnOption(colName, 'allowSorting'); else textCaption"
            class="filter-label"
            (click)="onSortClick(colName)"
          >
            <i
              class="fa"
              [ngClass]="{
                'fa-chevron-down': data.component.columnOption(colName, 'sortOrder') === 'desc',
                'fa-chevron-up': data.component.columnOption(colName, 'sortOrder') === 'asc'
              }"
            ></i>
            <ng-container *ngTemplateOutlet="textCaption"></ng-container>
          </div>
          <ng-template #textCaption>
            <span
              title="{{
                data.component.columnOption(colName, 'title')
                  ? data.component.columnOption(colName, 'title')
                  : data.component.columnOption(colName, 'caption')
              }}"
              [innerHTML]="data.component.columnOption(colName, 'caption')"
            ></span>
          </ng-template>
        </ng-container>
        <!--Filters render-->
        <div *ngIf="data.component.columnOption(colName, 'allowFiltering') !== false">
          <div *ngIf="data.component.columnOption(colName, 'lookup'); else textBlock">
            <select (change)="onFilterEnter(colName, $event)" [formControlName]="colName">
              <option
                *ngFor="let option of data.component.columnOption(colName, 'lookup').dataSource"
                [value]="option[data.component.columnOption(colName, 'lookup').valueExpr]"
              >
                {{ option[data.component.columnOption(colName, 'lookup').displayExpr] }}
              </option>
            </select>
          </div>
          <ng-template #textBlock>
            <div
              *ngIf="data.component.columnOption(colName, 'selectedFilterOperation') === 'between'; else noRangeBlock"
            >
              <ng-container
                *ngIf="!data.component.columnOption(colName, 'type')?.startsWith('date')"
                [formArrayName]="colName"
              >
                <app-input
                  [type]="data.component.columnOption(colName, 'type')"
                  [minValue]="data.component.columnOption(colName, 'minValue')"
                  placeholder="{{
                    data.component.columnOption(colName, 'placeholders')
                      ? data.component.columnOption(colName, 'placeholders')[0]
                      : ''
                  }}"
                  #from
                  (keyup.enter)="onFilterRangeEnter(colName, from, to)"
                  (blur)="onFilterRangeEnter(colName, from, to)"
                  [spacePressedStopPropagate]="true"
                  [formControlName]="0"
                >
                </app-input>
                <app-input
                  [type]="data.component.columnOption(colName, 'type')"
                  [minValue]="data.component.columnOption(colName, 'minValue')"
                  placeholder="{{
                    data.component.columnOption(colName, 'placeholders')
                      ? data.component.columnOption(colName, 'placeholders')[1]
                      : ''
                  }}"
                  #to
                  (keyup.enter)="onFilterRangeEnter(colName, from, $event.target)"
                  (blur)="onFilterRangeEnter(colName, from, to)"
                  [spacePressedStopPropagate]="true"
                  [formControlName]="1"
                >
                </app-input>
              </ng-container>
              <ng-container
                *ngIf="data.component.columnOption(colName, 'type')?.startsWith('date')"
                [formArrayName]="colName"
              >
                <dx-date-box
                  [height]="18"
                  [type]="data.component.columnOption(colName, 'type')"
                  #from
                  (keyup.enter)="
                    onFilterRangeEnter(colName, from, to, data.component.columnOption(colName, 'type'))
                  "
                  (onValueChanged)=" onFilterRangeEnter(colName, from, to, data.component.columnOption(colName, 'type'))"
                  [showClearButton]="true"
                  dateSerializationFormat="yyyy-MM-ddTHH:mm:ssZ"
                  [displayFormat]="'dd.MM.yyyy'"
                  [useMaskBehavior]="true"
                  [formControlName]="0"
                  placeholder="{{
                    data.component.columnOption(data.column.name, 'placeholders')
                      ? data.component.columnOption(data.column.name, 'placeholders')[0]
                      : ''
                  }}"
                ></dx-date-box>
                <dx-date-box
                  [height]="18"
                  [type]="data.component.columnOption(colName, 'type')"
                  #to
                  (keyup.enter)="
                    onFilterRangeEnter(colName, from, to, data.component.columnOption(colName, 'type'))
                  "
                  (onValueChanged)=" onFilterRangeEnter(colName, from, to, data.component.columnOption(colName, 'type'))"
                  [showClearButton]="true"
                  dateSerializationFormat="yyyy-MM-ddTHH:mm:ssZ"
                  [displayFormat]="'dd.MM.yyyy'"
                  [useMaskBehavior]="true"
                  [formControlName]="1"
                  placeholder="{{
                    data.component.columnOption(data.column.name, 'placeholders')
                      ? data.component.columnOption(data.column.name, 'placeholders')[1]
                      : ''
                  }}"
                ></dx-date-box>
              </ng-container>
            </div>
            <ng-template #noRangeBlock>
              <ng-container *ngIf="data.component.columnOption(colName, 'type')?.startsWith('date')">
                <dx-date-box
                  [height]="18"
                  [type]="data.component.columnOption(colName, 'type')"
                  (keyup.enter)="onFilterEnter(colName, $event)"
                  (onValueChanged)="onFilterEnter(colName, $event)"
                  [showClearButton]="true"
                  dateSerializationFormat="yyyy-MM-ddTHH:mm:ssZ"
                  [displayFormat]="
                    'dd.MM.yyyy' + (data.component.columnOption(colName, 'type') === 'datetime' ? ' HH:mm' : '')
                  "
                  [useMaskBehavior]="true"
                  [formControlName]="colName"
                  placeholder="{{
                    data.component.columnOption(data.column.name, 'placeholders')
                      ? data.component.columnOption(data.column.name, 'placeholders')[0]
                      : ''
                  }}"
                ></dx-date-box>
              </ng-container>
              <app-input
                *ngIf="!data.component.columnOption(colName, 'type')?.startsWith('date')"
                placeholder="{{
                  data.component.columnOption(colName, 'placeholders')
                    ? data.component.columnOption(colName, 'placeholders')[0]
                    : ''
                }}"
                (keyup.enter)="onFilterEnter(colName, $event)"
                (blur)="onFilterEnter(colName, $event)"
                [spacePressedStopPropagate]="true"
                [formControlName]="colName"
                [type]="data.component.columnOption(colName, 'type')"
                [minValue]="data.component.columnOption(colName, 'minValue')"
              ></app-input>
            </ng-template>
          </ng-template>
        </div>
      </div>
    </div>
    <div *dxTemplate="let item of 'customHeaderTemplate'">
      <ng-container
        *ngTemplateOutlet="getCustomTemplate(item.column.euconCustomHeaderTemplate); context: { $implicit: item }"
      >
      </ng-container>
    </div>
    <div *dxTemplate="let data of 'multipleFieldsCellTemplate'">
      <div *ngFor="let colName of data.column.entries">
        <div *ngIf="data.component.columnOption(colName, 'lookup'); else dataTextBlock">
          <div *ngIf="data.component.columnOption(colName, 'delegatedDataField')">
            {{ data.data[data.component.columnOption(colName, 'delegatedDataField')] }}
          </div>
          <div *ngIf="findOption(colName, data)">{{ findOption(colName, data) }}</div>
          <div *ngIf="!findOption(colName, data)">
            <div *ngIf="data.component.columnOption(colName, 'emptySymbol')" class="badge badge-color-1">
              {{ data.component.columnOption(colName, 'emptySymbol') }}
            </div>
          </div>
        </div>
        <ng-template #dataTextBlock>
          <div
            [innerHTML]="
              data.component.columnOption(colName, 'delegatedDataField')
                ? data.data[data.component.columnOption(colName, 'delegatedDataField')]
                : data.data[data.component.columnOption(colName, 'dataField')]
            "
          ></div>
        </ng-template>
      </div>
    </div>
    <div *dxTemplate="let data of 'checkBoxCellTemplate'">
      <div *ngFor="let colName of data.column.entries" class="mb-2">
        <app-table-check-box *ngIf="data.data[colName] !== null" [checked]="data.data[colName]"></app-table-check-box>
      </div>
    </div>
    <div *dxTemplate="let data of 'invertedCheckBoxCellTemplate'">
      <div *ngFor="let colName of data.column.entries" class="mb-2">
        <app-table-check-box
          *ngIf="data.data[colName] !== null"
          [checked]="data.data[colName]"
          [inverded]="true"
        ></app-table-check-box>
      </div>
    </div>
    <div *dxTemplate="let data of 'multipleLookupFieldsCellTemplate'">
      <div *ngFor="let colName of data.column.entries">
        <div
          *ngIf="data.data[data.component.columnOption(colName, 'delegatedDataField')]"
          [innerHTML]="data.data[data.component.columnOption(colName, 'delegatedDataField')]"
        ></div>
        <div
          *ngIf="!data.data[data.component.columnOption(colName, 'delegatedDataField')]"
          [innerHTML]="data.data[data.component.columnOption(colName, 'dataField')]"
        ></div>
      </div>
    </div>
    <div *dxTemplate="let data of 'arrayCellTemplate'">
      <div *ngFor="let colName of data.column.entries">
        <div>
          {{
            data.data[data.component.columnOption(colName, 'dataSource')]?.length !== 0
              ? data.data[data.component.columnOption(colName, 'dataSource')].join(
                  data.component.columnOption(colName, 'separator')
                )
              : '-'
          }}
        </div>
      </div>
    </div>
    <div *dxTemplate="let data of 'flagCellTemplate'">
      <ng-container *ngFor="let colName of data.column.entries">
        <span
          class="flag-icon flag-icon-{{ data.data[data.component.columnOption(colName, 'dataField')].toLowerCase() }}"
        ></span>
      </ng-container>
    </div>
    <div *dxTemplate="let data of 'linkCellTemplate'">
      <div *ngFor="let colName of data.column.entries">
        <div
          *ngIf="
            data.component.columnOption(colName, 'cellTemplate') === 'linkCellTemplate' &&
            data.data[data.component.columnOption(colName, 'dataField')] !== null
          "
          (click)="
            openWindow(
              data.component.columnOption(colName, 'link'),
              data.data[data.component.columnOption(colName, 'param')]
            )
          "
          class="link_cell"
        >
          {{ data.data[data.component.columnOption(colName, 'dataField')] }}
        </div>
        <div
          *ngIf="
            data.component.columnOption(colName, 'cellTemplate') === 'linkCellTemplate' &&
            data.data[data.component.columnOption(colName, 'dataField')] === null
          "
        >
          {{ data.component.columnOption(colName, 'emptySymbol') }}
        </div>
        <div *ngIf="data.component.columnOption(colName, 'cellTemplate') !== 'linkCellTemplate'">
          {{
            data.data[data.component.columnOption(colName, 'dataField')] !== null
              ? data.data[data.component.columnOption(colName, 'dataField')]
              : data.component.columnOption(colName, 'emptySymbol')
              ? data.component.columnOption(colName, 'emptySymbol')
              : ''
          }}
        </div>
      </div>
    </div>
    <div *dxTemplate="let data of 'mailCellTemplate'">
      <div *ngFor="let colName of data.column.entries">
        <a
          *ngIf="data.data[data.component.columnOption(colName, 'dataField')]"
          class="link_cell"
          [href]="'mailto: ' + data.data[data.component.columnOption(colName, 'dataField')]"
          >{{ data.data[data.component.columnOption(colName, 'dataField')] }}</a
        >
      </div>
    </div>
    <div *dxTemplate="let data of 'badgeCellTemplate'">
      <div *ngFor="let colName of data.column.entries">
        <div class="badge" [class.badge-error]="data.data[data.component.columnOption(colName, 'dataField')] === 0">
          {{ data.data[data.component.columnOption(colName, 'dataField')] }}
        </div>
      </div>
    </div>
    <div *dxTemplate="let data of 'buttonsCellTemplate'">
      <app-button-dropdown label="" icon="ellipsis-h" [items]="data.column.items"></app-button-dropdown>
    </div>
    <div *dxTemplate="let item of 'customTemplate'">
      <ng-container
        *ngTemplateOutlet="getCustomTemplate(item.column.euconCustomTemplate); context: { $implicit: item }"
      >
      </ng-container>
    </div>
    <div *dxTemplate="let data of 'detail'">
      <div *ngFor="let detailField of detailFields" [innerHTML]="data.data[detailField]"></div>
    </div>
  </dx-data-grid>
</form>
