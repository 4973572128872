<div class="tab-set" [ngClass]="{ 'height-unset': !renderInside }">
  <nav #nav="ngbNav" ngbNav [activeId]="activeId" (navChange)="onNavChange($event)">
    <ng-container *ngFor="let tab of tabs" [ngbNavItem]="tab.tabId">
      <a ngbNavLink (click)="onTabClick()">
        <ng-template #label>{{tab.tabLabel}}</ng-template>
        <ng-container *ngTemplateOutlet="tab.tabLabelTemplate || label"></ng-container>
        <span *ngIf="tab.tabBadge" class="tab-badge" [class]="tab.tabBadgeClass">{{tab.tabBadge}}</span>
      </a>
      <ng-template ngbNavContent>
        <ng-container *ngTemplateOutlet="tab.template"></ng-container>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="isCollapsed !== undefined" ngbNavItem="">
      <span class="toggle-icon" (click)="toggleCollapsed()">
        <i [class]="isCollapsed ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"></i>
      </span>
    </ng-container>
  </nav>
  <div [hidden]="isCollapsed" [ngbNavOutlet]="renderInside ? nav : dummyNgbNav"></div>
</div>
