import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-page-title-button',
	templateUrl: './page-title-button.component.html',
	styleUrls: ['./page-title-button.component.scss']
})
export class PageTitleButtonComponent implements OnInit {
	@Input() href: string = '';
	@Input() text: string = '';
	@Input() iconClasses: string = '';
	@Input() danger: boolean;

	constructor() {}

	ngOnInit(): void {}
}
