import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BaseFormControl } from '@app/shared/components/base-form-control/base-form-control';

@Component({
	selector: 'app-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent extends BaseFormControl<string, HTMLInputElement> implements OnInit, OnChanges {
	@Input() checked: boolean;
	@Input() id: string;
	@Input() text: string;

	@Input() icon: string;
	@Input() danger: boolean;
	@Input() pullRight: boolean;

	@Output() checkboxToggled = new EventEmitter();
	@Output() checkedChange = new EventEmitter();

	override ngOnInit() {
		super.ngOnInit();

		this.updateState();
	}

	private updateState() {
		if (this.fieldInfo) {
			this.id = this.fieldInfo.id;
			this.text = this.fieldInfo.textValue?.displayValue;
			this.checked = this.fieldInfo.textValue?.editValue;
		}
	}

	onActionToggle() {
		this.checked = !this.checked;
		this.onChange(this.checked);
		this.fieldInfo && this.changeValue(this.checked.toString());
		this.checkedChange.emit(this.checked);
		this.checkboxToggled.emit(this.checked);
	}
	ngOnChanges(changes: SimpleChanges): void {
		this.fieldInfo && this.assignFieldInfo();
		this.updateState();
	}
}
