<div class="login">
	<div class="login-box">
		<div class="login-logo">
			<img
				*ngIf="authService.isVoelkel; else otherImg"
				src=""
				class="logo-img"
				alt=""
				title="Smart Invoice"
				width="220"
				height="21"
			/>
			<ng-template #otherImg>
				<img src="" class="logo-img" title="Smart Invoice" width="200" height="60px" alt="" />
			</ng-template>
		</div>

		<form *ngIf="forgotForm && !state.sent" [formGroup]="forgotForm" (ngSubmit)="onReset()" class="login-form">
			<div class="login-title">
				<p class="login-text">{{ 'FORGOT_PW.TITLE' | translate }}</p>
			</div>

			<div class="input-group-field no-margin">
				<input
					type="email"
					class="login-input"
					[placeholder]="'FORGOT_PW.EMAIL' | translate"
					formControlName="username"
				/>
			</div>

			<div class="login-hint">
				<div *ngIf="username?.invalid && username?.touched && username?.dirty">
					<div *ngIf="username?.errors?.['required']">
						<span>{{ 'FORGOT_PW.ERROR.EMAIL_REQUIRED' | translate }}</span>
					</div>
					<div *ngIf="username?.errors?.['email']">
						<span>{{ 'FORGOT_PW.ERROR.EMAIL_INVALID' | translate }}</span>
					</div>
				</div>
			</div>

			<button [disabled]="!forgotForm.valid" type="submit" class="btn btn-primary">
				{{ 'FORGOT_PW.SUBMIT' | translate }}
			</button>

			<a routerLink="/login">{{ 'FORGOT_PW.BACK_TO_LOGIN' | translate }}</a>

			<div class="error">
				<span *ngIf="false">
					<i class="fa-exclamation-triangle"></i>
					<span> Error </span>
				</span>
			</div>

			<div class="error-field"></div>

			<div *ngIf="languageNext === 'English'" class="lang">
				<button class="btn-lang" (click)="changeLang()"><span class="lang-icon">EN</span>English?</button>
			</div>
			<div *ngIf="languageNext === 'Deutsch'" class="lang">
				<button class="btn-lang" (click)="changeLang()"><span class="lang-icon">DE</span>Deutsch?</button>
			</div>
		</form>
		<div *ngIf="state.sent" class="success-wrap">
			<div class="icon"><i class="fa fa-check"></i></div>
			<div class="msg" [innerHTML]="state.successMsg"></div>
			<a href="/login">{{ 'FORGOT_PW.BACK_TO_LOGIN' | translate }}</a>
		</div>
	</div>
</div>
