import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base.api.service';
import { ToastService } from '@app/services/common/toast.service';
import { Aggregation } from '../../app/smart-invoice/fabric/models/Aggregation';
import {
	AutocompleteVM,
	CaseActionDialogVM,
	CaseFeedbackVM,
	CaseInstanceQuickInfoVM,
	CaseInstanceVM,
	CaseSubmitUserTaskDialogInfo,
	CaseVM,
	CommentVM,
	EmailVM,
	MailLogDialogVM,
	OcrXmlPdf
} from '../../app/smart-invoice/case/models/case.model';
import { Case } from '../../app/smart-invoice/admin/cases/models/Models';

@Injectable({
	providedIn: 'root'
})
export class CaseApiService extends BaseApiService {
	constructor(http: HttpClient, private toastService: ToastService) {
		super(http);
	}

	getCaseContainer(id: number): Observable<CaseInstanceVM> {
		return this.http.get<any>(`${this.baseUrl}/case/${id}`, { params: this.viewport });
		// FIXME: return this.http.get<CaseInstanceVM | CaseInstanceQuickInfoVM>(`${this.baseUrl}/case/${id}`, { params: this.viewport })
	}

	editCaseDataProperty(caseDataId: number, property: string, newVal: string, label: string) {
		return this.toastService.toastify(
			this.http.patch(`${this.baseUrl}/case-data/${caseDataId}`, { property, value: newVal }),
			{
				caseData: true,
				edited: true,
				label
			}
		);
	}

	editAggProperty(aggregation: Aggregation) {
		return this.toastService.toastify(
			this.http.patch(`${this.baseUrl}/case-agg/${aggregation.caseID}/${aggregation.id}`, aggregation.key),
			{
				edited: true
			}
		);
	}

	unlockCase(id: number) {
		return this.http.post(`${this.baseUrl}/case/unlock/${id}`, null);
	}

	closeCaseLog(logID: number) {
		return this.http.post(`${this.baseUrl}/case-log/close/${logID}`, null);
	}

	restartCase(id: number, processStepId: number): Observable<number> {
		return this.toastService.toastify(
			this.http.post<number>(`${this.baseUrl}/case/${id}/restart/${processStepId}`, null),
			{
				success: 'CRUD.MSG.CASE_RESTART.SUCCESS',
				error: 'CRUD.MSG.CASE_RESTART.ERROR'
			}
		);
	}

	changeState(caseId: number, stateId: number): Observable<CaseInstanceVM> {
		return this.toastService.toastify(
			this.http.post<CaseInstanceVM>(`${this.baseUrl}/case/${caseId}/state/${stateId}`, {
				params: this.viewport
			}),
			{
				success: 'CRUD.MSG.CASE_STATUS.SUCCESS',
				error: 'CRUD.MSG.CASE_STATUS.ERROR'
			}
		);
	}

	exportCaseToSFTP(id: number) {
		return this.toastService.toastify(this.http.post(`${this.baseUrl}/case/${id}/export-to-sftp`, null), {
			success: 'CRUD.MSG.EXPORT_TO_SFTP.SUCCESS',
			error: 'CRUD.MSG.EXPORT_TO_SFTP.ERROR'
		});
	}

	getAssignmentSummaries(id: number, params: any): Observable<any> {
		return this.http.get<any>(`${this.baseUrl}/case/${id}/assignment-summaries`, {
			params: { ...params, ...this.viewport }
		});
	}

	getSimilarCases(id: number, onlyArchived: boolean, verySimilar: boolean): Observable<any> {
		return this.http.get<any>(
			`${this.baseUrl}/case/${id}/find-similar?onlyArchived=${onlyArchived}&verySimilar=${verySimilar}`,
			{ params: this.viewport }
		);
	}

	getMoveCaseToTrashCanDialog(caseId: number) {
		return this.http.get<CaseSubmitUserTaskDialogInfo>(`${this.baseUrl}/case/${caseId}/move-to-trash-can`);
	}

	getSubmitCaseDialog(caseId: number, routingActionId: number) {
		return this.http.get<CaseSubmitUserTaskDialogInfo>(`${this.baseUrl}/case/${caseId}/submit/${routingActionId}`);
	}

	purgeCase(id: number) {
		return this.toastService.toastify(this.http.post(`${this.baseUrl}/case/${id}/purge`, null), {
			removed: true,
			success: 'CRUD.MSG.CASE_INSTANCE.REMOVED'
		});
	}

	getOCRXML(caseId: number) {
		return this.http.get<OcrXmlPdf>(`${this.baseUrl}/case/${caseId}/ocr-xml`);
	}

	addPDFs(caseId: number) {
		return this.http.post(`${this.baseUrl}/dev/add-pdfs/${caseId}`, null);
	}

	submitUserTask(userTaskId: number, params: any) {
		return this.toastService.toastify(this.http.post(`${this.baseUrl}/user-task/submit/${userTaskId}`, params));
	}

	applyAssignmentSummary(assignmentID: number, summaryID: number) {
		return this.toastService.toastify(
			this.http.post(`${this.baseUrl}/assignment/${assignmentID}/assignment-summary/${summaryID}/apply`, {}),
			{ edit: true, caseData: true, assignment: true }
		);
	}

	revertAssignmentSummary(assignmentID: number) {
		return this.toastService.toastify(
			this.http.post(`${this.baseUrl}/assignment/${assignmentID}/assignment-summary/revert`, {}),
			{ edit: true, caseData: true, assignment: true }
		);
	}

	getCaseActionDialog(caseId: number, actionId: number, debitorInvoiceId: number) {
		let url = `${this.baseUrl}/case/${caseId}/action/${actionId}`;
		if (debitorInvoiceId) {
			url = `${url}?debitorInvoiceID=${debitorInvoiceId}`;
		}

		return this.http.get<CaseActionDialogVM>(url);
	}

	submitCaseActionDialog(caseId: number, actionId: number, values: CaseActionDialogVM) {
		return this.http.post(`${this.baseUrl}/case/${caseId}/action/${actionId}`, values);
	}

	addComment(caseId: number, text: string) {
		return this.http.post<CommentVM>(`${this.baseUrl}/case-comments`, {
			caseID: caseId,
			comment: text
		});
	}

	addCaseFeedback(caseID: number, message: string) {
		return this.http.post<CaseFeedbackVM>(`${this.baseUrl}/case-feedbacks`, { caseID, message });
	}

	removeComment(id: number) {
		return this.toastService.toastify(this.http.delete(`${this.baseUrl}/case-comments/${id}`), {
			removed: true,
			success: 'CRUD.MSG.CHAT.REMOVED'
		});
	}

	getEmails(searchString: string = '') {
		return this.http.get<EmailVM[]>(`${this.baseUrl}/email/search`, { params: { searchString } });
	}

	getCaseActionLogDialog(caseId: number, actionLogId: number) {
		return this.http.get<MailLogDialogVM>(`${this.baseUrl}/case/${caseId}/action-log/${actionLogId}`);
	}

	autocomplete(type: string, caseDataID: string, needle: string) {
		return this.http.get<AutocompleteVM>(`${this.baseUrl}/autocomplete/${type}/with-case-data/${caseDataID}`, {
			params: { needle }
		});
	}

	autocompletePMAccountAssignment(type: string, accountAssignmentID: string, needle: string) {
		return this.http.get<AutocompleteVM>(
			`${this.baseUrl}/autocomplete/${type}/with-pm-assignment/${accountAssignmentID}`,
			{ params: { needle } }
		);
	}

	autocompleteCorporateAccountAssignment(type: string, accountAssignmentID: string, needle: string) {
		return this.http.get<AutocompleteVM>(
			`${this.baseUrl}/autocomplete/${type}/with-corporate-assignment/${accountAssignmentID}`,
			{ params: { needle } }
		);
	}

	autocompleteAccountAssignment(type: string, accountAssignmentID: string, needle: string) {
		return this.http.get<AutocompleteVM>(
			`${this.baseUrl}/autocomplete/${type}/with-assignment/${accountAssignmentID}`,
			{ params: { needle } }
		);
	}

	autocompleteAccountAssignmentPos(type: string, accountAssignmentPosID: string, needle: string) {
		return this.http.get<AutocompleteVM>(
			`${this.baseUrl}/autocomplete/${type}/with-assignment-pos/${accountAssignmentPosID}`,
			{ params: { needle } }
		);
	}

	autocompleteCaseDataPos(type: string, posID: string, needle: string) {
		return this.http.get<AutocompleteVM>(`${this.baseUrl}/autocomplete/${type}/with-pos/${posID}`, {
			params: { needle }
		});
	}

	removeCase(caseId: number, comment: string) {
		return this.toastService.toastify(this.http.delete(`${this.baseUrl}/case/${caseId}`, { body: { comment } }), {
			removed: true,
			success: 'CRUD.MSG.CASE_INSTANCE.REMOVED'
		});
	}

	abortCaseCapturing(caseId: number) {
		return this.toastService.toastify(this.http.post(`${this.baseUrl}/case/${caseId}/abort-capturing`, null), {
			removed: false
		});
	}

	addReference(caseId: number, selectedCaseId: number, refType: string | undefined) {
		return this.toastService.toastify(
			this.http.post(`${this.baseUrl}/case/${caseId}/reference/${refType}/${selectedCaseId}/add`, null),
			{
				success: 'CRUD.MSG.ADD_REFERENCE.SUCCESS',
				error: 'CRUD.MSG.ADD_REFERENCE.ERROR',
				caseData: true,
				added: true
			}
		);
	}

	undoReference(id: number) {
		return this.toastService.toastify(this.http.post(`${this.baseUrl}/case-reference/${id}/undo`, null), {
			success: 'CRUD.MSG.UNDO_REFERENCE.SUCCESS',
			error: 'CRUD.MSG.UNDO_REFERENCE.ERROR',
			caseData: true
		});
	}

	verifyReference(id: number) {
		return this.toastService.toastify(this.http.post(`${this.baseUrl}/case-reference/${id}/verify/confirm`, null), {
			success: 'CRUD.MSG.VERIFIED_REFERENCE.SUCCESS',
			error: 'CRUD.MSG.VERIFIED_REFERENCE.ERROR',
			caseData: true
		});
	}

	declineReference(id: number) {
		return this.toastService.toastify(this.http.post(`${this.baseUrl}/case-reference/${id}/verify/decline`, null), {
			success: 'CRUD.MSG.DECLINED_REFERENCE.SUCCESS',
			error: 'CRUD.MSG.DECLINED_REFERENCE.ERROR',
			caseData: true
		});
	}

	deleteReference(id: number) {
		return this.toastService.toastify(this.http.delete(`${this.baseUrl}/case-reference/${id}`), {
			success: 'CRUD.MSG.DELETED_REFERENCE.SUCCESS',
			error: 'CRUD.MSG.DELETED_REFERENCE.ERROR',
			caseData: true
		});
	}

	loadCaseReferences(id: number) {
		return this.http.get<any>(`${this.baseUrl}/case/${id}/references`);
	}

	createUAMmail(body: string) {
		return this.http.post(`${this.baseUrl}/admin/uam-entry-create`, body);
	}

	findDuplicates(caseId: number) {
		return this.http.post(`${this.baseUrl}/case/${caseId}/find-new-duplicates`, null);
	}

	editAssignmentProperty(assignmentId: string, property: string, newVal: string, label: string) {
		return this.toastService.toastify(
			this.http.patch(`${this.baseUrl}/account-assignment/${assignmentId}`, { property, value: newVal }),
			{
				caseData: true,
				edited: true,
				label,
				assignment: true
			}
		);
	}

	editAssignmentPositionProperty(
		posId: string,
		property: string,
		newVal: string,
		label: string,
		recurringPaymentFieldGroupId: number
	) {
		return this.toastService.toastify(
			this.http.patch(`${this.baseUrl}/account-assignment-pos/${posId}`, {
				property,
				value: newVal,
				recurringPaymentFieldGroupId
			}),
			{
				caseData: true,
				edited: true,
				label,
				assignmentPos: true
			}
		);
	}

	editCorporateAssignmentProperty(assignmentId: string, property: string, newVal: string, label: string) {
		return this.toastService.toastify(
			this.http.patch(`${this.baseUrl}/corporate-account-assignment/${assignmentId}`, {
				property,
				value: newVal
			}),
			{
				caseData: true,
				edited: true,
				label,
				corporateAssignment: true
			}
		);
	}

	editPMAssignmentProperty(assignmentId: string, property: string, newVal: string, label: string) {
		return this.toastService.toastify(
			this.http.patch(`${this.baseUrl}/pm-account-assignment/${assignmentId}`, { property, value: newVal }),
			{
				caseData: true,
				edited: true,
				label,
				pmAssignment: true
			}
		);
	}

	editCaseCreditorDataProperty(
		caseDataId: string,
		property: string,
		newVal: string,
		label: string,
		newValTitle: string = '',
		reset?: boolean
	) {
		if (!reset) {
			return this.toastService.toastify(
				this.http.patch(`${this.baseUrl}/case-data/${caseDataId}/case-creditor-data`, {
					property,
					value: newVal
				}),
				{
					caseData: true,
					edited: true,
					label,
					value: newValTitle,
					creditor: true
				}
			);
		} else {
			return this.toastService.toastify(
				this.http.post(`${this.baseUrl}/case-data/${caseDataId}/reset-creditor-data`, null),
				{
					caseData: true,
					removed: true,
					label
				}
			);
		}
	}

	editCaseCreditorBankProperty(caseDataId: string, property: string, newVal: string, label: string) {
		return this.toastService.toastify(
			this.http.patch(`${this.baseUrl}/case-data/${caseDataId}/creditor-bank`, { property, value: newVal }),
			{
				caseData: true,
				edited: true,
				label,
				creditorBank: true
			}
		);
	}

	editCapturedCaseCreditorBankProperty(bankId: string, property: string, newVal: string, label: string) {
		return this.toastService.toastify(
			this.http.patch(`${this.baseUrl}/captured-creditor-bank/${bankId}`, { property, value: newVal }),
			{
				caseData: true,
				edited: true,
				label,
				capturedCreditorBank: true
			}
		);
	}

	editCasePositionProperty(positionId: string, property: string, newVal: string, label: string) {
		return this.toastService.toastify(
			this.http.patch(`${this.baseUrl}/case-data-position/${positionId}`, { property, value: newVal }),
			{
				caseData: true,
				edited: true,
				label,
				position: true
			}
		);
	}

	editCaseReminderListProperty(positionId: string, property: string, newVal: string, label: string) {
		return this.toastService.toastify(
			this.http.patch(`${this.baseUrl}/reminder-list-pos/${positionId}`, { property, value: newVal }),
			{
				caseData: true,
				edited: true,
				label,
				position: true
			}
		);
	}

	editDebitorInvoiceProperty(debitorInvoiceId: string, property: string, newVal: string, label: string) {
		return this.toastService.toastify(
			this.http.patch(`${this.baseUrl}/debitor-invoice/${debitorInvoiceId}`, { property, value: newVal }),
			{
				caseData: true,
				edited: true,
				label,
				assignment: true
			}
		);
	}

	editRecurringPaymentPlanProperty(planId: string, property: string, newVal: string, label: string) {
		return this.toastService.toastify(
			this.http.patch(`${this.baseUrl}/recurring-payment/field/${planId}`, { property, value: newVal }),
			{
				caseData: true,
				edited: true,
				label,
				assignment: true
			}
		);
	}

	updateAssignmentLookupField(
		type: string,
		assignmentId: string,
		newVal: string,
		label: string,
		newValTitle: string
	) {
		// We want to filter empty and null values but allow 0 and false
		if (newVal || typeof newVal === 'number' || typeof newVal === 'boolean') {
			return this.toastService.toastify(
				this.http.post(`${this.baseUrl}/account-assignment/${assignmentId}/${type}/${newVal}`, null),
				{
					caseData: true,
					edited: true,
					label,
					value: newValTitle,
					assignment: true
				}
			);
		} else {
			return this.toastService.toastify(
				this.http.delete(`${this.baseUrl}/account-assignment/${assignmentId}/${type}`),
				{
					caseData: true,
					removed: true,
					label,
					assignment: true
				}
			);
		}
	}

	updateAssignmentPositionLookupField(
		type: string,
		posId: string,
		newVal: string,
		label: string,
		newValTitle: string
	) {
		// We want to filter empty and null values but allow 0 and false
		if (newVal || typeof newVal === 'number' || typeof newVal === 'boolean') {
			return this.toastService.toastify(
				this.http.post(`${this.baseUrl}/account-assignment-pos/${posId}/${type}/${newVal}`, null),
				{
					caseData: true,
					edited: true,
					label,
					value: newValTitle,
					assignmentPos: true
				}
			);
		} else {
			return this.toastService.toastify(
				this.http.delete(`${this.baseUrl}/account-assignment-pos/${posId}/${type}`),
				{
					caseData: true,
					removed: true,
					label,
					assignmentPos: true
				}
			);
		}
	}

	updateCorporateAssignmentLookupField(
		type: string,
		assignmentId: string,
		newVal: string,
		label: string,
		newValTitle: string
	) {
		// We want to filter empty and null values but allow 0 and false
		if (newVal || typeof newVal === 'number' || typeof newVal === 'boolean') {
			return this.toastService.toastify(
				this.http.post(`${this.baseUrl}/corporate-account-assignment/${assignmentId}/${type}/${newVal}`, null),
				{
					caseData: true,
					edited: true,
					label,
					value: newValTitle,
					corporateAssignment: true
				}
			);
		} else {
			return this.toastService.toastify(
				this.http.delete(`${this.baseUrl}/corporate-account-assignment/${assignmentId}/${type}`),
				{
					caseData: true,
					removed: true,
					label,
					corporateAssignment: true
				}
			);
		}
	}

	updatePMAssignmentLookupField(
		type: string,
		assignmentId: string,
		newVal: string,
		label: string,
		newValTitle: string
	) {
		// We want to filter empty and null values but allow 0 and false
		if (newVal || typeof newVal === 'number' || typeof newVal === 'boolean') {
			return this.toastService.toastify(
				this.http.post(`${this.baseUrl}/pm-account-assignment/${assignmentId}/${type}/${newVal}`, null),
				{
					caseData: true,
					edited: true,
					label,
					value: newValTitle,
					pmAssignment: true
				}
			);
		} else {
			return this.toastService.toastify(
				this.http.delete(`${this.baseUrl}/pm-account-assignment/${assignmentId}/${type}`),
				{
					caseData: true,
					removed: true,
					label,
					pmAssignment: true
				}
			);
		}
	}

	editCaseCreditorBankLookupField(
		caseDataId: string,
		property: string,
		newVal: string | number | boolean,
		label: string,
		newValTitle: string
	) {
		// We want to filter empty and null values but allow 0 and false
		if (newVal || typeof newVal === 'number' || typeof newVal === 'boolean') {
			return this.toastService.toastify(
				this.http.patch(`${this.baseUrl}/case-data/${caseDataId}/creditor-bank`, { property, value: newVal }),
				{
					caseData: true,
					edited: true,
					label,
					value: newValTitle,
					creditor: true
				}
			);
		} else {
			return this.toastService.toastify(
				this.http.post(`${this.baseUrl}/case-data/${caseDataId}/reset-creditor-bank`, null),
				{
					caseData: true,
					removed: true,
					label
				}
			);
		}
	}

	editCapturedCreditorBankLookupField(
		caseDataId: string,
		property: string,
		newVal: string,
		label: string,
		newValTitle: string
	) {
		return this.toastService.toastify(
			this.http.post(`${this.baseUrl}/captured-creditor-bank/${caseDataId}/${property}/${newVal}`, null),
			{
				caseData: true,
				edited: true,
				label,
				value: newValTitle,
				position: true
			}
		);
	}

	updatePositionLookupField(
		type: string,
		positionId: string,
		newVal: string | number | boolean,
		label: string,
		newValTitle: string
	) {
		// We want to filter empty and null values but allow 0 and false
		if (newVal || typeof newVal === 'number' || typeof newVal === 'boolean') {
			return this.toastService.toastify(
				this.http.post(`${this.baseUrl}/case-data-position/${positionId}/${type}/${newVal}`, null),
				{
					caseData: true,
					edited: true,
					label,
					value: newValTitle,
					position: true
				}
			);
		} else {
			return this.toastService.toastify(
				this.http.delete(`${this.baseUrl}/case-data-position/${positionId}/${type}`),
				{
					caseData: true,
					removed: true,
					label,
					position: true
				}
			);
		}
	}

	updateDebitorInvoiceLookupField(type: string, diId: string, newVal: string, label: string, newValTitle: string) {
		return this.toastService.toastify(
			this.http.post(`${this.baseUrl}/debitor-invoice/${diId}/${type}/${newVal}`, null),
			{
				caseData: true,
				edited: true,
				label,
				value: newValTitle,
				position: true
			}
		);
	}

	updateLookupField(
		type: string,
		caseDataId: string,
		newVal: string | number | boolean,
		label: string,
		newValTitle: string
	) {
		// We want to filter empty and null values but allow 0 and false
		if (newVal || typeof newVal === 'number' || typeof newVal === 'boolean') {
			return this.toastService.toastify(
				this.http.post(`${this.baseUrl}/case-data/${caseDataId}/${type}/${newVal}`, null),
				{
					caseData: true,
					edited: true,
					label,
					value: newValTitle
				}
			);
		} else {
			return this.toastService.toastify(this.http.delete(`${this.baseUrl}/case-data/${caseDataId}/${type}`), {
				caseData: true,
				removed: true,
				label
			});
		}
	}

	getDebitorPDFPreviewUrl(assignmentID: any, token: any) {
		return `${this.baseUrl}/pdf-file/debitor-invoice/preview/${assignmentID}?t=${token}&d=${Date.now()}`;
	}

	getPDFUrl(pdfID: number, token: any) {
		return `${this.baseUrl}/pdf-file/view/${pdfID}?t=${token}`;
	}

	getCaseInfo(id: any) {
		return this.http.get(`${this.baseUrl}/case-quick-info/${id}`);
	}

	addRetention(assignmentPosID: number, retention: any, label: string) {
		return this.toastService.toastify(
			this.http.post(`${this.baseUrl}/account-assignment/${assignmentPosID}/retention`, retention),
			{ caseData: true, added: true, label }
		);
	}
	addRetentionToPos(assignmentPosID: number, retention: any, label: string) {
		return this.toastService.toastify(
			this.http.post(`${this.baseUrl}/account-assignment-pos/${assignmentPosID}/retention`, retention),
			{ caseData: true, added: true, label }
		);
	}

	removeRetention(assignmentID: number, retentionID: number, label: string) {
		return this.toastService.toastify(
			this.http.delete(`${this.baseUrl}/account-assignment/${assignmentID}/retention/${retentionID}`),
			{ caseData: true, removed: true, label }
		);
	}

	removeRetentionPos(assignmentPosID: number, retentionID: number, label: string) {
		return this.toastService.toastify(
			this.http.delete(`${this.baseUrl}/account-assignment-pos/${assignmentPosID}/retention/${retentionID}`),
			{ caseData: true, removed: true, label }
		);
	}
	getOrderAssignmentRetentions(caseId: number) {
		return this.http.get<any>(`${this.baseUrl}/order-assignment-retentions/${caseId}`);
	}

	lookupCaseData(caseID: any, what: any, params: any) {
		return this.fetchList(`case/${caseID}/lookup/${what}`, params);
	}

	lookupAssignmentData(caseID: any, assignmentID: any, what: any, params: any) {
		return this.fetchList(`case/${caseID}/assignment/${assignmentID}/lookup/${what}`, params);
	}

	lookupAssignmentPositionData(caseID: any, positionID: any, what: any, params: any) {
		return this.fetchList(`case/${caseID}/assignment-position/${positionID}/lookup/${what}`, params);
	}
	resetCreditorData(caseDataId: string) {
		return this.http.post(`${this.baseUrl}/case-data/${caseDataId}/reset-creditor-data`, null);
	}
	resetCreditorBank(caseDataId: string) {
		return this.http.post(`${this.baseUrl}/case-data/${caseDataId}/reset-creditor-bank`, null);
	}
	setCreditorBank(caseDataId: string, bankId: number) {
		return this.http.post(`${this.baseUrl}/case-data/${caseDataId}/creditor-bank/${bankId}`, null);
	}
	setCapturedCreditorBank(caseDataId: string, capturedBankId: number) {
		return this.http.post(`${this.baseUrl}/case-data/${caseDataId}/creditor-bank-captured/${capturedBankId}`, null);
	}
	addAssignment(caseId: number) {
		return this.toastService.toastify(
			this.http.post(`${this.baseUrl}/case/${caseId}/create-account-assignment`, null),
			{
				caseData: true,
				added: true,
				assignment: true,
				success: 'CRUD.MSG.ASSIGNMENT.ADDED'
			}
		);
	}
	resetCABank(caId: number) {
		return this.toastService.toastify(
			this.http.post(`${this.baseUrl}/corporate-account-assignment/${caId}/bank-reset`, null),
			{ success: 'CRUD.MSG.RESET_CABANK.SUCCESS', error: 'CRUD.MSG.RESET_CABANK.ERROR', caseData: true }
		);
	}
	setCABank(caId: number, bankId: number) {
		return this.toastService.toastify(
			this.http.post(`${this.baseUrl}/corporate-account-assignment/${caId}/bank/${bankId}`, null),
			{
				success: 'CRUD.MSG.SELECT_CABANK.SUCCESS',
				error: 'CRUD.MSG.SELECT_CABANK.ERROR',
				caseData: true
			}
		);
	}

	saveGSCreditor(caseDataId: string) {
		return this.toastService.toastify(
			this.http.post(`${this.baseUrl}/case-data/${caseDataId}/gs-creditor-save`, null),
			{
				success: 'CRUD.MSG.SAVE_GS.SUCCESS',
				error: 'CRUD.MSG.SAVE_GS.ERROR',
				caseData: true,
				added: true
			}
		);
	}

	saveGSBank(caseDataId: string) {
		return this.toastService.toastify(
			this.http.post(`${this.baseUrl}/case-data/${caseDataId}/gs-creditor-bank-save`, null),
			{
				success: 'CRUD.MSG.SAVE_GS_BANK.SUCCESS',
				error: 'CRUD.MSG.SAVE_GS_BANK.ERROR',
				caseData: true,
				added: true
			}
		);
	}

	setEuconCreditor(caseDataId: string, creditorId: number) {
		return this.toastService.toastify(
			this.http.post(`${this.baseUrl}/case-data/${caseDataId}/eucon-creditor/${creditorId}`, null),
			{
				success: 'CRUD.MSG.SAVE_GS_CREDITOR.SUCCESS',
				error: 'CRUD.MSG.SAVE_GS_CREDITOR.ERROR',
				caseData: true
			}
		);
	}

	resetGSCreditor(caseDataId: string) {
		return this.toastService.toastify(
			this.http.post(`${this.baseUrl}/case-data/${caseDataId}/gs-creditor-reset`, null),
			{
				success: 'CRUD.MSG.RESET_GS_CREDITOR.SUCCESS',
				error: 'CRUD.MSG.RESET_GS_CREDITOR.ERROR',
				caseData: true
			}
		);
	}

	resetGSBank(caseDataId: string) {
		return this.toastService.toastify(
			this.http.post(`${this.baseUrl}/case-data/${caseDataId}/gs-bank-reset`, null),
			{
				success: 'CRUD.MSG.RESET_GS_CREDITOR_BANK.SUCCESS',
				error: 'CRUD.MSG.RESET_GS_CREDITOR_BANK.ERROR',
				caseData: true
			}
		);
	}

	setEuconBank(caseDataId: string, bankId: number) {
		return this.toastService.toastify(
			this.http.post(`${this.baseUrl}/case-data/${caseDataId}/eucon-bank/${bankId}`, null),
			{
				success: 'CRUD.MSG.SELECT_EUCON_BANK.SUCCESS',
				error: 'CRUD.MSG.SELECT_EUCON_BANK.ERROR',
				caseData: true
			}
		);
	}
	getOrderAssignments(params: any): any {
		return this.fetchList<any>('order-assignments', params);
	}

	purgeRecurringPaymentPlan(planId: number) {
		return this.toastService.toastify(this.http.delete(`${this.baseUrl}/recurring-payment/purge/${planId}`), {
			edit: true,
			caseData: true,
			assignment: true,
			assignmentChanged: true
		});
	}

	addScreenshot(assignmentID: number, pdf: Blob): Observable<any> {
		const formData = new FormData();
		formData.append('file', pdf, 'screenshot.pdf');

		// return $http({
		// 	url: `${apiURL}screenshot/add/${assignmentID}`,
		// 	headers: { 'Content-Type': undefined },
		// 	data: formData,
		// 	method: 'POST'
		// });
		return this.toastService.toastify(this.http.post(`${this.baseUrl}/screenshot/add/${assignmentID}`, formData), {
			success: 'CRUD.MSG.SCREENSHOT.SUCCESS',
			error: 'CRUD.MSG.SCREENSHOT.ERROR',
			caseData: true
		});
	}

	changeCurrencyOfAccountAssignment(caseAccountAssignmentId: number, currencyId: string) {
		return this.toastService.toastify(
			this.http.post(
				`${this.baseUrl}/case-data/assignment/${caseAccountAssignmentId}/currency/${currencyId}`,
				null
			),
			{
				edit: true,
				caseData: true,
				assignment: true,
				assignmentChanged: true
			}
		);
	}
}
