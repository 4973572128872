<div class="note-wrapper">
	<div class="action" *ngIf="vm.allowDisableOrEnable && !external">
		<app-button *ngIf="!external && vm.allowEdit"
			cssClass="btn-sm btn-danger"
			[disabled]="!vm.optional || !vm.allowDisableOrEnable || !vm.allowEdit"
			[label]="'UNION_NOTE.DELETE' | translate"
			[tooltip]="vm.optional ? '' : 'UNION_NOTE.DELETION_FORBIDDEN' | translate"
			(click)="deleteNote()">
		</app-button>
		<app-button
			cssClass="btn-sm"
			[disabled]="!vm.allowEdit"
			[label]="(vm.note.disabled ? 'UNION_NOTE.ENABLE' : 'UNION_NOTE.DISABLE') | translate"
			(click)="enabledOrDisable()"
		>
		</app-button>
	</div>
	<ng-container class="" *ngIf="vm.note.disabled && !external">
		<div *ngIf="!vm.note.uploadedNotePDFID" class="upload-wrap">
			<div class="mb-8">{{ 'ATTACHMENTS.UPLOAD_ACCOMPANYING_NOTE_HINT' | translate }}</div>
			<app-button
				icon="fa-upload"
				[disabled]="!!file.isProgress || !vm.allowEdit"
				[label]="uploadButtonLabel"
				(click)="openFileSelect()"
				*ngIf="!vm.note.uploadedNotePDFID"
			>
			</app-button>
			<input
				#fileInput
				type="file"
				style="display: none"
				(change)="uploadFile($event)"
				accept="application/pdf"
			/>
		</div>
		<div class="case-viewer bg-white p-5" *ngIf="vm.note.uploadedNotePDFID">
			<div
				class="case-viewer-content d-flex flex-column"
				[ngStyle]="{ height: 'calc(100% - ' + (60 + 33) + 'px)' }"
			>
				<app-pdf-viewer *ngIf="pdfURL" [pdfURL]="pdfURL" [canUseImgViewer]="false"></app-pdf-viewer>
			</div>
		</div>
	</ng-container>
	<div class="note-content" *ngIf="!vm.note.disabled">
		<div class="box">
			<div class="line-header">
				<span>Objekt</span>
			</div>
			<div class="line">
				<div class="col1">Objekt:</div>
				<div class="val1">{{ vm.note.objectName }}</div>
				<div class="colright">ISH / ProjektNr.</div>
				<div class="valright">{{ vm.note.projectNr }}</div>
			</div>
			<div class="line">
				<div class="col1">Leistungsort:</div>
				<div class="val1">{{ vm.note.placeOfPerformance }}</div>
				<div class="colright">WE</div>
				<div class="valright">{{ vm.note.businessEntityKey }}</div>
			</div>
			<div class="line">
				<div class="col1">Auftragnehmer:</div>
				<div class="val1">{{ vm.note.contractor }}</div>
			</div>
			<div class="line">
				<div class="col1">Leistung/Gewerk</div>
				<div class="val1">{{ vm.note.craft }}</div>
			</div>
		</div>

		<div class="box">
			<div class="line-header">
				<span>Auftrag</span>
			</div>
			<div class="line">
				<div class="col1">Auftragsnummer (GVF):</div>
				<div class="val1 right">{{ vm.note.orderBusinessKeys.join(', ') }}</div>
				<div class="middlecol">&nbsp;</div>
				<div class="colright">Rechnung Nr.:</div>
				<div class="valright">{{ vm.note.billNumber }}</div>
			</div>
			<div class="line input-right">
				<div class="col1">Auftragssumme:</div>
				<div class="val1 right" style="display: flex; justify-content: flex-end; align-items: flex-start">
					<span style="margin-left: 2px">
						<app-input
							[fieldInfo]="vm.fields['mainOrderNetAmount']"
							[externallyEditable]="external"
							[externalToken]="externalToken"
							[disabled]="!vm.allowEdit"
						></app-input>
					</span>
					<span class="net-amount"><span class="word">Netto</span></span>
				</div>
				<div class="middlecol">&nbsp;</div>
				<div class="colright">Rechnungsdatum:</div>
				<div class="valright">{{ vm.note.billDate }}</div>
			</div>
			<div class="line input-right">
				<div class="col1">Summe Nachträge:</div>
				<div class="val1 right" style="display: flex; justify-content: flex-end; align-items: flex-start">
					<span style="margin-left: 2px">
						<app-input
							[fieldInfo]="vm.fields['supplementsNetAmount']"
							[externallyEditable]="external"
							[externalToken]="externalToken"
							[disabled]="!vm.allowEdit"
						></app-input>
					</span>
					<span class="net-amount"><span class="word">Netto</span></span>
				</div>
				<div class="middlecol">&nbsp;</div>
				<div class="colright">Rechnungsart:</div>
				<div class="valright">
					<app-input
						class="force-left"
						[fieldInfo]="vm.fields['billType']"
						[externallyEditable]="external"
						[externalToken]="externalToken"
						[disabled]="!vm.allowEdit"
					></app-input>
				</div>
			</div>
			<div class="line input-right">
				<div class="col1">Gesamt-Auftragssumme:</div>
				<div class="val1 right" style="display: flex; justify-content: flex-end; align-items: flex-start">
					<span style="margin-left: 2px">
						<app-input
							[fieldInfo]="vm.fields['noteNetAmount']"
							[externallyEditable]="external"
							[externalToken]="externalToken"
							[disabled]="!vm.allowEdit"
						></app-input>
					</span>
					<span class="net-amount"><span class="word">Netto</span></span>
				</div>
				<div class="middlecol">&nbsp;</div>
				<div class="colright">&nbsp;</div>
				<div class="valright">&nbsp;</div>
			</div>
			<div class="line input-right">
				<div class="col1">Gewährleistung:</div>
				<div class="val1 right" style="display: flex; justify-content: flex-end; align-items: flex-start">
					<div class="percentage">
						<app-input
							[fieldInfo]="vm.fields['warrantyPercentage']"
							[externallyEditable]="external"
							[externalToken]="externalToken"
							[disabled]="!vm.allowEdit"
						></app-input>
					</div>
					<span style="margin-left: 2px">
						<app-input
							[fieldInfo]="vm.fields['warrantyNetAmount']"
							[externallyEditable]="external"
							[externalToken]="externalToken"
							[disabled]="!vm.allowEdit"
						></app-input>
					</span>
					<span class="net-amount">
						<span class="word">Netto</span>
					</span>
				</div>
				<div class="middlecol">&nbsp;</div>
				<div class="big-col checkboxes">
					<app-checkbox
						[fieldInfo]="vm.fields['warrantyTypeContractPerfGuar']"
						[externallyEditable]="external"
						[externalToken]="externalToken"
					></app-checkbox>
					<app-checkbox
						[fieldInfo]="vm.fields['warrantyTypeRetention']"
						[externallyEditable]="external"
						[externalToken]="externalToken"
					></app-checkbox>
					<app-checkbox
						[fieldInfo]="vm.fields['warrantyTypeGuarantee']"
						[externallyEditable]="external"
						[externalToken]="externalToken"
					></app-checkbox>
				</div>
			</div>
			<div class="line">
				<div class="col1">Abrechnungsstand</div>
				<div class="val1 right">{{ vm.note.performanceLevelPercentFormatted }}</div>
				<div class="middlecol-big">
					<span class="net-amount"
						>{{ vm.note.performanceLevelShareFormatted }}<span class="word">Netto</span></span
					>
				</div>
				<div class="medium-last-col">&nbsp;</div>
			</div>
		</div>

		<div class="box">
			<div class="line-header">
				<span>Gesamt-Leistungsstand</span>
			</div>
			<div class="line-long">
				<div class="col1"><strong>ungeprüft</strong></div>
				<div class="val1">
					<span class="net-amount"
						>{{ vm.note.billNetUnauditedAmountFormatted }}<span class="word">Netto</span></span
					>
				</div>
			</div>
			<div class="line-long top-border">
				<div class="col1">Sachliche und rechnerische Korrektur (bereits bezahlte Abschläge)</div>
				<div class="val1">
					<span class="net-amount"
						>{{ vm.note.factualAndComputationalCorrectionAmountAlreadyPaidFormatted
						}}<span class="word">Netto</span></span
					>
				</div>
			</div>
			<div class="line-long input-right">
				<div class="col1">Sachliche und rechnerische Korrektur</div>
				<div class="val1" style="display: flex; flex-direction: row-reverse">
					<div style="width: 120px" class="net-amount-input">
						<app-input
							[fieldInfo]="vm.fields['factualAndComputationalCorrectionAmount']"
							[externallyEditable]="external"
							[externalToken]="externalToken"
							[disabled]="!vm.allowEdit"
						></app-input>
						<span class="word">Netto</span>
					</div>
				</div>
			</div>
			<div class="line-long">
				<div class="col1"><strong>geprüft</strong></div>
				<div class="val1">
					<span class="net-amount"
						>{{ vm.note.billNetAuditedAmountFormatted }}<span class="word">Netto</span></span
					>
				</div>
			</div>
			<div class="line-long top-border">
				<div class="col1">Nachlass (bereits bezahlte Abschläge)</div>
				<div class="val1">
					<span class="net-amount"
						>{{ vm.note.discountAmountAlreadyPaidFormatted }}<span class="word">Netto</span></span
					>
				</div>
			</div>
			<div class="line-long input-right">
				<div class="col1">Nachlass</div>
				<div class="val1" style="display: flex; flex-direction: row-reverse">
					<div style="width: 120px; margin-left: 2px" class="net-amount-input">
						<app-input
							[fieldInfo]="vm.fields['discountAmount']"
							[externallyEditable]="external"
							[externalToken]="externalToken"
							[disabled]="!vm.allowEdit"
						></app-input>
						<span class="word">Netto</span>
					</div>
				</div>
			</div>
			<div class="line-long">
				<div class="col1">Nach Abzug Nachlass</div>
				<div class="val1">
					<span class="net-amount"
						>{{ vm.note.billNetAfterReductionAmountFormatted }}<span class="word">Netto</span></span
					>
				</div>
			</div>
			<div class="line-long top-border">
				<div class="col1">zzgl. MwSt.</div>
				<div class="val1 net-amount-input" style="display: flex; flex-direction: row-reverse">
					<span class="word">&nbsp;</span>
					<div style="width: 120px">
						{{ vm.note.vatAmountFormatted }}
					</div>
					<div class="percentage">
						{{ vm.note.vatPercentFormatted }}
					</div>
				</div>
			</div>
			<div class="line-long top-border">
				<div class="col1">Geprüft inkl. Abzug von Nachlass</div>
				<div class="val1">
					<span class="gross-amount"
						>{{ vm.note.billTotalAmountFormatted }}<span class="word">Brutto</span></span
					>
				</div>
			</div>
		</div>

		<div class="box">
			<div class="line-header">
				<span>Bereits bezahlte Abschläge</span>
			</div>
			<div class="line-long">
				<div class="tablecol1 header">Vorgang</div>
				<div class="tablecol2 header">SAP-GVF-Nummer</div>
				<div class="tablecol3 header">Datum</div>
				<div class="tablecol4 header">Betrag</div>
				<div class="tablecol5 header">Sicherheitseinbehalt</div>
			</div>
			<div class="line-long" *ngFor="let booking of vm.note.bookings">
				<div class="tablecol1">
					<a [routerLink]="['/case', booking.caseID]" target="_blank">{{ booking.caseID }}</a>
				</div>
				<div class="tablecol2">{{ booking.sapTransactionNr }}</div>
				<div class="tablecol3">{{ booking.bookingDateFormatted }}</div>
				<div class="tablecol4">
					<span class="net-amount"
						>{{ booking.bookedNetMinusRetentionsFormatted }}<span class="word">Netto</span></span
					>
				</div>
				<div class="tablecol5">
					<span class="net-amount"
						>{{ booking.warrantyRetentionsNetAmountFormatted }}<span class="word">Netto</span></span
					>
				</div>
			</div>
			<div class="line-long top-border">
				<div class="tablecol1">&nbsp;</div>
				<div class="tablecol2"><strong>Summe</strong></div>
				<div class="tablecol3"></div>
				<div class="tablecol4">
					<span class="net-amount"
						><strong>{{ vm.note.totalDeductionsNetFormatted }}</strong
						><span class="word">Netto</span></span
					>
				</div>
				<div class="tablecol5">
					<span class="net-amount"
						><strong>{{ vm.note.totalWarrantyRetentionNetFormatted }}</strong
						><span class="word">Netto</span></span
					>
				</div>
			</div>
			<div class="line-long top-border">
				<div class="col1">Rechnungsbetrag nach Abschlägen</div>
				<div class="val1">
					<span class="net-amount"
						>{{ vm.note.netAmountAfterDeductionsFormatted }}<span class="word">Netto</span></span
					>
				</div>
			</div>
		</div>

		<div class="box">
			<div class="line-header">
				<span>{{ vm.note.billType === 'FINAL' ? 'Endabrechnung' : 'Abschlagszahlungen' }}</span>
			</div>

			<div class="line-long">
				<div class="col1">
					{{
						vm.note.billType === 'FINAL'
							? 'Freigabebetrag nach Abzug der Abschläge, unter Hinzurechnung der Garantieeinbehalte'
							: 'Freigabebetrag nach Abzug der Abschläge'
					}}
				</div>
				<div class="val1">
					<span class="net-amount"
						>{{ vm.note.netAmountReleasedAfterDeductionOfDiscountsFormatted
						}}<span class="word">Netto</span></span
					>
				</div>
			</div>

			<div class="line-long top-border">
				<div class="col1 inner-flex">
					<div class="label">zzgl. MwSt.</div>
					<div class="middle-box">
						{{ vm.note.vatRateReleasedAfterDeductionOfDiscountsFormatted }}
					</div>
					<div class="right">&nbsp;</div>
				</div>
				<div class="val1 net-amount">
					{{ vm.note.vatPriceReleasedAfterDeductionOfDiscountsFormatted }}<span class="word">&nbsp;</span>
				</div>
			</div>

			<div class="line-long">
				<div class="col1">Freigegebener Rechnungsbetrag</div>
				<div class="val1">
					<span class="net-amount"
						>{{ vm.note.releasedGrossAmountFormatted }}<span class="word">Brutto</span></span
					>
				</div>
			</div>

			<div class="line-long top-border input-right">
				<div class="col1 inner-flex">
					<div class="label">
						abzgl. {{ vm.note.billType === 'FINAL' ? 'Garantieeinbehalt' : 'Sicherheitseinbehalt' }}
					</div>
					<div class="middle-box net-amount-input">
						<app-input
							[fieldInfo]="vm.fields['minusWarrantyRetentionPercent']"
							[externallyEditable]="external"
							[externalToken]="externalToken"
							[disabled]="!vm.allowEdit"
						></app-input>
					</div>
					<div class="right">&nbsp;</div>
				</div>
				<div class="val1 net-amount-input">
					<app-input
						[fieldInfo]="vm.fields['minusWarrantyRetentionNetAmount']"
						[externallyEditable]="external"
						[externalToken]="externalToken"
						[disabled]="!vm.allowEdit"
					></app-input>
					<span class="word">Netto</span>
				</div>
			</div>

			<div class="line-long">
				<div class="col1 inner-flex">
					<div class="label">MwSt. auf Einbehalt</div>
					<div class="middle-box">
						{{ vm.note.minusWarrantyRetentionVatRateFormatted }}
					</div>
					<div class="right">&nbsp;</div>
				</div>
				<div class="val1 net-amount">
					{{ vm.note.minusWarrantyRetentionVatPriceFormatted }}<span class="word">&nbsp;</span>
				</div>
			</div>

			<div class="line-long">
				<div class="col1">
					{{ vm.note.billType === 'FINAL' ? 'Garantieeinbehalt' : 'Sicherheitseinbehalt' }}
				</div>
				<div class="val1">
					<span class="gross-amount"
						>{{ vm.note.minusWarrantyRetentionGrossFormatted }}<span class="word">Brutto</span></span
					>
				</div>
			</div>

			<div class="line-long">
				<div class="col1">Freigabebetrag abzgl. Einbehalte:</div>
				<div class="val1">
					<span class="gross-amount"
						>{{ vm.note.releaseAmountWithoutRetentionsGrossFormatted
						}}<span class="word">Brutto</span></span
					>
				</div>
			</div>

			<div class="line-long top-border input-right">
				<div class="col1 inner-flex">
					<div class="label">abzüglich vereinbart. Skonto</div>
					<div class="middle-box">
						<app-input
							[fieldInfo]="vm.fields['minusAgreedDiscountPercent']"
							[externallyEditable]="external"
							[externalToken]="externalToken"
							[disabled]="!vm.allowEdit"
						></app-input>
					</div>
					<div class="right">&nbsp;</div>
				</div>
				<div class="val1 net-amount-input">
					<app-input
						[fieldInfo]="vm.fields['minusAgreedDiscountAmount']"
						[externallyEditable]="external"
						[externalToken]="externalToken"
						[disabled]="!vm.allowEdit"
					></app-input>
					<span class="word">Brutto</span>
				</div>
			</div>

			<div class="line-long">
				<div class="col1">
					{{
						vm.note.billType === 'PARTIAL'
							? 'Auszahlungsbetrag'
							: 'Freigabebetrag abzgl. Einbehalte und Skonto'
					}}
				</div>
				<div class="val1">
					<span class="gross-amount"
						>{{ vm.note.amountPaidOutGrossFormatted }}<span class="word">Brutto</span></span
					>
				</div>
			</div>

			<div class="line-long top-border input-right" *ngIf="vm.note.billType === 'FINAL'">
				<div class="col1 inner-flex">
					<div class="label">abzgl. Stromanteil</div>
					<div class="middle-box">
						<app-input
							[fieldInfo]="vm.fields['minusElectricityShareGrossPercent']"
							[externallyEditable]="external"
							[externalToken]="externalToken"
							[disabled]="!vm.allowEdit"
						></app-input>
					</div>
					<div class="right">&nbsp;</div>
				</div>
				<div class="val1 net-amount-input">
					<app-input
						[fieldInfo]="vm.fields['minusElectricityShareGrossAmount']"
						[externallyEditable]="external"
						[externalToken]="externalToken"
						[disabled]="!vm.allowEdit"
					></app-input>
					<span class="word">Brutto</span>
				</div>
			</div>

			<div class="line-long input-right" *ngIf="vm.note.billType === 'FINAL'">
				<div class="col1 inner-flex">
					<div class="label">abzgl. Bauleistungsversicherung</div>
					<div class="middle-box">
						<app-input
							[fieldInfo]="vm.fields['minusConstructionWorkInsurancePercent']"
							[externallyEditable]="external"
							[externalToken]="externalToken"
							[disabled]="!vm.allowEdit"
						></app-input>
					</div>
					<div class="right">&nbsp;</div>
				</div>
				<div class="val1 net-amount-input">
					<app-input
						[fieldInfo]="vm.fields['minusConstructionWorkInsuranceAmount']"
						[externallyEditable]="external"
						[externalToken]="externalToken"
						[disabled]="!vm.allowEdit"
					></app-input>
					<span class="word">Netto</span>
				</div>
			</div>

			<div class="line-long top-border" *ngIf="vm.note.billType === 'FINAL'">
				<div class="col1">Auszahlungsbetrag abzgl. Umlagen</div>
				<div class="val1">
					<span class="gross-amount"
						><strong
							>{{ vm.note.amountPaidOutGrossMinusAllocationsFormatted
							}}<span class="word">Brutto</span></strong
						></span
					>
				</div>
			</div>
		</div>

		<div class="box">
			<div class="line-header">
				<span>Bemerkungen</span>
			</div>
			<div class="line-long input-right">
				<div class="col1">Abnahme erfolgt am</div>
				<div class="val1">
					<app-input
						[fieldInfo]="vm.fields['acceptanceDate']"
						[externallyEditable]="external"
						[externalToken]="externalToken"
						[disabled]="!vm.allowEdit"
					></app-input>
				</div>
			</div>
			<div class="line-long input-right">
				<div class="col1">Gewährleistung Ende</div>
				<div class="val1">
					<app-input
						[fieldInfo]="vm.fields['warrantyEndDate']"
						[externallyEditable]="external"
						[externalToken]="externalToken"
						[disabled]="!vm.allowEdit"
					></app-input>
				</div>
			</div>
		</div>
	</div>
</div>
