<div
	class="external-release"
	style="background-image:url('{{ environment.backgroundImagePath }}')"
	*ngIf="this.releaseData"
	[ngClass]="{'union-note-layout': releaseData.accompanyingNote}"
>
  <div>
    <div class="logo-wrap">
      <img src="" class="logo-img" title="Smart Invoice" width="200" height="60px" />
    </div>

    <div *ngIf="releaseData.error" style="margin: 160px 0; font-weight: bold; font-size: 14px; text-align: center">
      {{ releaseData.errorMsg }}
    </div>

    <div
      *ngIf="successMsg"
      style="margin: 160px 0; font-weight: bold; font-size: 14px; color: #005300; text-align: center"
    >
      <span [innerHtml]="successMsg"></span>
    </div>

    <div *ngIf="!releaseData.error && !successMsg">
      <h3>{{ releaseData.caseTitle }}</h3>

      <div class="field" *ngFor="let field of releaseData.fields">
        <div class="title">{{ field.title }}</div>
        <div class="value">{{ field.value }}</div>
      </div>
		<div class="field">
			<div class="title">Rechnungsbegleitzettel</div>
			<div class="value">
				<app-union-invoice-note
					[external]="true"
					[externalToken]="token"
					*ngIf="releaseData.accompanyingNote"
					[vm]="releaseData.noteVM"
				>
				</app-union-invoice-note>
			</div>
		</div>

		<div class="input-wrap" [ngClass]="{'invalid': !comment}" style="margin-top: 10px">
        <textarea
          placeholder="{{ (releaseData.noteVM ? 'EXTERNAL_RELEASE.NOTE_COMMENT_PLACEHOLDER' : 'EXTERNAL_RELEASE.COMMENT_PLACEHOLDER') | translate }}"
          id="{{ 'comment-' + tenant }}"
          name="{{ 'comment-' + tenant }}"
          [(ngModel)]="comment"
          required
		  minlength="5"
          [disabled]="submitting"
          (ngModelChange)="errorMessage = null"
          rows="5"
        ></textarea>
      </div>

		<div class="flex-column flex-align-items-center action-btns">
			<app-button *ngIf="!submitting" (buttonClicked)="print()" cssClass="btn-width mb-12"
			><i class="fa fa-print mr-2"></i>
				<span>{{ 'EXTERNAL_RELEASE.PRINT' | translate }}</span>
			</app-button>
			<p>Bitte drucken Sie sich den Rechnungsbegleitzettel für Ihre Unterlagen aus!</p>
		</div>
      <div class="field file-drop">
        <div class="title">Ihre Anhänge (bis zu 10)</div>

        <ol *ngIf="files.length" class="list-unstyled">
          <li *ngFor="let file of files; let i = index">
            <div class="fname">
              {{ file.fileName }} ({{ file.fileSize }})
              <app-button (click)="removeFile(i)" cssClass="btn-danger ml-12">
                <i class="fa fa-fw fa-trash"></i>
              </app-button>
            </div>
          </li>
        </ol>

        <app-file-drop (dropEvent)="upLoadFile($event)" accept=".pdf" [multiple]="true">
          <i class="fa fa-file-excel-o icon" aria-hidden="true"></i>
          <div class="sub-title">{{ 'UPLOAD.SUBTITLE_PDF' | translate }}</div>
        </app-file-drop>
      </div>
      <div class="flex-column flex-align-items-center action-btns">
		  <app-button [disabled]="!comment || submitting" (buttonClicked)="accept()" cssClass="btn-width mb-12"
		  ><i class="fa fa-spin fa-spinner ng-animate-disabled mr-2" *ngIf="submitting"></i>
			  <i class="fa fa-check mr-2" *ngIf="!submitting"></i>
			  <span *ngIf="!submitting">{{ 'EXTERNAL_RELEASE.RELEASE' | translate }}</span>
		  </app-button>
		  <app-button [disabled]="!comment || submitting" (buttonClicked)="decline()" cssClass="btn-width">
			  <i class="fa fa-spin fa-spinner ng-animate-disabled mr-2" *ngIf="submitting"></i>
			  <i class="fa fa-times mr-2" *ngIf="!submitting"></i>
			  <span *ngIf="!submitting">{{ 'EXTERNAL_RELEASE.DENY' | translate }}</span>
		  </app-button>
	  </div>
    </div>
  </div>
</div>
