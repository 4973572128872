import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
	selector: 'ng-template[appTab]'
})
export class TabItemDirective {
	@Input() tabId: string = '';
	@Input() tabLabel: string = '';
	@Input() tabBadge: string | number = '';
	@Input() tabLabelTemplate: TemplateRef<any> | undefined;
	@Input() tabBadgeClass: string = '';

	constructor(public template: TemplateRef<any>) {}
}
