export interface IButtonOption {
	cssClass?: string;
	type: OptionType;
	icon?: string;
	text?: string;
	visible?: boolean | (() => boolean);
	tooltip?: string;
	url?: string;
	isExternalUrl?: boolean;
	isTargetBlank?: boolean;
	customTemplate?: string;
	onActionClicked?: () => void;
}

export enum OptionType {
	Standard = 0,
	Header = 1,
	Divider = 2,
	Link = 3,
	ActionButton = 4,
	Custom = 5
}
