import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { DestroyService } from 'src/core/services/common/destroy.service';
import { takeUntil } from 'rxjs';
import { LANGUAGES } from '@app/models/index';
import { AuthService } from '@app/services/common/auth.service';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss'],
	providers: [DestroyService]
})
export class ForgotPasswordComponent implements OnInit {
	forgotForm?: FormGroup;
	languageNext?: string;
	isUnion: boolean;

	constructor(
		private fb: FormBuilder,
		private translateService: TranslateService,
		private translatePipe: TranslatePipe,
		private destroy$: DestroyService,
		public authService: AuthService
	) {}

	ngOnInit() {
		this.forgotForm = this.fb.group({
			username: ['', [Validators.required, Validators.email]]
		});

		this.languageNext = LANGUAGES[this.getNextLanguage()];

		this.isUnion = this.authService.isUnion;
	}

	getNextLanguage() {
		const langKeys = Object.keys(LANGUAGES);
		const currentIndex = langKeys.indexOf(this.translateService.currentLang);
		return currentIndex + 1 >= langKeys.length ? langKeys[0] : langKeys[currentIndex + 1];
	}

	state = {
		sending: false,
		sent: false,
		successMsg: '',
		error: ''
	};
	email: string;

	onReset() {
		if (this.forgotForm?.valid) {
			this.email = this.forgotForm.get('username')?.value;
			console.log(this.email);

			this.authService
				.forgotPassword(this.email)
				.pipe(takeUntil(this.destroy$))
				.subscribe(
					() => {
						this.state.sending = false;
						this.state.sent = true;
						let msgKey = 'FORGOT_PW.SUCCESS';
						if (this.isUnion) {
							msgKey = 'FORGOT_PW.SUCCESS_UNION';
						}
						this.state.successMsg = this.translatePipe.transform(msgKey);
						this.email = '';
					},
					() => {
						this.state.sending = false;
						this.state.error = this.translatePipe.transform('ERROR.GENERAL');
					}
				);
		}
	}

	changeLang() {
		const nextLanguage = this.getNextLanguage();
		this.translateService
			.use(nextLanguage)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => (this.languageNext = LANGUAGES[this.getNextLanguage()]));
	}

	get username() {
		return this.forgotForm?.get('username');
	}
}
