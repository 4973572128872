import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-pdf-previewer',
	templateUrl: './pdfpreviewer.component.html',
	styleUrls: ['./pdfpreviewer.component.scss']
})
export class PDFPreviewerComponent implements OnInit {
	@Input() imageSrc: string;
	@Input() size: any;
	@Input() position?: 'auto' | 'left';
	isAlignLeft = false;
	private _cursorX: number;
	get cursorX(): number {
		return this._cursorX;
	}
	@Input() set cursorX(value: number) {
		this._cursorX = value;
		this.updateAlign();
	}
	minWidth: number = 0;
	minHeight: number = 0;

	errorSrc = '/assets/img/no-image.png';
	isError: boolean;

	constructor() {
		this.isError = false;
	}

	error() {
		this.isError = true;
	}

	ngOnInit(): void {
		console.log(this.size);
		if (this.size) {
			const viewportH = window.innerHeight;
			const factor = this.size.width / this.size.height;
			const minHeight = this.size.height >= viewportH ? viewportH : this.size.height;
			const minWidth = Math.round(factor * viewportH);
			this.minHeight = minHeight;
			this.minWidth = minWidth;
		}
		this.updateAlign();
	}

	private updateAlign() {
		if (this.position === 'auto') {
			const docWidthHalf = document.body.clientWidth / 2;
			if (this._cursorX >= docWidthHalf) {
				this.isAlignLeft = true;
			}
		} else if (this.position === 'left') {
			this.isAlignLeft = true;
		}
	}
}
