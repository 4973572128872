/**
 * Enumerates the current tenant environment: <br>
 * 	 <i>UNDEFINED</i>, <br>
 *   <i>AMPEGA</i>, <br>
 *   <i>APLEONA</i>, <br>
 *   <i>DEMO</i>, <br>
 * 	 <i>DEKA</i>, <br>
 *   <i>STRABAG</i>, <br>
 *   <i>UNION</i>, <br>
 *   <i>VOELKEL</i>, <br>
 *   <i>DEKA</i>
 */
enum Tenant {
	UNDEFINED = 'UNDEFINED',
	AMPEGA = 'AMPEGA',
	APLEONA = 'APLEONA',
	DEKA = 'DEKA',
	DEMO = 'DEMO',
	STRABAG = 'STRABAG',
	UNION = 'UNION',
	VOELKEL = 'VOELKEL'
}

export { Tenant };
