import { EventEmitter, Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { TranslatePipe } from '@ngx-translate/core';
import { EuconErrorResponse } from '@app/models/responses/error/EuconErrorResponse';
import { ErrorCode } from '@app/models/responses/error/ErrorCode';

export interface Toast {
	title: string;
	msg: string;
	classname: string;
	delay: number;
}

export interface ToastOptions {
	added?: boolean;
	removed?: boolean;
	edit?: boolean;
	edited?: boolean;
	error?: string;
	cloned?: boolean;
	success?: string;
	label?: string;
	value?: string;
	caseData?: boolean;
	remove?: boolean;
	add?: boolean;
	assignment?: boolean;
	assignmentChanged?: boolean;
	assignmentPos?: boolean;
	corporateAssignment?: boolean;
	pmAssignment?: boolean;
	creditor?: boolean;
	creditorBank?: boolean;
	capturedCreditorBank?: boolean;
	position?: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class ToastService {
	toasts: Toast[] = [];
	public caseDataUpdated = new EventEmitter<any>();

	constructor(private translatePipe: TranslatePipe) {}

	success(title: string, msg: string) {
		this.toasts.push({
			title: title,
			msg: msg,
			classname: 'toast-success',
			delay: 2500
		});
	}

	error(title: string, msg: string, delay: number = 2500) {
		this.toasts.push({
			title: title,
			msg: msg,
			classname: 'toast-error',
			delay: delay
		});
	}

	remove(toast: Toast) {
		this.toasts = this.toasts.filter((t) => t !== toast);
	}

	toastify<T>(observable: Observable<T>, opts: ToastOptions = {}): Observable<T> {
		return observable.pipe(
			tap(
				(response) => {
					let titleKey = 'CRUD.SUCCESS';

					// if (opts.caseData && data) {
					//   $rootScope.$broadcast('caseDataUpdated', data);
					// }

					if (opts.added) {
						titleKey = 'CRUD.ADDED';
					} else if (opts.removed) {
						titleKey = 'CRUD.REMOVED';
					} else if (opts.edit) {
						titleKey = 'CRUD.EDITED';
					} else if (opts.cloned) {
						titleKey = 'CRUD.CLONED';
					}

					let msg = this.translatePipe.transform('CRUD.MSG.SUCCESS');

					if (opts.success) {
						msg = this.translatePipe.transform(opts.success);
					} else if (opts.edited) {
						if (opts.label && opts.value) {
							msg = this.translatePipe.transform('CRUD.MSG.FIELD.EDITED_WITH_LABEL_AND_LABEL', {
								label: opts.label,
								value: opts.value
							});
						} else if (opts.label) {
							msg = this.translatePipe.transform('CRUD.MSG.FIELD.EDITED_WITH_LABEL', {
								label: opts.label
							});
						} else {
							msg = this.translatePipe.transform('CRUD.MSG.FIELD.EDITED');
						}
					} else if (opts.removed) {
						if (opts.label) {
							msg = this.translatePipe.transform('CRUD.MSG.FIELD.REMOVED_WITH_LABEL', {
								label: opts.label
							});
						} else {
							msg = this.translatePipe.transform('CRUD.MSG.FIELD.REMOVED');
						}
					} else if (opts.added) {
						if (opts.label) {
							msg = this.translatePipe.transform('CRUD.MSG.FIELD.ADDED_WITH_LABEL', {
								label: opts.label
							});
						} else {
							msg = this.translatePipe.transform('CRUD.MSG.FIELD.ADDED');
						}
					}

					if (opts.caseData) {
						this.caseDataUpdated.emit(response);
					}

					this.success(this.translatePipe.transform(titleKey), msg);
				},
				(response: any) => {
					let msgKey: string = 'CRUD.MSG.ERROR.GENERAL';

					let errorCode: string;

					if (response.error && typeof response.error.errorCode != 'undefined') {
						errorCode = response.error.errorCode;
					} else if (typeof response.status != 'undefined') {
						errorCode = response.status;
					} else {
						errorCode = '';
					}

					if (response.error && typeof response.error.errorCode != 'undefined') {
						// get the error message
						msgKey = this.translatePipe.transform('ERROR.' + errorCode);

						//check the error more specifically
						// TODO: implement for all error types with ErrorArgs
						switch (errorCode) {
							case ErrorCode.VALIDATION_ERROR: {
								const errorArgs = response.error.errorArgs;
								for (const field in errorArgs) {
									// @ts-ignore
									let errorType: string = errorArgs[field];
									msgKey +=
										this.translatePipe.transform(`ERROR.VALIDATION_ERRORS.${errorType}`) +
										this.translatePipe.transform(`FIELDS.${field.toUpperCase()}`) +
										'. ';
								}
								break;
							}
							default: {
								break;
							}
						}
					} else {
						if (opts.error) {
							msgKey = opts.error;
						} else if (opts.added) {
							msgKey = 'CRUD.MSG.ERROR.ADDED';
						} else if (opts.removed) {
							msgKey = 'CRUD.MSG.ERROR.REMOVED';
						} else if (opts.cloned) {
							msgKey = 'CRUD.MSG.ERROR.CLONED';
						}
					}

					this.error(this.translatePipe.transform('CRUD.ERROR'), this.translatePipe.transform(msgKey));
				}
			)
		);
	}
}
