import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[euconTemplate]',
	exportAs: 'euconTemplate'
})
export class EuconTemplateDirective {
	@Input('euconTemplate') name: string;

	constructor(public templateRef: TemplateRef<any>) {}
}
