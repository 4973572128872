<button class="btn btn-primary {{cssClass}}"
        [class.btn-lighter]="lighter"
        [class.btn-danger]="danger"
        [disabled]="disabled"
        [class.float-right]="pullRight"
        [ngClass]="size"
        (click)="onActionClicked()"
        ngbTooltip="{{tooltip | translate}}" placement="bottom">
  <i class="fa fa-fw" *ngIf="icon" [ngClass]='icon'></i>
  {{ label }}
  <ng-content></ng-content>
</button>
