import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export type size = 'btn-sm' | 'btn-lg';
@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
	@Input() label: string;
	@Input() icon: string;
	@Input() lighter: boolean;
	@Input() disabled: boolean;
	@Input() danger: boolean;
	@Input() pullRight: boolean;
	@Input() size: size;
	@Input() cssClass: string;

	@Output() buttonClicked = new EventEmitter();
	@Input() tooltip: string = '';

	constructor() {}

	ngOnInit(): void {}

	onActionClicked() {
		this.buttonClicked.emit();
	}
}
