import { DeploymentTier } from '@app/models/constants/deployment-tiers';
import { Tenant } from '@app/models/constants/tenants';
import { tenantEnvironment } from 'src/environments/environment.common';

export const environment = tenantEnvironment({
	deploymentTier: DeploymentTier.STAGING,
	tenant: Tenant.AMPEGA,
	systemVersion: 'TEST V2',
	systemVersionColor: 'red',
	title: '[TEST] eFlow',
	backgroundImagePath: '/assets/img/tenants/ampega/bg-test.jpg',
	baseUrl: 'https://eflow-test.eucon.com/backend/api',
	featureConfig: {
		oldFrontendRedirect: { url: 'https://ampega-staging.frontend.dev-smartinvoice.eucon.com/' },
		sapBookingStatus: true,
		accountAssignmentScreenshot: true,
		hotjar: true
	}
});
