import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { userDataKey, tokenDataKey, searchOptionsLiveSearch, searchOptionsViewMode } from '@app/models/index';
import { User } from '@app/models/dto/common/auth.info..dto';
import { CaseSearchOptions } from 'src/app/smart-invoice/case-search/case-search-options.model';

@Injectable({
	providedIn: 'root'
})
export class EuconStorageService {
	constructor(private localStorageService: LocalStorageService) {}

	saveUser(user: User) {
		this.localStorageService.set(userDataKey, user);
	}
	getUser() {
		return this.localStorageService.get(userDataKey);
	}
	saveToken(token: string) {
		this.localStorageService.set(tokenDataKey, token);
	}
	getToken() {
		return this.localStorageService.get(tokenDataKey);
	}
	getData(field: string) {
		return <any>this.localStorageService.get(field);
	}

	setData(field: string, data: any) {
		this.localStorageService.set(field, data);
	}

	removeData(field: string) {
		this.localStorageService.remove(field);
	}

	setSearchOptions(obj: CaseSearchOptions) {
		this.setData(searchOptionsViewMode, obj.viewMode);
		this.setData(searchOptionsLiveSearch, obj.liveSearch);
	}

	getSearchOptions(): CaseSearchOptions {
		return {
			viewMode: this.getData(searchOptionsViewMode) || 'table',
			liveSearch: this.getData(searchOptionsLiveSearch) === true
		};
	}
}
