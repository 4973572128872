import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {
	transform(value: number): number {
		return parseFloat(value.toFixed(3));
	}
}
