<div class="login" [style]="'background-image:url(' + environment.backgroundImagePath + ')'">
	<div class="login-box">
		<div class="login-logo">
			<img
				*ngIf="authService.isVoelkel; else otherImg"
				src=""
				class="logo-img"
				alt=""
				title="Smart Invoice"
				height="60"
			/>
			<ng-template #otherImg>
				<img src="" class="logo-img" title="Smart Invoice" width="200" height="60px" alt="" />
			</ng-template>
		</div>
		<form *ngIf="loginForm && !state.loggedIn" [formGroup]="loginForm" (ngSubmit)="onLogin()" class="login-form">
			<div class="input-group-field">
				<input
					type="email"
					class="login-input"
					[placeholder]="'LOGIN.EMAIL' | translate"
					formControlName="username"
				/>
				<i class="fa fa-user login-icon"></i>
			</div>

			<div class="input-group-field no-margin">
				<i class="fa fa-key login-icon"></i>
				<input
					type="password"
					class="login-input"
					[placeholder]="'LOGIN.PASSWORD' | translate"
					formControlName="password"
				/>
			</div>

			<div class="input-group-checkbox">
				<label class="checkbox-label" for="rememberCheckbox">
					<input type="checkbox" class="checkbox" id="rememberCheckbox" formControlName="rememberEmail" />
					{{ 'LOGIN.SAVE_EMAIL' | translate }}
				</label>
			</div>

			<button [disabled]="!loginForm.valid" type="submit" class="btn btn-primary">
				{{ 'LOGIN.LOGIN' | translate }}
			</button>

			<a routerLink="/forgot-password">{{ 'LOGIN.FORGOT_PW' | translate }}</a>

			<div class="error">
				<span *ngIf="state.errorMessage">
					<i class="fa fa-exclamation-triangle"></i>
					<span [innerHTML]="state.errorMessage | safeHTML"></span>
				</span>
			</div>

			<div *ngIf="languageNext === 'English'" class="lang">
				<button class="btn-lang" (click)="changeLang()"><span class="lang-icon">EN</span>English?</button>
			</div>
			<div *ngIf="languageNext === 'Deutsch'" class="lang">
				<button class="btn-lang" (click)="changeLang()"><span class="lang-icon">DE</span>Deutsch?</button>
			</div>
		</form>
		<div *ngIf="state.loggedIn" class="logged-in-wrap">
			<div class="icon"><i class="fa fa-check"></i></div>
			<div class="msg">{{ 'LOGIN.SUCCESS' | translate }}</div>
			<div class="loading-inbox">
				<i class="fa fa-spin fa-spinner ng-animate-disabled"></i>
				{{ 'LOGIN.LOADING_INBOX' | translate }}
			</div>
		</div>
	</div>
</div>
