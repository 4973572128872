<div
	*ngIf="label"
	class="label text-truncate"
	[class.disabled]="readonly"
	[class.invalid]="hasErrors"
	[class.warning]="warning"
	[ngClass]="{ 'active-filter-field': this.value && this.filterInput }"
>
	{{ label }}
	<span *ngIf="required" class="mandatory">*</span>
	<ng-content></ng-content>
</div>

<div
	class="position-relative input-content d-flex"
	[class.readonly]="readonly"
	[class.invalid]="hasErrors"
	[ngClass]="fieldInfo?.classes"
	ngbDropdown
	[ngbTooltip]="tooltip"
	triggers="hover"
	container="body"
	[tooltipClass]="hasErrors ? 'invalid' : warning ? 'warning' : readonly ? 'readonly' : ''"
	[placement]="tooltipPlacement || 'bottom bottom-left'"
>
	<input
		*ngIf="fieldInfoType !== 'TEXT_AREA'"
		#input
		ngbDropdownToggle
		[disabled]="disabled || readonly"
		[min]="minValue"
		[attr.placeholder]="placeholder"
		[value]="value === undefined || value === null ? '' : value"
		[style.width]="width ? width + 'px' : '100%'"
		[readonly]="readonly"
		[attr.maxlength]="maxlength"
		[type]="type"
		[class.changed]="changed"
		(input)="handleInput($event)"
		(keydown)="onKeyDown($event)"
		(focus)="onFocus($event)"
		(blur)="onBlur($event)"
		(drop)="onDrop()"
	/>

	<textarea
		#textAreaElement
		*ngIf="fieldInfoType === 'TEXT_AREA'"
		appTextareaAutoresize
		[disabled]="disabled || readonly"
		[attr.placeholder]="placeholder"
		[value]="value === undefined || value === null ? '' : value"
		[style.width]="width ? width + 'px' : '100%'"
		[readonly]="readonly"
		[attr.maxlength]="maxlength"
		[class.changed]="changed"
		(input)="handleInput($event)"
		(keydown)="onKeyDown($event)"
		(focus)="onFocus($event)"
		(blur)="onBlur($event)"
		(drop)="onDrop()"
		[rows]="1"
	></textarea>

	<div class="btn-search" *ngIf="autocomplete && lookupSAP && dataSAP?.creditor">
		<app-button
			size="btn-sm"
			icon="fa-search"
			(buttonClicked)="openSAPCreditorSearchDialog()"
			[label]="btnLabel"
		></app-button>
	</div>

	<div class="btn-search" *ngIf="autocomplete && lookupSAP && dataSAP?.debitor">
		<app-button
			size="btn-sm"
			icon="fa-search"
			(buttonClicked)="openSAPDebitorSearchDialog()"
			[label]="btnLabel"
		></app-button>
	</div>

	<div class="btn-search" *ngIf="autocomplete && fieldInfo?.dialog && !readonly">
		<app-button class="btn-search" size="btn-sm" icon="fa-search" (buttonClicked)="openDialog()"></app-button>
	</div>
	<div class="btn-search" *ngIf="lookup">
		<app-button
			size="btn-sm"
			icon="fa-search"
			(buttonClicked)="openLookupDialog(baseModal)"
			[label]="btnLabel"
		></app-button>
	</div>

	<ng-template #noDropdown>
		<div ngbDropdownMenu style="display: none"></div>
	</ng-template>

	<div
		ngbDropdownMenu
		*ngIf="(autocomplete || autocompleteText || choices || options) && !readonly; else noDropdown"
		class="auto-complete-container"
	>
		<div *ngIf="(options || choices || dataSource) && !value && autocompleteHeader" class="autocomplete-header">
			{{ autocompleteHeader }}
		</div>
		<div
			*ngIf="value && !onlySpaces(value) && choices?.length != 0"
			class="autocomplete-header"
			[translate]="'AUTOCOMPLETE.RESULTS'"
			[translateParams]="{ needle: value }"
		></div>
		<div *ngIf="dataSource; else choices ? choicesBlock : elseBlock">
			<div class="auto-complete-results-ds">
				<div
					class="autocomplete-option-ds"
					[class.selected]="i === selectedIndex"
					*ngFor="let item of data.items | slice: 0:numberOfRows; let i = index"
					ngbDropdownItem
					(click)="handleSelectDataItem(item)"
				>
					<ng-container
						*ngTemplateOutlet="
							dataSourceTemplate;
							context: { item: item, valueKey: autocompleteOptionValueKey }
						"
					>
					</ng-container>
				</div>
			</div>
			<span class="has-more creditor" *ngIf="showMore" translate (click)="handleMoreSelect()">{{
				lastItemInfo
			}}</span>
		</div>
		<ng-template #choicesBlock>
			<table *ngIf="fieldInfo.tableLayout" class="auto-complete-results">
				<tr
					*ngFor="let choice of choices; let i = index"
					ngbDropdownItem
					class="item"
					[ngClass]="choice.cssClasses"
					[class.auto-complete-item-selected]="
						this.fieldInfo.selectValue?.displayTitle === choice[autocompleteOptionValueKey] ||
						this.fieldInfo.textValue?.displayValue === choice[autocompleteOptionValueKey]
					"
					[class.selected]="i === selectedIndex"
					(click)="changeValue(choice.id, choice.displayTitle)"
				>
					<td *ngFor="let row of choice.rows">
						<div *ngIf="choice.html" [innerHTML]="row"></div>
						<div *ngIf="!choice.html">{{ row }}</div>
					</td>
				</tr>
				<tr *ngIf="lastItemInfo" class="last-item-row" [ngClass]="lastItemClasses">
					<td [colSpan]="colsLength">
						<div class="last-item" [innerHTML]="lastItemInfo"></div>
					</td>
				</tr>
			</table>
			<ul *ngIf="!fieldInfo.tableLayout" class="auto-complete-results">
				<li
					*ngFor="let choice of choices; let i = index"
					ngbDropdownItem
					class="item"
					[ngClass]="choice.cssClasses"
					[class.auto-complete-item-selected]="
						this.fieldInfo.selectValue?.displayTitle === choice[autocompleteOptionValueKey] ||
						this.fieldInfo.textValue?.displayValue === choice[autocompleteOptionValueKey]
					"
					[class.selected]="i === selectedIndex"
					(click)="changeValue(choice.id, choice.displayTitle, useTitle())"
				>
					<div *ngIf="choice.html" [innerHTML]="choice.extendedTitle"></div>
					<div *ngIf="!choice.html">{{ choice.extendedTitle }}</div>
				</li>
			</ul>
		</ng-template>
		<ng-template #elseBlock>
			<div
				*ngFor="let option of options"
				ngbDropdownItem
				class="autocomplete-option"
				(click)="handleSelect(option[autocompleteOptionValueKey])"
			>
				<ng-template #defaultOptionTemplate>
					<span class="dropdown-item-col">{{ option[autocompleteOptionLabelKey] }}</span>
				</ng-template>
				<ng-container
					*ngTemplateOutlet="
						autocompleteOptionTemplate || defaultOptionTemplate;
						context: { option: option, valueKey: autocompleteOptionValueKey }
					"
				>
				</ng-container>
			</div>
		</ng-template>
	</div>
</div>

<div *ngIf="fieldInfo?.compareValue" class="compare-value">
	<span>{{ fieldInfo.compareValue }}</span>
</div>

<ng-template #dataSourceTemplate let-item="item">
	<span class="autocomplete-option-col-ds" *ngFor="let col of item.rows" [innerHTML]="col"></span>
</ng-template>

<ng-template #choiceTemplate let-option="option">
	<span class="dropdown-item-col">{{ option.html }}</span>
</ng-template>

<ng-template #baseModal let-modal>
	<div class="overlay">
		<div class="overlay-wrap overlay-wrap-wide">
			<div class="title-wrap">
				<span>{{ modalTitle }}</span>
			</div>
			<div class="overlay-content email-wrap">
				<app-table [columns]="gridColumns" [dataSource]="dataSource"> </app-table>
			</div>
			<div class="btns-wrap">
				<app-button
					icon="fa-close"
					(buttonClicked)="modal.close()"
					[label]="'CLOSE' | translate"
					icon="fa fa-close fa-fw"
					cssClass="w-100"
				>
				</app-button>
			</div>
		</div>
	</div>
</ng-template>
