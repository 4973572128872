import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastService } from '@app/services/common/toast.service';
import { BaseApiService } from '../../../core/api/base.api.service';
import { CaseVM, UnionInvoiceNoteVM } from '../../../app/smart-invoice/case/models/case.model';
import { Observable } from 'rxjs';

@Injectable()
export class UnionInvoiceNoteApiService extends BaseApiService {
	constructor(http: HttpClient, private toastService: ToastService) {
		super(http);
	}

	enableOrDisableUnionInvoiceNote(id: number): Observable<CaseVM> {
		return this.toastService.toastify(
			this.http.post<CaseVM>(`${this.baseUrl}/union-note/${id}/enable-or-disable`, null),
			{
				caseData: true,
				edited: true
			}
		);
	}

	deleteUnionInvoiceNote(id: number): Observable<CaseVM> {
		return this.toastService.toastify(this.http.delete<CaseVM>(`${this.baseUrl}/union-note/${id}/delete-note`), {
			caseData: true,
			edited: true
		});
	}

	setLastUploadedAttachment(id: number): Observable<CaseVM> {
		return this.toastService.toastify(
			this.http.post<CaseVM>(`${this.baseUrl}/union-note/${id}/set-last-uploaded-attachment`, null),
			{
				caseData: true,
				edited: true
			}
		);
	}

	createAccompanyingNoteManually(assignmentID: number): Observable<CaseVM> {
		return this.toastService.toastify(
			this.http.post<CaseVM>(`${this.baseUrl}/union-note/${assignmentID}/create-manually`, null),
			{
				caseData: true,
				edit: true,
				assignment: true,
				assignmentChanged: true
			}
		);
	}

	editField(noteID: string, property: string, newVal: string) {
		return this.toastService.toastify(
			this.http.patch(`${this.baseUrl}/union-note/${noteID}/edit-field`, { property, value: newVal }),
			{
				caseData: true,
				edited: true,
				assignment: true
			}
		);
	}

	editFieldByExternalUser(
		noteID: string,
		property: string,
		newVal: string,
		externalKey: string
	): Observable<UnionInvoiceNoteVM> {
		return this.toastService.toastify(
			this.http.patch<UnionInvoiceNoteVM>(
				`${this.baseUrl}/external-release/${externalKey}/note/${noteID}/edit-field`,
				{
					property,
					value: newVal
				}
			),
			{
				caseData: true,
				edited: true,
				assignment: false
			}
		);
	}
}
