<div *ngIf="modalOpen" @modalInOut class="smivl-modal gap-3 {{ size }}">
	<div class="smivl-modal-header">
		<span>{{ title | translate }}</span>
		<i
			(click)="cancel()"
			class="fa fa-times pointer align-self-start icon-animation text-muted"
			*ngIf="cancelable"
		></i>
	</div>
	<hr />
	<div class="smivl-modal-content" [class]="{ 'overflow-hidden': overflow === 'hidden' }">
		<ng-container *ngIf="modalBodyTemplate" [ngTemplateOutlet]="modalBodyTemplate"></ng-container>
		<span *ngIf="textContent">{{ textContent | translate }}</span>
	</div>
	<hr />
	<div class="smivl-modal-footer">
		<app-button *ngIf="cancelable" (click)="cancel()">
			{{ cancelLabel ?? 'modal.cancel' | translate }}
		</app-button>
		<app-button *ngIf="actionLabel" [disabled]="(canAction$ | async) === false" (click)="handleAction()">
			{{ actionLabel | translate }}
		</app-button>
		<app-button *ngIf="submittable" [disabled]="(canSubmit$ | async) === false" (click)="submit()">
			{{ submitLabel ?? 'modal.submit' | translate }}
		</app-button>
	</div>
</div>

<div
	*ngIf="modalOpen"
	@modalBgInOut
	(@modalBgInOut.done)="animationDone($event)"
	class="smivl-modal-backdrop"
	(click)="cancelable && cancel()"
></div>
