export enum LANGUAGE_KEY {
	EN = 'en',
	DE = 'de'
}

/**
 * Languages string key - plain text value <br>
 * <i>e.g. "en": "English"</i>
 */
export const LANGUAGES: { [key: string]: string } = { en: 'English', de: 'Deutsch' };
