import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-page-title-action-button',
	templateUrl: './page-title-action-button.component.html',
	styleUrls: ['./page-title-action-button.component.scss']
})
export class PageTitleActionButtonComponent {
	@Input() isActive = false;
	@Input() iconClasses: string = '';
	@Input() btnClasses: string = 'primaryColor';
	@Input() text: string = '';
	@Input() disabled = false;
	@Output() clickEvent = new EventEmitter();

	constructor() {}
}
