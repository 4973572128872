import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class BaseApiService {
	constructor(protected http: HttpClient) {
		this._baseUrl = environment.baseUrl;
	}
	private _baseUrl: string = '';
	get baseUrl(): string {
		return this._baseUrl;
	}

	get viewport() {
		return {
			vh: window.innerHeight,
			vw: window.innerWidth
		};
	}

	fetchList<T>(url: string, params: any) {
		return this.http.get<T>(`${this.baseUrl}/${url}`, { observe: 'response', params }).pipe(
			map((res) => ({
				data: res.body,
				totalCount: parseInt(res.headers.get('X-Total-Count')!),
				currentPage: parseInt(res.headers.get('X-Current-Page')!),
				totalPages: parseInt(res.headers.get('X-Total-Pages')!)
			}))
		);
	}
}
