import { Component, Input, OnInit } from '@angular/core';
import { InboxComponent } from 'src/app/smart-invoice/inbox/inbox.component';

@Component({
	selector: 'app-page-title',
	templateUrl: './page-title.component.html',
	styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit {
	@Input() iconClasses: string = '';
	@Input() text: string = '';
	@Input() breadcrumbs: any[];
	@Input() inboxComponent: InboxComponent;

	constructor() {}

	ngOnInit(): void {}

	refresh() {
		if (this.inboxComponent) {
			this.inboxComponent.clearAndRefresh();
		}
	}
}
