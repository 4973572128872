import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from 'src/core/api/base.api.service';
import { ExternalReleaseVM } from './external-release.component';

@Injectable()
export class ExternalReleaseService extends BaseApiService {
	constructor(http: HttpClient) {
		super(http);
	}

	getExternalReleaseData(token: string | null): Observable<ExternalReleaseVM> {
		return this.http.get<ExternalReleaseVM>(`${this.baseUrl}/external-release/${token}`);
	}

	acceptExternalLink(token: string | null, comment: string, uploadedFiles: any[]): Observable<string> {
		return this.http.post(
			`${this.baseUrl}/external-release/${token}/accept`,
			{
				comment,
				uploadedFiles
			},
			{
				responseType: 'text'
			}
		);
	}

	denyExternalLink(token: string | null, comment: string, uploadedFiles: any[]): Observable<string> {
		return this.http.post(
			`${this.baseUrl}/external-release/${token}/decline`,
			{
				comment,
				uploadedFiles
			},
			{
				responseType: 'text'
			}
		);
	}
}
