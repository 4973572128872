import { Component, Input } from '@angular/core';
import { BaseFormControl } from '@app/shared/components/base-form-control/base-form-control';

@Component({
	selector: 'app-table-check-box',
	templateUrl: './table-check-box.component.html',
	styleUrls: ['./table-check-box.component.scss']
})
export class TableCheckBoxComponent extends BaseFormControl<string, HTMLInputElement> {
	@Input() checked: boolean = false;
	@Input() badgeClass: string;
	@Input() inverded: boolean = false;
}
