<div
  [style.min-width.px]="minWidth"
  [style.min-height.px]="minHeight"
  *ngIf="!isError"
  class="wrapper"
  [class.align-left]="isAlignLeft"
>
  <img (error)="error()" [src]="imageSrc" />
</div>
<div
  [style.min-width.px]="minWidth"
  [style.min-height.px]="minHeight"
  class="wrapper-error"
  *ngIf="isError"
  [class.align-left]="isAlignLeft"
>
  <img [src]="errorSrc" />
</div>
