import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UnionInvoiceNoteVM } from '../../../app/smart-invoice/case/models/case.model';
import { UnionInvoiceNoteApiService } from '@app/shared/components/union-invoice-note/union-invoice-note.api.service';
import { FileDto } from '@app/models/dto/common/file.dto';
import { TranslatePipe } from '@ngx-translate/core';
import { FileDataService } from '@app/services/data/file.service';
import { AuthService } from '@app/services/common/auth.service';
import { CaseApiService } from '../../../core/api/case.api.service';

@Component({
	selector: 'app-union-invoice-note',
	templateUrl: './union-invoice-note.component.html',
	styleUrls: ['./union-invoice-note.component.scss']
})
export class UnionInvoiceNoteComponent implements OnInit, OnChanges {
	@Input() vm: UnionInvoiceNoteVM;
	/**
	 * If set to true, indicates that fields in this form can be edited by an external (unauthenticated) user
	 */
	@Input() external: boolean = false;
	/**
	 * The external token which identifies the external user / part of the app
	 */
	@Input() externalToken: string | null = null;

	@ViewChild('fileInput') fileInput: ElementRef;
	file: FileDto = new FileDto();
	pdfURL: string | null;

	constructor(
		private translatePipe: TranslatePipe,
		private apiService: UnionInvoiceNoteApiService,
		private fileService: FileDataService,
		private caseService: CaseApiService,
		private authService: AuthService
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['vm'] && changes['vm'].currentValue && !this.external) {
			let currentValue: UnionInvoiceNoteVM = changes['vm'].currentValue as unknown as UnionInvoiceNoteVM;
			this.calcPDFURL(currentValue.note.uploadedNotePDFID);
		}
	}

	get uploadButtonLabel() {
		return this.file.isProgress
			? `${this.file.progress} %`
			: this.translatePipe.transform('ATTACHMENTS.UPLOAD_ACCOMPANYING_NOTE');
	}

	enabledOrDisable() {
		this.apiService.enableOrDisableUnionInvoiceNote(this.vm.note.id).subscribe(() => {});
	}

	deleteNote() {
		this.apiService.deleteUnionInvoiceNote(this.vm.note.id).subscribe(() => {});
	}

	uploadFile(event: Event) {
		const element = event.currentTarget as HTMLInputElement;
		let file: File | null | undefined = element.files?.item(0);
		if (!file) {
			return;
		}
		this.fileService
			.uploadAdditional(file, this.vm.note.caseInstanceID, 'Rechnungsbegleitzettel')
			.subscribe((x) => {
				this.file = x;
				if (this.file.result) {
					this.apiService.setLastUploadedAttachment(this.vm.note.id).subscribe(() => {});
					this.fileInput.nativeElement.value = '';
				}
			});
	}

	openFileSelect() {
		this.fileInput.nativeElement.click();
	}

	ngOnInit(): void {
		if (!this.external) {
			this.calcPDFURL(this.vm.note.uploadedNotePDFID);
		}
	}

	calcPDFURL(noteID: number | null) {
		this.pdfURL = noteID ? this.caseService.getPDFUrl(noteID, this.authService.getToken()) : null;
	}
}
