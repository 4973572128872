<div class="editable-block i-block">
  <div class="edit-text-block-label" [ngClass]="{'disabled': readonly}" *ngIf="label">
    {{ label }}
  </div>
  <div
    class="checkbox-wrap"
    [ngbTooltip]="tooltip"
    [tooltipClass]="hasErrors ? 'invalid' : warning ? 'warning' : readonly ? 'readonly' : ''"
    [placement]="tooltipPlacement || 'bottom bottom-left'"
  >
    <input #input [id]="id" type="checkbox" [disabled]="readonly" [checked]="checked" (change)="onActionToggle()" />
    <label [for]="id" class="far">
      <span>{{ text }}</span>
    </label>
  </div>
</div>
