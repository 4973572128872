<div class="login">
	<div class="login-box">
		<div class="login-logo">
			<img src="" class="logo-img" alt="" title="Smart Invoice" width="200" height="60px" />
		</div>

		<form
			*ngIf="passwordForm && !state.sent"
			[formGroup]="passwordForm"
			(ngSubmit)="onResetPassword()"
			class="login-form"
		>
			<div class="login-title">
				<p class="login-text">{{ 'RESET_PW.TITLE' | translate }}</p>
			</div>

			<div class="input-group-field no-margin">
				<input
					#password
					type="password"
					class="login-input"
					[placeholder]="'RESET_PW.PLACEHOLDER' | translate"
					formControlName="password"
					(change)="state.error = ''"
					minlength="8"
					(keyup)="updateRequirements(password.value)"
				/>
			</div>
			<br />
			<div class="page-title no-hor-margin no-padding">
				<h2>{{ 'RESET_PW.REQUIREMENTS' | translate }}</h2>
			</div>

			<ul class="password-rules list-unstyled">
				<li [ngClass]="{ valid: rules.TOO_SHORT, invalid: !rules.TOO_SHORT }">
					<i
						class="fa fa-fw icon"
						[ngClass]="{ 'fa-check': rules.TOO_SHORT, 'fa-close': !rules.TOO_SHORT }"
					></i>
					<span class="text">{{ 'RESET_PW.REQUIREMENT.MIN8CHARS' | translate }}</span>
				</li>
				<li
					[ngClass]="{
						valid: rules.INSUFFICIENT_CHARACTERISTICS,
						invalid: !rules.INSUFFICIENT_CHARACTERISTICS
					}"
				>
					<i
						class="fa fa-fw icon"
						[ngClass]="{
							'fa-check': rules.INSUFFICIENT_CHARACTERISTICS,
							'fa-close': !rules.INSUFFICIENT_CHARACTERISTICS
						}"
					></i>
					<span class="text">{{ 'RESET_PW.REQUIREMENT.THREE_OF4CHAR_RULES' | translate }}</span>
				</li>
				<li
					class="sub-rule"
					[ngClass]="{ valid: rules.INSUFFICIENT_DIGIT, invalid: !rules.INSUFFICIENT_DIGIT }"
				>
					<i
						class="fa fa-fw icon"
						[ngClass]="{
							'fa-check': rules.INSUFFICIENT_DIGIT,
							'fa-close': !rules.INSUFFICIENT_DIGIT
						}"
					></i>
					<span class="text">{{ 'RESET_PW.REQUIREMENT.DIGITS' | translate }}</span>
				</li>
				<li
					class="sub-rule"
					[ngClass]="{ valid: rules.INSUFFICIENT_SPECIAL, invalid: !rules.INSUFFICIENT_SPECIAL }"
				>
					<i
						class="fa fa-fw icon"
						[ngClass]="{
							'fa-check': rules.INSUFFICIENT_SPECIAL,
							'fa-close': !rules.INSUFFICIENT_SPECIAL
						}"
					></i>
					<span class="text">{{ 'RESET_PW.REQUIREMENT.NON_ALPHA_NUM' | translate }}</span>
				</li>
				<li
					class="sub-rule"
					[ngClass]="{ valid: rules.INSUFFICIENT_UPPERCASE, invalid: !rules.INSUFFICIENT_UPPERCASE }"
				>
					<i
						class="fa fa-fw icon"
						[ngClass]="{
							'fa-check': rules.INSUFFICIENT_UPPERCASE,
							'fa-close': !rules.INSUFFICIENT_UPPERCASE
						}"
					></i>
					<span class="text">{{ 'RESET_PW.REQUIREMENT.CAPS' | translate }}</span>
				</li>
				<li
					class="sub-rule"
					[ngClass]="{ valid: rules.INSUFFICIENT_LOWERCASE, invalid: !rules.INSUFFICIENT_LOWERCASE }"
				>
					<i
						class="fa fa-fw icon"
						[ngClass]="{
							'fa-check': rules.INSUFFICIENT_LOWERCASE,
							'fa-close': !rules.INSUFFICIENT_LOWERCASE
						}"
					></i>
					<span class="text">{{ 'RESET_PW.REQUIREMENT.LOWER_CASE_LETTERS' | translate }}</span>
				</li>
				<li [ngClass]="{ valid: rules.ILLEGAL_WORD, maybe: !rules.ILLEGAL_WORD }">
					<i
						class="fa fa-fw icon"
						[ngClass]="{ 'fa-check': rules.ILLEGAL_WORD, 'fa-question': !rules.ILLEGAL_WORD }"
					></i>
					<span class="text">{{ 'RESET_PW.REQUIREMENT.NO_EMAIL' | translate }}</span>
				</li>
				<li [ngClass]="{ valid: rules.USED_BEFORE, maybe: !rules.USED_BEFORE }">
					<i
						class="fa fa-fw icon"
						[ngClass]="{ 'fa-check': rules.USED_BEFORE, 'fa-question': !rules.USED_BEFORE }"
					></i>
					<span class="text">{{ 'RESET_PW.REQUIREMENT.NOT_USED_BEFORE' | translate }}</span>
				</li>
			</ul>

			<button [disabled]="!passwordForm.valid" type="submit" class="btn btn-primary">
				{{ 'RESET_PW.SET' | translate }}
			</button>

			<a routerLink="/login">{{ 'FORGOT_PW.BACK_TO_LOGIN' | translate }}</a>

			<div class="error pre-ws">
				<span *ngIf="state.error">{{ state.error }}</span>
			</div>

			<div class="error-field"></div>
		</form>
		<div *ngIf="state.sent" class="success-wrap">
			<div class="icon"><i class="fa fa-check"></i></div>
			<div class="msg" [innerHTML]="state.successMsg"></div>
			<a href="/login">{{ 'FORGOT_PW.BACK_TO_LOGIN' | translate }}</a>
		</div>
		<div *ngIf="languageNext === 'English'" class="lang">
			<button class="btn-lang" (click)="changeLang()"><span class="lang-icon">EN</span>English?</button>
		</div>
		<div *ngIf="languageNext === 'Deutsch'" class="lang">
			<button class="btn-lang" (click)="changeLang()"><span class="lang-icon">DE</span>Deutsch?</button>
		</div>
	</div>
</div>
