import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CustomGridParams } from '@app/models/components/table/grid.datasource';
import { SortDescriptor } from 'devextreme/data';
import { map, Observable } from 'rxjs';

export class QueryParams {
	pageIndex: number = 0;
	pageSize: number = 15;
	columns: any[] = [];
	customFilters: CustomGridParams[] = [];
	customSort: SortDescriptor<any>;
}

@Injectable()
export class TableService {
	constructor(private router: Router, private route: ActivatedRoute) {}

	getQueryParams(columns: any[]): Observable<QueryParams> {
		return this.route.queryParams.pipe(
			map((params: Params) => {
				const queryPatams = new QueryParams();
				if (params['page']) queryPatams.pageIndex = +params['page'];
				if (params['size']) queryPatams.pageSize = +params['size'];
				const sort: any = params['sort'];
				if (sort) {
					columns.forEach((c) => (c.sortOrder = null));
					const colName = sort.split(',')[0];
					const order = sort.split(',')[1];
					const column = columns.find((c) => c.dataField === colName);
					if (column) {
						column.sortOrder = order;
					} else {
						queryPatams.customSort = { selector: colName, desc: order === 'desc' };
					}
				}
				columns.forEach((c) => {
					const filterValue = params[c.dataField];
					c.filterValue = filterValue == null || filterValue === '' ? c.filterDefaultValue : filterValue;
					if (c.headerCellTemplate === 'betweenDate') {
						let from = c.entries.find((x: string) => x.includes('From'));
						let to = c.entries.find((x: string) => x.includes('To'));
						c.filterValueFrom = params[from];
						c.filterValueTo = params[to];
					}
				});
				queryPatams.columns = columns;

				queryPatams.customFilters = Object.keys(params)
					.filter((key: string) => key !== 'page' && key !== 'size' && key !== 'sort')
					.filter((key: string) => !columns.find((c) => c.name === key && c.allowFiltering !== false))
					.map((key: string) => ({ name: key, value: params[key] }));
				return queryPatams;
			})
		);
	}

	setQueryParams(state: any, customFilters?: CustomGridParams[]) {
		const columns: { dataField: string; sortOrder: string; filterValue: any }[] = state.columns;
		const sorted = columns?.find((c: any) => c.sortOrder);
		const params: any = {
			page: state.pageIndex,
			sort: sorted ? sorted.dataField + ',' + sorted.sortOrder : undefined,
			size: state.pageSize
		};
		columns
			.filter((c) => c.filterValue != null && c.filterValue !== '')
			.forEach((c) => {
				params[c.dataField] = c.filterValue;
			});
		if (customFilters) {
			customFilters.forEach((f) => (params[f.name] = f.value));
		}
		this.router.navigate([], { queryParams: params });
	}
}
