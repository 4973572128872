import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { NavigationService } from '@app/services/common/navigation.service';
import { EuconStorageService } from '@app/services/common/eucon-storage.service';
import { ModalService } from '@app/services/common/modal.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	@ViewChild('hotjarConsent') hotjarConsent!: TemplateRef<HTMLElement>;

	constructor(
		private translateService: TranslateService,
		private titleService: Title,
		private _navigationService: NavigationService,
		private euconStorageService: EuconStorageService,
		private modalService: ModalService
	) {}

	ngOnInit(): void {
		this.translateService.addLangs(['en', 'de']);
		let browserDefaultLocale = this.translateService.getBrowserLang();
		let startupLanguage;

		if (this.euconStorageService.getData('language')) {
			startupLanguage = this.euconStorageService.getData('language');
		} else if (browserDefaultLocale && this.translateService.langs.includes(browserDefaultLocale)) {
			startupLanguage = browserDefaultLocale;
		} else {
			startupLanguage = environment.defaultLocale;
		}

		this.translateService.use(startupLanguage);
		this.titleService.setTitle(environment.title);

		if (environment.featureConfig.hotjar) {
			this.checkForHotjar();
		}
	}

	private checkForHotjar() {
		const isHotjarAllowed = this.euconStorageService.getData('allowHotjar');
		if (isHotjarAllowed === false) return;

		if (isHotjarAllowed == undefined) {
			setTimeout(() => {
				this.modalService
					.open(this.hotjarConsent, {
						title: 'general.cookieConsent.callToAction',
						submitLabel: 'modal.accept',
						cancelLabel: 'modal.deny'
					})
					.subscribe((result) => {
						switch (result) {
							case 'submit':
								this.euconStorageService.setData('allowHotjar', true);
								this.injectHotjar();
								break;
							case 'cancel':
								this.euconStorageService.setData('allowHotjar', false);
								break;
							case 'action':
								//no-op
								break;
						}
					});
			}, 1000);
		} else if (isHotjarAllowed) {
			this.injectHotjar();
		}
	}

	private injectHotjar() {
		const head = document.getElementsByTagName('head')[0];

		const script = document.createElement('script');
		script.innerHTML = `
			(function(h,o,t,j,a,r){
				h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
				h._hjSettings={hjid:3850902,hjsv:6};
				a=o.getElementsByTagName('head')[0];
				r=o.createElement('script');r.async=1;
				r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
				a.appendChild(r);
			})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `;

		head.insertBefore(script, head.firstChild);
	}
}
