import { ApplicationRef, ComponentRef, inject, Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SizesType } from '@app/models/constants/sizes.type';
import { SignalColor } from '@app/models/constants/color';
import { ModalComponent, ModalEmission } from '@app/shared/components/modal/modal.component';

export type ModalConfig = {
	size?: SizesType;
	title?: string;
	submitLabel?: string;
	submitColor?: SignalColor;
	cancelLabel?: string;
	cancelColor?: SignalColor;
	cancelable?: boolean;
	submittable?: boolean;
	skippable?: boolean;
	canSubmit$?: Observable<boolean>;
	canAction$?: Observable<boolean>;
	actionLabel?: string;
	actionColor?: SignalColor;
	overflow?: 'hidden' | 'visible';
};

@Injectable({
	providedIn: 'root'
})
export class ModalService {
	private appRef = inject(ApplicationRef);
	private modalNotifier$?: Subject<'submit' | 'cancel' | 'action' | 'skip'>;
	private vcr?: ViewContainerRef;
	private modal?: ComponentRef<ModalComponent>;

	/**
	 * __ONLY FOR USE IN MODAL-OUTLET__ <br/>
	 * Registers a singleton outlet where modal components get injected into.
	 * @param vcr The viewContainerRef the modal component gets injected into.
	 */
	public registerOutlet(vcr: ViewContainerRef) {
		this.vcr = vcr;
	}

	/**
	 * Opens a modal window with the provided template.
	 * @param content The ng-template that will be injected into the modal component and displayed inside the model-outlet component.
	 * @param options Modal action and display configurations.
	 * @returns  Observable An observable stream that returns the action that was triggered (submit/action/cancel) and will end on modal close.
	 *
	 * @example Open a modal from template
	 *  this.modalService
	 *      .open(modalTemplate, {
	 *          size: 'large',
	 *          title: 'upload.title',
	 *          canAction$: this.canUpload$.asObservable(),
	 *          canSubmit$: this.canCloseUpload$.asObservable(),
	 *          actionLabel: 'upload.uploadQueue',
	 *          submitLabel: 'modal.complete',
	 *          cancelLabel: 'modal.cancel',
	 *      })
	 *      .subscribe((result) => {
	 *          switch (result) {
	 *              case "submit":
	 *                  break;
	 *              case "action":
	 *                  break;
	 *              case "cancel":
	 *                  break;
	 *          }
	 *      });
	 *
	 */
	open(content: TemplateRef<any>, options?: ModalConfig): Observable<'submit' | 'cancel' | 'action' | 'skip'>;
	/**
	 * Opens a modal window with the provided template.
	 * @param content The ng-template that will be injected into the modal component and displayed inside the model-outlet component.
	 * @param options Modal action and display configurations.
	 * @returns  Observable An observable stream that returns the action that was triggered (submit/action/cancel) and will end on modal close.
	 *
	 * @example Open a modal from string
	 *  this.modalService
	 *      .open("key.to.translation", {
	 *          size: 'large',
	 *          title: 'upload.title',
	 *          canAction$: this.canUpload$.asObservable(),
	 *          canSubmit$: this.canCloseUpload$.asObservable(),
	 *          actionLabel: 'upload.uploadQueue',
	 *          submitLabel: 'modal.complete',
	 *          cancelLabel: 'modal.cancel',
	 *      })
	 *      .subscribe((result) => {
	 *          switch (result) {
	 *              case "submit":
	 *                  break;
	 *              case "action":
	 *                  break;
	 *              case "cancel":
	 *                  break;
	 *          }
	 *      });
	 */
	open(content: string, options?: ModalConfig): Observable<'submit' | 'cancel' | 'action' | 'skip'>;
	open(
		content: TemplateRef<any> | string,
		options?: ModalConfig
	): Observable<'submit' | 'cancel' | 'action' | 'skip'> {
		if (!this.vcr) throw 'No <app-modal-outlet/> provided!';

		this.vcr.clear();

		this.modal = this.vcr.createComponent(ModalComponent);

		if (options) {
			const {
				size,
				title,
				canSubmit$,
				canAction$,
				cancelable,
				submittable,
				skippable,
				submitLabel,
				actionLabel,
				cancelLabel,
				submitColor,
				actionColor,
				cancelColor,
				overflow
			} = options;
			this.modal.instance.size = size ?? this.modal.instance.size;
			this.modal.instance.title = title ?? this.modal.instance.title;
			this.modal.instance.canSubmit$ = canSubmit$ ?? this.modal.instance.canSubmit$;
			this.modal.instance.canAction$ = canAction$ ?? this.modal.instance.canAction$;
			this.modal.instance.cancelable = cancelable ?? this.modal.instance.cancelable;
			this.modal.instance.submittable = submittable ?? this.modal.instance.submittable;
			this.modal.instance.skippable = skippable ?? this.modal.instance.skippable;
			this.modal.instance.submitLabel = submitLabel ?? this.modal.instance.submitLabel;
			this.modal.instance.actionLabel = actionLabel ?? this.modal.instance.actionLabel;
			this.modal.instance.cancelLabel = cancelLabel ?? this.modal.instance.cancelLabel;
			this.modal.instance.submitColor = submitColor ?? this.modal.instance.submitColor;
			this.modal.instance.actionColor = actionColor ?? this.modal.instance.actionColor;
			this.modal.instance.cancelColor = cancelColor ?? this.modal.instance.cancelColor;
			this.modal.instance.overflow = overflow ?? this.modal.instance.overflow;
		}
		this.modal.instance.cancelEvent.subscribe((data) => this.cancelModal(data));
		this.modal.instance.submitEvent.subscribe((data) => this.submitModal(data));
		this.modal.instance.actionEvent.subscribe(() => this.modalAction());

		if (typeof content === 'string') {
			this.modal.instance.textContent = content ?? this.modal.instance.textContent;
		} else {
			this.modal.instance.modalBodyTemplate = content;
		}

		this.appRef.tick();
		this.modalNotifier$ = new Subject();
		return this.modalNotifier$?.asObservable();
	}

	private cancelModal(data: ModalEmission) {
		if (data && data.skipDialog) this.modalNotifier$?.next('skip');
		this.modalNotifier$?.next('cancel');
		this.modalNotifier$?.complete();
		this.closeModal();
	}

	private submitModal(data: ModalEmission) {
		if (data && data.skipDialog) this.modalNotifier$?.next('skip');
		this.modalNotifier$?.next('submit');
		this.modalNotifier$?.complete();
		this.closeModal();
	}

	private modalAction() {
		this.modalNotifier$?.next('action');
	}

	private closeModal() {
		this.modal?.instance.close().subscribe(() => {
			this.vcr?.clear();
		});
	}
}
