import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseFormControl } from '@app/shared/components/base-form-control/base-form-control';

@Component({
	selector: 'app-switch',
	templateUrl: './switch.component.html',
	styleUrls: ['./switch.component.scss']
})
export class SwitchComponent extends BaseFormControl<string, HTMLInputElement> {
	@Input() checked: boolean;
	@Input() onText: string;
	@Input() offText: string;

	@Input() icon: string;
	@Input() lighter: boolean;
	@Input() disabled: boolean;
	@Input() danger: boolean;
	@Input() pullRight: boolean;

	@Output() switchToggle = new EventEmitter();

	onActionToggle() {
		this.checked = !this.checked;
		this.onChange(this.checked);
		this.switchToggle.emit(this.checked);
	}
}
