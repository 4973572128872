import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ContentChildren,
	DoCheck,
	EventEmitter,
	Input,
	OnInit,
	Output,
	QueryList,
	TemplateRef,
	ViewChild,
	ViewChildren
} from '@angular/core';
import { IButtonOption, OptionType } from '@app/models/index';
import { EuconTemplateDirective } from '@app/shared/directives/eucon-template.directive';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-button-dropdown',
	templateUrl: './button-dropdown.component.html',
	styleUrls: ['./button-dropdown.component.scss']
})
export class ButtonDropdownComponent implements AfterViewInit, /*DoCheck,*/ OnInit {
	@ViewChild(NgbDropdown) dropdown: NgbDropdown;
	@ViewChild('defaultTmpl') defaultTmpl: TemplateRef<any>;
	@ContentChildren(EuconTemplateDirective) customTemplates: QueryList<EuconTemplateDirective>;
	@ViewChildren(EuconTemplateDirective) templates: QueryList<EuconTemplateDirective>;

	@Input() label: string;
	@Input() icon: string;
	@Input() items: IButtonOption[];
	@Input() btnTypeClass: string = 'primary';
	@Input() btnCssClass: string;
	@Input() autoClose: boolean | 'outside' | 'inside' = true;
	@Input() display: 'dynamic' | 'static' = 'dynamic';
	@Input() dropdownClass: string;
	@Input() openFlag: boolean = false;
	@Input() placement: string;

	@Output() openChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	optionTypeEnum = OptionType;

	// private objDiffer: any;

	constructor(private cdRef: ChangeDetectorRef /*, private differs: KeyValueDiffers*/) {}

	ngOnInit() {
		// this.objDiffer = {};
		// this.items.forEach((elt, i) => {
		//   this.objDiffer[i] = this.differs.find(elt).create();
		// });
	}

	ngAfterViewInit(): void {
		if (this.placement) {
			this.dropdown.placement = this.placement;
		}
		this.cdRef.detectChanges();
	}

	// ngDoCheck() {
	//   this.items.forEach((elt, i) => {
	//     var objDiffer = this.objDiffer[i];
	//     var objChanges = objDiffer.diff(elt);
	//     if (objChanges) {
	//       objChanges.forEachChangedItem((elt: { key: string; }): void => {
	//         if (elt.key === 'prop1') {
	//           console.log(elt);
	//         }
	//       });
	//     }
	//   });
	// }

	getIconClass(icon: string) {
		return icon.indexOf('fa-') < 0 ? `fa fa-fw fa-${icon}` : icon;
	}

	getVisibility(item: IButtonOption) {
		return typeof item.visible === 'function' ? item.visible() : item.visible !== false;
	}

	isOpen(): boolean {
		return this.dropdown.isOpen();
	}

	open(): void {
		this.dropdown.open();
	}

	close(): void {
		this.dropdown.close();
	}

	toggle(): void {
		this.dropdown.toggle();
	}

	getTypeName(type: OptionType): string {
		return `typeTmpl${type || 0}`;
	}

	getTemplate(item: IButtonOption): TemplateRef<any> | null {
		if (item.type === OptionType.Custom && this.customTemplates && this.customTemplates.length) {
			const template = this.customTemplates.find((t) => t.name === item.customTemplate);
			if (!template) {
				console.error('Custom template is not provided');
				return this.defaultTmpl;
			}

			return template.templateRef;
		}

		if (item.type !== OptionType.Custom && this.templates && this.templates.length) {
			const template = this.templates.find((t) => t.name === this.getTypeName(item.type));
			if (!template) {
				console.error('Template is not provided');
				return this.defaultTmpl;
			}

			return template.templateRef;
		}

		return this.defaultTmpl;
	}
}
